import { useState, useEffect } from "react";
import { Table, Switch, Popover } from "antd";
import {
  getQuery,
  convertQueryParams,
  fetchApi,
  downloadAsCSV,
} from "../../../helper";

export default function IndividualForm(props) {
  let query = getQuery();
  const [detailedHistoryShow, setDetailedHistoryShow] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [queryParams, setQueryParams] = useState({ _start: 0, _limit: 5 });
  const [totalCount, setTotalCount] = useState(0);
  const [clientData, setClientData] = useState([]);
  let { id } = props;

  useEffect(() => {
    if (id || query.id) {
      let params = { enId: id || query.id };
    }
  }, [id, query.id]);

  useEffect(() => {
    handleRefresh();
  }, [id]);

  const columns = [
    {
      title: "Share Certificate Number",
      dataIndex: "shareNumber",
      sorter: (a, b) => a.shareNumber - b.shareNumber,
    },
    {
      title: "Sender Master ID",
      dataIndex: "senderMasterId",
      sorter: (a, b) => (a.senderMasterId || 0) - (b.senderMasterId || 0),
    },
    {
      title: "Number of Shares",
      dataIndex: "noShares",
      sorter: (a, b) => a.noShares - b.noShares,
    },
    {
      title: "Share Type",
      dataIndex: "shareType",
      sorter: (a, b) => a.shareType - b.shareType,
    },

    {
      title: "Total No. of Shares held",
      dataIndex: "sharesHeld",
      sorter: (a, b) => a.sharesHeld - b.sharesHeld,
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => a.status - b.status,
    },
    {
      title: "Issued Date",
      dataIndex: "createdAt",
      sorter: (a, b) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
    },

    {
      title: "Par value",
      dataIndex: "parValue",
      sorter: (a, b) => a.parValue - b.parValue,
    },
    {
      title: "Date Ceased to be a Member",
      dataIndex: "ceasedDate",
      sorter: (a, b) =>
        new Date(a.dateCeased).getTime() - new Date(b.dateCeased).getTime(),
    },
    {
      title: "Comment",
      render: (_, { _id, comment }, index) => (
        <Popover
          className="custom-popover"
          placement="rightBottom"
          content={<p className="mb-0">{comment}</p>}
        >
          <button className="primary-btn">View</button>
        </Popover>
      ),
    },
  ];
  const getPagination = async (current, pageSize) => {
    let params = {
      ...queryParams,
      _start: (current - 1) * pageSize,
      _limit: pageSize,
      enId: id || query.id,
    };

    const queryString = convertQueryParams(params);
    const payload = {
      method: "GET",
      url: `/transaction?${queryString}`,
    };

    const historyData = await fetchApi(payload).then((res) => {
      setTotalCount(res?.data?.totalCount || 0);
      return res?.data?.rows || [];
    });
    const clientDataMap = new Map(
      clientData.map((item) => [item.value, item.label])
    );
    const transformedResponse = {
      rows: historyData.map((row) => ({
        ...row,
        senderMasterId: row.senderForm?.length
          ? clientDataMap.get(row.senderForm[0]?.masterId)
          : row.allocationForm?.length
          ? clientDataMap.get(row.allocationForm[0]?.masterId)
          : null,

        shareNumber: row.senderForm?.length
          ? row.senderForm[0]?.certificateNumber
          : row.receiverForm?.length
          ? row.receiverForm[0]?.certificateNumber
          : row.allocationForm?.length
          ? row.allocationForm[0]?.certificateNumber
          : null,

        noShares: row.senderForm?.length
          ? row.senderForm[0]?.numberOfShares
          : row.receiverForm?.length
          ? row.receiverForm[0]?.numberOfShares
          : row.allocationForm?.length
          ? row.allocationForm[0]?.numberOfShares
          : null,

        sharesHeld: row.senderForm?.length
          ? row.senderForm[0]?.shareCapital
          : row.receiverForm?.length
          ? row.receiverForm[0]?.shareCapital
          : row.allocationForm?.length
          ? row.allocationForm[0]?.shareCapital
          : null,

        parValue: row.senderForm?.length
          ? row.senderForm[0]?.parValue
          : row.receiverForm?.length
          ? row.receiverForm[0]?.parValue
          : row.allocationForm?.length
          ? row.allocationForm[0]?.parValue
          : null,

        ceasedDate: row.senderForm?.length
          ? row.senderForm[0]?.ceasedDate
          : row.receiverForm?.length
          ? row.receiverForm[0]?.ceasedDate
          : row.allocationForm?.length
          ? row.allocationForm[0]?.ceasedDate
          : "-",

        effectiveDate: row.senderForm?.length
          ? row.senderForm[0]?.effectiveDate
          : row.receiverForm?.length
          ? row.receiverForm[0]?.effectiveDate
          : row.allocationForm?.length
          ? row.allocationForm[0]?.effectiveDate
          : null,

        totalShares: row.senderForm?.length
          ? row.senderForm[0]?.totalShares
          : row.receiverForm?.length
          ? row.receiverForm[0]?.totalShares
          : row.allocationForm?.length
          ? row.allocationForm[0]?.totalShares
          : null,

        percentage: row.senderForm?.length
          ? row.senderForm[0]?.percentage
          : row.receiverForm?.length
          ? row.receiverForm[0]?.percentage
          : row.allocationForm?.length
          ? row.allocationForm[0]?.percentage
          : null,

        comment: row.allocationForm?.length
          ? row.allocationForm[0]?.comment
          : null,

        currency: row.allocationForm?.length
          ? row.allocationForm[0]?.currency
          : null,

        shareType: row.senderForm?.length
          ? row.senderForm[0]?.shareType
          : row.receiverForm?.length
          ? row.receiverForm[0]?.shareType
          : row.allocationForm?.length
          ? row.allocationForm[0]?.shareType
          : null,

        combinedForm: {
          sender: row.senderForm || [],
          receiver: row.receiverForm || [],
          allocation: row.allocationForm || [],
        },
      })),
    };
    console.log(transformedResponse.rows, "transformedResponse");
    setTableData(transformedResponse.rows);

    setQueryParams(params);
  };

  const handleRefresh = async () => {
    try {
      let params = { enId: id, ...queryParams }; //all: true

      const fetchData = async (url) => {
        const payload = { method: "GET", url };
        const response = await fetchApi(payload);
        setTotalCount(response?.data?.totalCount);
        return response?.data?.rows || [];
      };

      const clientParams = convertQueryParams({
        isAll: true,
        options: true,
      });
      const clientData = await fetchData(`/client?${clientParams}`);

      const clientDataMap = new Map(
        clientData.map((item) => [item.value, item.label])
      );
      setClientData(clientData);
      const queryString = convertQueryParams(params);
      const historyData = await fetchData(`/transaction?${queryString}`);
      const transformedResponse = {
        rows: historyData.map((row) => ({
          ...row,
          senderMasterId: row.senderForm?.length
            ? clientDataMap.get(row.senderForm[0]?.masterId)
            : row.allocationForm?.length
            ? clientDataMap.get(row.allocationForm[0]?.masterId)
            : null,

          shareNumber: row.senderForm?.length
            ? row.senderForm[0]?.certificateNumber
            : row.receiverForm?.length
            ? row.receiverForm[0]?.certificateNumber
            : row.allocationForm?.length
            ? row.allocationForm[0]?.certificateNumber
            : null,

          noShares: row.senderForm?.length
            ? row.senderForm[0]?.numberOfShares
            : row.receiverForm?.length
            ? row.receiverForm[0]?.numberOfShares
            : row.allocationForm?.length
            ? row.allocationForm[0]?.numberOfShares
            : null,

          sharesHeld: row.senderForm?.length
            ? row.senderForm[0]?.shareCapital
            : row.receiverForm?.length
            ? row.receiverForm[0]?.shareCapital
            : row.allocationForm?.length
            ? row.allocationForm[0]?.shareCapital
            : null,

          parValue: row.senderForm?.length
            ? row.senderForm[0]?.parValue
            : row.receiverForm?.length
            ? row.receiverForm[0]?.parValue
            : row.allocationForm?.length
            ? row.allocationForm[0]?.parValue
            : null,

          ceasedDate: row.senderForm?.length
            ? row.senderForm[0]?.ceasedDate
            : row.receiverForm?.length
            ? row.receiverForm[0]?.ceasedDate
            : row.allocationForm?.length
            ? row.allocationForm[0]?.ceasedDate
            : null,

          effectiveDate: row.senderForm?.length
            ? row.senderForm[0]?.effectiveDate
            : row.receiverForm?.length
            ? row.receiverForm[0]?.effectiveDate
            : row.allocationForm?.length
            ? row.allocationForm[0]?.effectiveDate
            : null,

          totalShares: row.senderForm?.length
            ? row.senderForm[0]?.totalShares
            : row.receiverForm?.length
            ? row.receiverForm[0]?.totalShares
            : row.allocationForm?.length
            ? row.allocationForm[0]?.totalShares
            : null,

          percentage: row.senderForm?.length
            ? row.senderForm[0]?.percentage
            : row.receiverForm?.length
            ? row.receiverForm[0]?.percentage
            : row.allocationForm?.length
            ? row.allocationForm[0]?.percentage
            : null,

          comment: row.allocationForm?.length
            ? row.allocationForm[0]?.comment
            : null,

          currency: row.allocationForm?.length
            ? row.allocationForm[0]?.currency
            : null,

          shareType: row.senderForm?.length
            ? row.senderForm[0]?.shareType
            : row.receiverForm?.length
            ? row.receiverForm[0]?.shareType
            : row.allocationForm?.length
            ? row.allocationForm[0]?.shareType
            : null,

          combinedForm: {
            sender: row.senderForm || [],
            receiver: row.receiverForm || [],
            allocation: row.allocationForm || [],
          },
        })),
      };
      console.log(transformedResponse.rows, "transformedResponse");
      setTableData(transformedResponse.rows);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onExportClick = () => {
    downloadAsCSV(tableData, "detailed-history");
  };

  return (
    <div>
      <div className="d-flex py-4 gap-3 align-items-center">
        <Switch
          className="custom-switch"
          onChange={(value) => setDetailedHistoryShow(value)}
        />
        <h4 className="primary-title">Detailed History</h4>
        {detailedHistoryShow && (
          <button className="primary-btn ms-auto" onClick={onExportClick}>
            Export
          </button>
        )}
      </div>
      {detailedHistoryShow && (
        <div className="d-flex justify-content-between align-items-center">
          <Table
            className="custom-table-light mt-md-1 mb-3"
            columns={columns}
            dataSource={tableData}
            scroll={{ x: 1800 }}
            pagination={{
              className: "custom-pagination",
              defaultCurrent: 1,
              pageSize: queryParams?._limit,
              total: totalCount,
              onChange: getPagination,
            }}
          />
        </div>
      )}
    </div>
  );
}

// import { useState, useEffect, useRef } from "react";
// import { Table, Switch, Modal } from "antd";

// import { useSelector, useDispatch } from "react-redux";

// import { getIndividualDetails } from "../../../store/individualDetails/individualDetailsSlice";

// import FormZ from "../../../components/FormZ";

// import {
//   getQuery,
//   convertQueryParams,
//   fetchApi,
//   sendNotify,
//   downloadAsCSV,
// } from "../../../helper";
// export default function IndividualForm(props) {
//   const { data, isLoading } = useSelector((state) => state.country);
//   const individualDetails = useSelector((state) => state.individualDetails);

//   let query = getQuery();
//   const dispatch = useDispatch();
//   const [detailedHistoryShow, setDetailedHistoryShow] = useState(false);
//   const [individualOptions, setIndividualOptions] = useState({});
//   const [formData, setFormData] = useState({});
//   const [historyShow, setHistoryShow] = useState([]);
//   const [openDetailedHistory, setOpenDetailedHistory] = useState(false);
//   const [individualArray, setIndividualArray] = useState([]);
//   const [historyData, setHistoryData] = useState({});
//   const [tableData, setTableData] = useState([]);
//   const [selectedId, setSelectedId] = useState("");
//   const [queryParams, setQueryParams] = useState({ _start: 0, _limit: 5 });
//   const [totalCount, setTotalCount] = useState(0);
//   let { name, entityData, id } = props;

//   useEffect(() => {
//     if (id || query.id) {
//       let params = { enId: id ? id : query.id };
//       dispatch(getIndividualDetails(params));
//     }
//   }, [id]);

//   useEffect(() => {
//     dropDownOnClick();
//     handleRefresh();
//   }, [individualDetails]);
//   const columns = [
//     {
//       title: "history Code",
//       dataIndex: "code",
//       sorter: (a, b) => a.code - b.code,
//     },
//     {
//       title: "Share Certificate Number",
//       dataIndex: "shareNumber",
//       sorter: (a, b) => a.shareNumber - b.shareNumber,
//     },
//     {
//       title: "Number of Shares",
//       dataIndex: "noShares",
//       sorter: (a, b) => a.noShares - b.noShares,
//     },
//     {
//       title: "Share Type",
//       dataIndex: "shareType",
//       sorter: (a, b) => a.shareType - b.shareType,
//     },
//     {
//       title: (
//         <div className="d-flex align-items-center">
//           Issued Date&nbsp;<i className="fal fa-question-circle"></i>
//         </div>
//       ),
//       dataIndex: "issuedDate",
//       sorter: (a, b) => a.issuedDate - b.issuedDate,
//     },
//     {
//       title: "Total No. of Shares held",
//       dataIndex: "sharesHeld",
//       sorter: (a, b) => a.sharesHeld - b.sharesHeld,
//     },
//     {
//       title: (
//         <div className="d-flex align-items-center">
//           Transfer Date&nbsp;<i className="fal fa-question-circle"></i>
//         </div>
//       ),
//       dataIndex: "transferDate",
//       sorter: (a, b) => a.transferDate - b.transferDate,
//     },
//     {
//       title: "Transfer Amount",
//       dataIndex: "transferAmount",
//       sorter: (a, b) => a.transferAmount - b.transferAmount,
//     },
//     {
//       title: "Par value",
//       dataIndex: "parValue",
//       sorter: (a, b) => a.parValue - b.parValue,
//     },
//     {
//       title: "Date Ceased to be a Member",
//       dataIndex: "dateCeased",
//       sorter: (a, b) => a.dateCeased - b.dateCeased,
//     },
//     {
//       title: "Comments",
//       dataIndex: "comments",
//       sorter: (a, b) => a.comments - b.comments,
//     },
//     {
//       title: "",
//       render: (_, { _id }) => (
//         <div className="d-flex gap-2 align-items-center justify-content-end">
//           <button className="action-btn delete-btn">
//             <i className="far fa-trash-alt"></i>
//           </button>
//           <button
//             className="action-btn"
//             onClick={() => editDetailedHistory(_id)}
//           >
//             <i className="far fa-pencil"></i>
//           </button>
//         </div>
//       ),
//     },
//   ];
//   const editDetailedHistory = async (value) => {
//     let data = { ...historyData };

//     let params = {};
//     params.enId = id;
//     params.id = value;
//     let query = convertQueryParams(params);
//     let payload = {
//       method: "GET",
//       url: `/historyData?${query}`,
//     };
//     let history = await fetchApi(payload).then((res) => {
//       return res?.data.rows;
//     });
//     console.log(history, "ezryextcyiub");
//     data = history[0];
//     setHistoryData(data);
//     setSelectedId(value);
//     setOpenDetailedHistory(true);
//   };

//   const getPagination = async (current, pageSize) => {
//     let params = { ...queryParams };
//     params._start = (current - 1) * pageSize;
//     params.enId = id ? id : query.id;

//     let query = convertQueryParams(params);
//     let payload = {
//       method: "GET",
//       url: `/historyData?${query}`,
//     };
//     let history = await fetchApi(payload).then((res) => {
//       setTotalCount(res?.data.totalCount);
//       return res?.data.rows;
//     });
//     setTableData(history);
//     setQueryParams(params);
//   };

//   // const tableData = [
//   //   {
//   //     key: "1",
//   //     id: "1",
//   //     shareNumber: 55,
//   //     noShares: 100,
//   //     shareType: "Type 1",
//   //     issuedDate: "11-12-2023",
//   //     sharesHeld: "02",
//   //     transferDate: "12-12-2023",
//   //     transferAmount: 500,
//   //     parValue: 100,
//   //     dateCeased: "Sample",
//   //     comments: "Sample",
//   //   },
//   //   {
//   //     key: "2",
//   //     id: "2",
//   //     shareNumber: 56,
//   //     noShares: 500,
//   //     shareType: "Type 2",
//   //     issuedDate: "13-12-2023",
//   //     sharesHeld: "05",
//   //     transferDate: "14-12-2023",
//   //     transferAmount: 800,
//   //     parValue: 100,
//   //     dateCeased: "Sample",
//   //     comments: "Sample",
//   //   },
//   //   {
//   //     key: "3",
//   //     id: "3",
//   //     shareNumber: 57,
//   //     noShares: 250,
//   //     shareType: "Type 1",
//   //     issuedDate: "15-12-2023",
//   //     sharesHeld: "05",
//   //     transferDate: "17-12-2023",
//   //     transferAmount: 400,
//   //     parValue: 100,
//   //     dateCeased: "Sample",
//   //     comments: "Sample",
//   //   },
//   //   {
//   //     key: "4",
//   //     id: "4",
//   //     shareNumber: 58,
//   //     noShares: 120,
//   //     shareType: "Type 2",
//   //     issuedDate: "18-12-2023",
//   //     sharesHeld: "05",
//   //     transferDate: "19-12-2023",
//   //     transferAmount: 1500,
//   //     parValue: 100,
//   //     dateCeased: "Sample",
//   //     comments: "Sample",
//   //   },
//   // ];
//   const handleRefresh = async () => {
//     let params = {};
//     params.enId = id;
//     let query = convertQueryParams(params);
//     let payload = {
//       method: "GET",
//       url: `/historyData?${query}`,
//     };
//     let historyData = await fetchApi(payload).then((res) => {
//       return res?.data?.rows;
//     });

//     const individualParams = convertQueryParams({
//       isAll: true,
//       masterType: "individual",
//       options: true,
//     });

//     // let obj = {
//     //   method: "get",
//     //   url: `/client?${individualParams}`,
//     // };
//     // let individualData = await fetchApi(obj).then((res) => {
//     //   return res?.data;
//     // });

//     // const firstName = individualData?.rows.map((d) => ({
//     //   value: d.firstName,
//     //   label: d.firstName,
//     // }));
//     // const middleName = individualData?.rows.map((d) => ({
//     //   value: d.middleName,
//     //   label: d.middleName,
//     // }));
//     // const lastName = individualData?.rows.map((d) => ({
//     //   value: d.lastName,
//     //   label: d.lastName,
//     // }));
//     // let data = {
//     //   firstName: firstName,
//     //   middleName: middleName,
//     //   lastName: lastName,
//     // };
//     // setIndividualOptions(data);

//     setTableData(historyData);
//   };
//   const dropDownOnClick = async (value) => {
//     let data = { ...historyData };
//     let params = {};
//     params.isAll = true;
//     params.masterType = "individual";
//     params.options = true;
//     let query = convertQueryParams(params);
//     let payload = {
//       method: "GET",
//       url: `/client?${query}`,
//     };
//     let clientData = await fetchApi(payload).then((res) => {
//       return res?.data?.rows;
//     });

//     setIndividualArray(clientData);
//     data.shareNumber = entityData?.authorisedShares;
//     setHistoryData(data);
//   };

//   const toggleHistory = (index) => {
//     let arr = [...historyShow];
//     let item = arr.findIndex((hs) => hs === index);
//     if (item !== -1) {
//       arr.splice(item, 1);
//     } else {
//       arr.push(index);
//     }
//     setHistoryShow(arr);
//   };

//   const onChangDetailedHistory = () => {
//     setOpenDetailedHistory(true);
//   };

//   const historyOnChange = (value, name) => {
//     let {
//       invId,

//       passportNo,
//       firstName,
//       lastName,
//       middleName,
//       nationality,
//       noShares,
//     } = value;

//     if (name == "noShares") {
//       let percentage =
//         (Number(noShares) / Number(entityData?.authorisedShares)) * 100;
//       value.percentage = `${percentage} %`;
//       setHistoryData((prev) => ({
//         ...prev,
//         ...value,
//       }));
//     }
//     let param = {};
//     if (name == "invId") {
//       param.id = invId;
//     }

//     if (name == "firstName") {
//       param.firstName = firstName;
//     }
//     if (name == "lastName") {
//       param.lastName = lastName;
//     }
//     if (name == "middleName") {
//       param.middleName = middleName;
//     }
//     if (nationality) {
//       param.nationality = nationality;
//     }
//     if (passportNo) {
//       param.passportNo = passportNo;
//     }

//     handleindividualChange(param);
//     // setHistoryData((prev) => ({
//     //   ...prev,
//     //   ...value,
//     // }));
//   };
//   const handleindividualChange = async (value) => {
//     let query = convertQueryParams(value);

//     let payload = {
//       method: "get",
//       url: `/individual?${query}`,
//     };
//     let individualData = await fetchApi(payload).then((res) => {
//       return res?.data;
//     });

//     if (individualData?.rows.length) {
//       const firstName = individualData?.rows.map((d) => ({
//         value: d.firstName,
//         label: d.firstName,
//       }));
//       const middleName = individualData?.rows.map((d) => ({
//         value: d.middleName,
//         label: d.middleName,
//       }));
//       const lastName = individualData?.rows.map((d) => ({
//         value: d.lastName,
//         label: d.lastName,
//       }));
//       let data = {
//         firstName: firstName,
//         middleName: middleName,
//         lastName: lastName,
//       };
//       setIndividualOptions(data);
//     } else {
//       setHistoryData({});
//     }
//     // if (individualData?.rows.length == 1) {
//     //   setHistoryData((prev) => ({
//     //     ...prev,
//     //     invId: individualData?.rows[0]?._id,
//     //     firstName: individualData?.rows[0]?.firstName,
//     //     middleName: individualData?.rows[0]?.middleName,
//     //     lastName: individualData?.rows[0]?.lastName,
//     //     title: individualData?.rows[0]?.title,
//     //   }));
//     // }
//   };
//   const formRef = useRef();
//   let formSchema = [
//     {
//       name: "invId",
//       value: "",
//       type: "select",
//       label: "Individual",
//       placeholder: "Select Individual code",
//       //  options: [],
//       loading: isLoading,
//       allowClear: true,
//       showSearch: true,
//     },
//     // {
//     //   name: "title",
//     //   value: "",
//     //   type: "select",
//     //   label: "Salutation",
//     //   placeholder: "Enter salutation",
//     //   //disabled: true,
//     //   options: [
//     //     {
//     //       value: "mr",
//     //       label: "Mr",
//     //     },
//     //     {
//     //       value: "mrs",
//     //       label: "Mrs",
//     //     },
//     //     {
//     //       value: "miss",
//     //       label: "Miss",
//     //     },
//     //     {
//     //       value: "ms",
//     //       label: "Ms",
//     //     },
//     //     {
//     //       value: "dr",
//     //       label: "Dr",
//     //     },
//     //     {
//     //       value: "rev",
//     //       label: "Rev",
//     //     },
//     //   ],
//     // },
//     {
//       name: "firstName",
//       value: "",
//       type: "select",
//       label: "First Name",
//       showSearch: true,
//       allowClear: true,
//       placeholder: "Enter first name",
//     },
//     {
//       name: "middleName",
//       value: "",
//       type: "select",
//       label: "Middle Name",
//       showSearch: true,
//       placeholder: "Enter Middle name",
//       allowClear: true,
//     },
//     {
//       name: "lastName",
//       showSearch: true,
//       value: "",
//       type: "select",
//       label: "Last Name",
//       placeholder: "Enter last name",
//       allowClear: true,
//     },
//     {
//       name: "shareNumber",
//       value: "",
//       type: "text",
//       label: "Authorized Share Capital", //Share Number
//       placeholder: "Enter Authorized Share Capital",
//       disabled: true,
//     },
//     {
//       name: "noShares",
//       value: "",
//       type: "text",
//       label: "No Of Shares",
//       placeholder: "Enter No Of Shares",
//       required: true,
//     },

//     {
//       name: "shareType",
//       value: "",
//       type: "text",
//       label: "Share Type",
//       placeholder: "Enter Share Type",
//       required: true,
//     },

//     {
//       name: "issuedDate",
//       value: "",
//       type: "date",
//       label: "Issued Date",
//       placeholder: "Enter Date (DD/MM/YYYY)",
//       //format: "DD/MM/YYYY",
//     },
//     {
//       name: "sharesHeld",
//       value: "",
//       type: "text",
//       label: "Total Shares Held", //Shares Held
//       placeholder: "Enter Total Shares Held",
//     },
//     {
//       name: "ShareCertificateNo",
//       value: "",
//       type: "text",
//       label: "Share Certificate Number", //Shares Held
//       placeholder: "Enter Share Certificate Number",
//     },

//     {
//       name: "transferDate",
//       value: "",
//       type: "date",
//       label: "Transfer Date",
//       placeholder: "Enter Date (DD/MM/YYYY)",
//       //format: "DD/MM/YYYY",
//     },

//     {
//       name: "transferAmount",
//       value: "",
//       type: "text",
//       label: "Transfer Amount",
//       placeholder: "Enter Transfer Amount",
//     },
//     {
//       name: "parValue",
//       value: "",
//       type: "text",
//       label: "Par Value",
//       placeholder: "Enter Par Value",
//     },
//     {
//       name: "dateCeased",
//       value: "",
//       type: "text",
//       label: "Date Ceased",
//       placeholder: "Enter Date Ceased",
//     },
//     {
//       name: "createdAt",
//       value: "",
//       type: "date",
//       label: "Date of Creation",
//       placeholder: "Select date",
//     },

//     {
//       name: "percentage",
//       value: "",
//       type: "text",
//       label: "Percentage of shares",
//       placeholder: "0 %",
//       disabled: true,
//     },

//     {
//       name: "comments",
//       value: "",
//       type: "textarea",
//       label: "Comments",
//       placeholder: "Enter Comments",
//       // required: true,
//     },
//   ];
//   const closeModalOnClick = () => {
//     setOpenDetailedHistory(false);
//   };
//   const saveOnClick = () => {
//     saveData(selectedId);
//   };
//   const saveData = async (selectedId) => {
//     const valid = formRef.current.validForm();
//     if (!valid) {
//       sendNotify("error", "Some fields are missing!");
//       return;
//     }
//     let formData = formRef.current.getPayload();
//     formData.enId = id;
//     const payload = {
//       method: selectedId ? "PUT" : "POST",
//       url: selectedId ? `/historyData/update/${selectedId}` : `/historyData`,
//       data: formData,
//     };
//     fetchApi(payload)
//       .then((res) => {
//         sendNotify("success", res?.message);
//         setOpenDetailedHistory(false);
//         handleRefresh();
//       })
//       .catch((error) => {
//         sendNotify("error", error?.message);
//       });
//   };
//   const onExportClick = () => {
//     downloadAsCSV(tableData, "detailed-history");
//   };

//   return (
//     <div>
//       <div className="d-flex py-4 gap-3 align-items-center">
//         <Switch
//           className="custom-switch"
//           onChange={(value) => setDetailedHistoryShow(value)}
//         />
//         <h4 className="primary-title">Detailed History</h4>
//       </div>

//       {detailedHistoryShow && (
//         <div className="d-flex justify-content-between align-items-center">
//           <h6 className="hyper-link mb-0" onClick={() => toggleHistory(name)}>
//             Detailed History{" "}
//             <i
//               className={`fas fa-chevron-down ${
//                 historyShow.findIndex((hs) => hs === name) !== -1
//                   ? "fa-rotate-180"
//                   : ""
//               }`}
//             ></i>
//           </h6>
//           {historyShow.findIndex((hs) => hs === name) !== -1 ? (
//             <div className="d-flex gap-3">
//               <button
//                 className="primary-btn ms-auto"
//                 onClick={onChangDetailedHistory}
//               >
//                 Add
//               </button>
//               <button className="primary-btn ms-auto" onClick={onExportClick}>
//                 Export
//               </button>
//             </div>
//           ) : (
//             <span className="py-2">&nbsp;</span>
//           )}
//         </div>
//       )}

//       {historyShow.findIndex((hs) => hs === name) !== -1 ? (
//         <Table
//           className="custom-table-light mt-md-1 mb-3"
//           columns={columns}
//           dataSource={tableData}
//           scroll={{ x: 1800 }}
//           pagination={{
//             className: "custom-pagination",
//             defaultCurrent: 1,
//             pageSize: queryParams?._limit,
//             total: totalCount,
//             onChange: getPagination,
//           }}
//         />
//       ) : (
//         <></>
//       )}

//       <Modal
//         title={selectedId ? "Edit History " : "Add New History"}
//         okText={"Save"}
//         className="custom-modal"
//         open={openDetailedHistory}
//         width={1000}
//         onOk={() => saveOnClick()}
//         onCancel={() => setOpenDetailedHistory(false)}
//         // footer={false}
//       >
//         <div>
//           <FormZ
//             formSchema={formSchema}
//             ref={formRef}
//             formData={historyData}
//             onChange={historyOnChange}
//             formClass="row mb-3 mb-md-4 gy-3 gx-4"
//             formOptions={{ invId: individualArray, ...individualOptions }}
//             childClass="col-md-3 mb-0"
//             labelClass="mb-1"
//             inputClass="w-100"
//           />
//         </div>
//       </Modal>
//     </div>
//   );
// }
