import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Select, Popover, Modal } from "antd";
import { Link, useSearchParams } from "react-router-dom";
import { getEntity } from "../store/entity/entitySlice";

import { Each, fetchApi, sendNotify, convertQueryParams } from "../helper";

import FormZ from "../components/FormZ";
import UploadZ from "../components/UploadZ";
import TabZ from "../components/TabZ";
let arr = [1];
const { success } = Modal;

export default function PostIncorp() {
  let [searchParams, setSearchParams] = useSearchParams();
  const entity = useSelector((state) => state.entity);
  const dispatch = useDispatch();

  const [selectedType, setSelectedType] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [serviceFee, setServiceFee] = useState(0);
  const [documents, setDocuments] = useState([]);
  const [subDocuments, setSubDocuments] = useState([]);
  const [formData, setFormData] = useState([]);
  const [entityObjArray, setEntityObjArray] = useState([]);
  const [name, setName] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [activeTab, setActiveTab] = useState("individual");
  const [receiveAs, setReceiveAs] = useState("");
  const [serviceType, setServiceType] = useState([]);
  const [alterationType, setAlterationType] = useState(null);
  const [address, setAddress] = useState("");
  const [note, setNote] = useState("");
  function storeDocuments(file) {
    let doc = [...documents];

    doc.push(file);

    setDocuments(doc);
  }

  function storeDocument(file) {
    let doc = [...subDocuments];
    doc.push(file);

    setSubDocuments(doc);
  }

  function storeDocument(file) {
    let doc = [...subDocuments];
    doc.push(file);

    setSubDocuments(doc);
  }
  useEffect(() => {
    let service = searchParams.get("service");
    if (service) {
      setIsDisabled(true);
      getSelectType(service);
    }
    dispatch(getEntity({ _start: 0, _limit: 100 }));
  }, [searchParams]);
  useEffect(() => {
    entityCodeOnchange();
  }, [entity]);
  const entityCodeOnchange = () => {
    let list = entity?.data?.rows?.map((d) => {
      let obj = {
        value: d?.code,
        label: d?.code,
      };
      return obj;
    });
    setEntityObjArray(list);
  };
  const postIncorpType = [
    {
      value: "COI",
      label: "Certificate of Incumbency (COI)",
    },
    {
      value: "COGS",
      label: "Certificate of Good Standing (COGS)",
    },
    {
      value: "ROD",
      label: "Register of Directors",
    },
    {
      value: "SR",
      label: "Share Registry",
    },
    {
      value: "COD",
      label: "Change Of Director (COD)",
    },
    {
      value: "COSH",
      label: "Change of Shareholder (COSH)",
    },
    {
      value: "COA",
      label: "Change of Client / Administration (COA)",
    },
    {
      value: "COC",
      label: "Change of Contact Point (COC)",
    },
    {
      value: "TO",
      label: "Transfer Out (TO)",
    },
    {
      value: "CO",
      label: "Continuation Out (CO)",
    },
    {
      value: "AMAA",
      label: "Alteration to the Memorandum & Articles of Association",
    },
    {
      value: "MNC",
      label: "M&AA Name Change",
    },
    {
      value: "NOD",
      label: "Notarization of Documents",
    },
    {
      value: "AOD",
      label: "Apostille of Documents",
    },
    {
      value: "ARMA",
      label: "Amended and Restated Memorandum & Articles",
    },
    { value: "Apostilles", label: "Apostilles" },
    {
      value: "CGS",
      label: "Certificate of Good Standing",
    },
    {
      value: "RCAD",
      label: "Registry Certified Copy of Any Document",
    },
    {
      value: "RUD",
      label: "Registry Uncertified or Duplicate Copy of Any Document",
    },
    {
      value: "RPEC",
      label: "Registry Physical Copy of any Electronic Certificate",
    },
    { value: "CI", label: "Certificate of Incumbency" },
    { value: "CD", label: "Corporate Documents (Internal Certification)" },
    {
      value: "CCPD",
      label: "CCP Certified or Duplicate copies of documents",
    },
    {
      value: "ARD",
      label: "Amendments to Register of Directors",
    },
    {
      value: "ARM",
      label:
        "Amendments to Register of Members & Issuing New Share Certificate(s)",
    },
    { value: "Continuations", label: "Continuations" },
    { value: "DR", label: "Drafting Resolutions" },
    {
      value: "FRMC",
      label: "Filing Register of Mortgage & Charges",
    },
    {
      value: "FAR",
      label: "Filing Appointment of RO/RA",
    },
    { value: "FTO", label: "Filing Transfer Out" },
    { value: "IA", label: "Indemnity Agreements" },
    { value: "Notarizations", label: "Notarizations" },
    { value: "PA", label: "Powers of Attorney" },
    {
      value: "RSC",
      label: "Reactivation of struckoff companies",
    },
    {
      value: "RNR",
      label: "Registration of Notice of Resignation as Registered Agent",
    },
    {
      value: "FRRA",
      label: "Filing notice of Recission as Registered Agent",
    },
    {
      value: "RFC",
      label: "Registration as a Foreign Company in the BVI",
    },
    {
      value: "FNFC",
      label:
        "Filing Notice of Foreign Company Ceasing to Do Business in the BVI",
    },
    {
      value: "EFF",
      label: "Expedite Fee for Fast Track Due Diligence and Incorporation",
    },
    {
      value: "SL",
      label: "Solvent Liquidation",
    },
    {
      value: "other",
      label: "Other",
    },
  ];

  const docUploadSchema = {
    COI: { individual: [], corporateEntity: [] },
    COGS: { individual: [], corporateEntity: [] },
    ROD: {
      individual: ["Latest Client information Sheet"],
      corporateEntity: [],
    },
    SR: {
      individual: ["Latest Client information Sheet"],
      corporateEntity: [],
    },
    COD: {
      individual: [
        "Resolution of Appointment",
        "Resolution of Resignation",
        "Letter of Resignation",
        "Consent to Act as Director",
        "Passport/ID",
        "Address Proof",
        "CV",
        "Reference Letter",
        "Other",
      ],
      corporateEntity: [
        "Incorporation Certificate",
        "M&AA",
        "Share Registry",
        "ROD",
        "Seal",
        "Other",
      ],
    },
    COSH: {
      individual: [
        "Resolution of Appointment",
        "Instrument of Transfer",
        "Passport/ID",
        "Address Proof",
        "CV",
        "Reference Letter",
        "Other",
      ],
      corporateEntity: [
        "Incorporation Certificate",
        "M&AA",
        "Share Registry",
        "ROD",
        "Seal",
        "Resolution of Appointment",
        "Instrument of Transfer",
        "Other",
      ],
    },
    COA: {
      individual: [
        "Resolution of Change in Administration",
        "Client Information Sheet",
        "KYC 01",
        "KYC 02",
        "KYC 03",
      ],
      corporateEntity: [],
    },
    COC: {
      individual: [
        "Passport / ID Proof",
        "Address Proof",
        "Letter of Appointment as Contact Point",
      ],
      corporateEntity: [],
    },
    TO: {
      individual: [
        "Letter of Request - Transfer Out",
        "New RA Acceptance Letter",
      ],
      corporateEntity: [],
    },
    CO: { individual: [], corporateEntity: [] },
    AMAA: ["Resolution of Alteration to M&AA", "M&AA", "Other"],
    ARMA: { individual: [], corporateEntity: [] },
    Apostilles: { individual: [], corporateEntity: [] },
    CGS: { individual: [], corporateEntity: [] },
    RCAD: { individual: [], corporateEntity: [] },
    RUD: { individual: [], corporateEntity: [] },
    RPEC: { individual: [], corporateEntity: [] },
    CI: { individual: [], corporateEntity: [] },
    CCPD: { individual: [], corporateEntity: [] },
    ARD: { individual: [], corporateEntity: [] },
    ARM: { individual: [], corporateEntity: [] },
    Continuations: { individual: [], corporateEntity: [] },
    DR: {
      individual: [
        "Resolution of Appointment",
        "Resolution of Resignation",
        "Letter of Resignation",
        "Consent to Act as Director",
        "Passport/ID",
        "Address Proof",
        "CV",
        "Reference Letter",
        "Other",
      ],
      corporateEntity: [],
    },
    FRMC: { individual: [], corporateEntity: [] },
    FAR: { individual: [], corporateEntity: [] },
    FTO: { individual: [], corporateEntity: [] },
    IA: { individual: [], corporateEntity: [] },
    Notarizations: { individual: [], corporateEntity: [] },
    PA: { individual: [], corporateEntity: [] },
    RSC: { individual: [], corporateEntity: [] },
    RNR: { individual: [], corporateEntity: [] },
    FRRA: { individual: [], corporateEntity: [] },
    RFC: { individual: [], corporateEntity: [] },
    FNFC: { individual: [], corporateEntity: [] },
    EFF: { individual: [], corporateEntity: [] },
    SL: {
      individual: [
        "Liquidation Plan",
        "Statement of Assets and Liabilities",
        "Blank Indmenity Agreement",
        "CP New Engagement Application Form",
        "Declaration of Solvency",
        "Notice of Appointment of Liquidator",
        "Notice of Appointment Confirmation",
        "Written Resolution of the Shareholders",
        "Written Resolutions of the Directors",
        "New Procedures - Voluntary Liquidations",
      ],
      corporateEntity: [],
    },
    other: { individual: [], corporateEntity: [] },
  };
  const transferOut = [
    "Transfer Out Resolution",
    "Updated KYC",
    "Additional Documents",
  ];
  const getSelectType = (value) => {
    let find = postIncorpType.find((t) => t.value == value);
    getFeeDetails(value);
    setSelectedType(find);
  };
  const onChangeHandler = (setIdentifierState, event) => {
    setIdentifierState(event.target.value);
  };
  const getFeeDetails = (value) => {
    switch (value) {
      case "COI":
        setServiceFee(60);
        break;
      case "COGS":
        setServiceFee(100);
        break;
      case "ROD":
        setServiceFee(50);
        break;
      case "SR":
        setServiceFee(120);
        break;
      case "COD":
        setServiceFee(150);
        break;
      case "COSH":
        setServiceFee(100);
        break;
      case "COA":
        setServiceFee(80);
        break;
      case "COC":
        setServiceFee(200);
        break;
      case "TO":
        setServiceFee(170);
        break;
      case "CO":
        setServiceFee(50);
        break;
      default:
        break;
    }
  };

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  const formRef = useRef();

  let formSchema = [
    {
      name: "serviceType",
      value: selectedType?.value,
      type: "select",
      label: "Post Incorporation Service",
      placeholder: "Enter any type",
      options: postIncorpType,
      disabled: isDisabled,
      showSearch: true,
      allowClear: true,
    },
    {
      name: "entityId",
      value: "",
      type: "hidden",
    },
    // {
    //   customElement: <span>&nbsp;  </span>,
    // },
    {
      name: "entityCode",
      value: "",
      type: "select",
      label: "Entity Code",
      showSearch: true,
      placeholder: "Enter entity code",
      required: true,
    },
    {
      name: "name",
      value: "",
      type: "text",
      label: "Entity Name",
      placeholder: "Enter entity name",
      disabled: true,
      required: true,
    },
    {
      name: "serviceFee",
      label: "Service Fee",
      customElement: (
        <div className="input-field w-100">
          <div className="custom-badge">USD {serviceFee}.00</div>
        </div>
      ),
    },
    {
      name: "bankRef",
      value: "",
      type: "text",
      label: (
        <div className="d-flex gap-1">
          <p className="mb-0">Bank Reference</p>
          <Popover
            className="custom-popover"
            placement="rightBottom"
            content={
              <p className="mb-0">
                If you are on a pre-paid plan, enter the reference once a <br />
                payment for the service fee has been made.
              </p>
            }
          >
            <span className="label-info">
              <i className="fal fa-info-circle"></i>
            </span>
          </Popover>
        </div>
      ),
      placeholder: "Enter bank reference",
    },
  ];

  const getOnUpdate = async (value) => {
    let { serviceType, entityCode } = value;
    let params = { code: entityCode };
    let query = convertQueryParams(params);
    let payload = {
      method: "GET",
      url: `/entity?${query}`,
    };
    let data = await fetchApi(payload).then((res) => {
      return res.data.rows[0];
    });

    if (data) {
      value.entityId = data._id;
      value.name = data.name;
    }

    getSelectType(serviceType);
    setFormData(value);
  };

  const entityOnChange = async (value) => {
    let params = { code: value };
    let query = convertQueryParams(params);
    let payload = {
      method: "GET",
      url: `/entity?${query}`,
    };
    let data = await fetchApi(payload).then((res) => {
      return res.data.rows;
    });

    let list = data.map((d) => {
      let obj = {
        value: d?.code,
        label: d?.code,
      };
      return obj;
    });
    setEntityObjArray(list);
  };

  const submitOnClick = () => {
    try {
      const valid = formRef.current.validForm();
      const individualValid = individualFormRef?.current?.validForm();
      const corporateValid = corporateFormRef?.current?.validForm();
      const optionValid = optionFormRef?.current?.validForm();
      if (!valid) {
        sendNotify("error", "Some fields are missing!");
        return;
      }
      console.log(documents, subDocuments, "subDocuments");
      const obj = formRef.current.getPayload();
      obj.serviceFee = serviceFee;
      obj.documents = documents.length ? documents : subDocuments;

      if (individualValid) {
        const individual = individualFormRef.current.getPayload();
        obj.individual = individual;
        obj.individual.documents = documents;
      }
      if (corporateValid) {
        const corporate = corporateFormRef.current.getPayload();
        obj.corporate = corporate;
        obj.corporate.documents = documents;
      }
      if (alterationType) {
        obj.alterationType = alterationType;
      }
      if (optionValid) {
        const option = optionFormRef.current.getPayload();
        obj.option = option;
      }
      if (documentType) {
        obj.documentType = documentType;
      }
      if (receiveAs) {
        obj.receiveAs = receiveAs;
        obj.note = note;
        obj.address = address;
      }
      console.log(obj, "asdtyguhojlk");

      const payload = {
        method: "POST",
        url: `/serviceRequest`,
        data: obj,
      };

      fetchApi(payload)
        .then((res) => {
          sendNotify("success", res?.message);
          setTimeout(() => {
            //   window.location.href = `/app/entity-overview/list?type=entity&id=${options.entityId}`;
          }, 1000);
        })
        .catch((error) => {
          sendNotify("error", error?.message);
        });
    } catch (error) {
      sendNotify("error", error?.message);
    }
  };
  const onSubmit = () => {
    success({
      title: <h5>Successfully Submitted</h5>,
      content: <p>Your request has been submitted. </p>,
      className: "custom-confirm",
      centered: true,
      closable: true,
      okText: "Okay",
      onOk() {
        console.log("Okay");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const labels = [
    {
      key: 1,
      label: "INDIVIDUAL",
    },
    {
      key: 2,
      label: "CORPORATE ENTITY",
    },
  ];

  const onTabChange = (key) => {
    setActiveTab(key == 1 ? "individual" : "corporateKYC");
  };

  const individualFormRef = useRef();
  const corporateFormRef = useRef();
  const optionFormRef = useRef();
  let individualFormSchema = [
    {
      name: "name",
      value: "",
      type: "text",
      label: "Full Name",
      placeholder: "Enter full name of individual",
    },
    {
      name: "email",
      value: "",
      type: "email",
      label: "Email",
      placeholder: "Enter email address of individual",
    },
    {
      name: "contactNumber",
      value: "",
      type: "number",
      label: "Contact Number",
      placeholder: "Enter contact number of individual",
    },
  ];
  let corporateFormSchema = [
    {
      name: "name",
      value: "",
      type: "text",
      label: "Corporate Entity Name",
      placeholder: "Enter corporate entity name",
    },
  ];
  let optionFormSchema = [
    {
      name: "name",
      value: "",
      type: "text",
      label: "Option 1",
      placeholder: "Enter name",
    },
    {
      name: "name",
      value: "",
      type: "text",
      label: "Option 2",
      placeholder: "Enter name",
    },
    {
      name: "name",
      value: "",
      type: "text",
      label: "Option 3",
      placeholder: "Enter name",
    },
  ];

  const checkStandard = (key) => {
    switch (key) {
      case "AMAA":
        return true;
        break;
      case "MNC":
        return true;
        break;
      case "NOD":
        return true;
        break;
      case "AOD":
        return true;
        break;
      case "CD":
        return true;
        break;
      case "other":
        return true;
        break;
      case "TO":
        return true;
        break;
      case "SL":
        return true;
        break;
      default:
        return false;
        break;
    }
  };
  const documentTypeOnChange = (value) => {
    setDocumentType(value);
  };
  return (
    <div>
      <div className="page-header">
        <h1>New Post Incorporation Request</h1>
        {/* <h2 className="primary-title mb-4">New Post Incorporation Request</h2> */}
        {/* <Link to={"/app/entity-overview/list"} className="primary-btn">
          Submit
        </Link> */}
        <div onClick={submitOnClick} className="primary-btn">
          Create
        </div>
      </div>
      <div className="page-content">
        <FormZ
          formSchema={formSchema}
          ref={formRef}
          formClass="custom-form row mb-4 gy-3 gx-4"
          onChange={getOnUpdate}
          formData={formData}
          onKeyUp={(e) => entityOnChange(e.target.value)}
          formOptions={{ entityCode: entityObjArray }}
          childClass="col-md-5 mb-0"
          labelClass="mb-1"
          inputClass="w-100"
        />
        {/* <div className="row custom-form mb-4 align-items-end gap-ms-2">
        <div className="col-md-3">
          <label htmlFor="">Entity Name</label>
          <input
            type="text"
            className="w-100"
            placeholder="Enter entity name"
          />
        </div>
        <div className="col-md-3">
          <label htmlFor="">Entity Code</label>
          <input
            type="text"
            className="w-100"
            placeholder="Enter entity code"
          />
        </div>
      </div> */}
        {selectedType && checkStandard(selectedType?.value) ? (
          <div className="custom-form">
            {selectedType?.value == "AMAA" && (
              <div>
                <div className="row mb-4">
                  <div className="col-md-4">
                    <div>
                      <label htmlFor="">Type of Alteration</label>
                      <Select
                        className="custom-select w-100 ptb-1"
                        placeholder="Select type(s) of alteration"
                        mode="multiple"
                        allowClear
                        options={[
                          {
                            value: "Add a clause",
                            label: "Add a clause",
                          },
                          {
                            value: "Increase shares",
                            label: "Increase shares",
                          },
                          {
                            value: "Restate the entire M&AA",
                            label: "Restate the entire M&AA",
                          },
                        ]}
                        onChange={(value) => setAlterationType(value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row gy-2 gx-4">
                  <Each
                    array={docUploadSchema[selectedType?.value]}
                    render={(item) => (
                      <div className="col-md-3">
                        <label htmlFor={item} className="me-3">
                          {item}
                        </label>
                        <UploadZ
                          onStoreFile={storeDocuments}
                          fileName={item}
                          customClass="mb-0"
                        />
                      </div>
                    )}
                  />
                </div>
              </div>
            )}
            {selectedType?.value == "MNC" && (
              <div className="row gy-3 gx-4">
                <FormZ
                  ref={optionFormRef}
                  formSchema={optionFormSchema}
                  formClass="custom-form row mb-4 gy-3"
                  childClass="col-md-5 mb-0"
                  labelClass="mb-1"
                  inputClass="w-100"
                />
              </div>
            )}
            {(selectedType?.value == "NOD" ||
              selectedType?.value == "AOD" ||
              selectedType?.value == "CD") && (
              <div className="row gy-3 gx-4">
                <div className="col-md-5">
                  <label htmlFor="">Document Type</label>
                  <Select
                    className="custom-select w-100 ptb-1"
                    placeholder="Select document(s) "
                    mode="multiple"
                    allowClear
                    onChange={documentTypeOnChange}
                    options={[
                      {
                        value: "COI",
                        label: (
                          <div className="price-list" data-price="$50.00">
                            Certificate of Incumbency
                          </div>
                        ),
                      },
                      {
                        value: "COGS",
                        label: (
                          <div className="price-list" data-price="$50.00">
                            Certificate of Good Standing
                          </div>
                        ),
                      },
                      {
                        value: "COS",
                        label: (
                          <div className="price-list" data-price="$50.00">
                            Certificate of Shares
                          </div>
                        ),
                      },
                      {
                        value: "MAA",
                        label: (
                          <div className="price-list" data-price="$50.00">
                            M&AA
                          </div>
                        ),
                      },
                    ]}
                  />
                </div>
                <div className="col-md-5">
                  <label htmlFor="">Receive As</label>
                  <Select
                    className="custom-select w-100 ptb-1 mb-2"
                    placeholder="Select how you wish to receive the document(s)"
                    onChange={(value) => setReceiveAs(value)}
                    options={[
                      {
                        value: "hard-copy",
                        label: "Hard copy with physical courier",
                      },
                      {
                        value: "soft-copy",
                        label: "Soft copy",
                      },
                    ]}
                  />
                  {receiveAs == "hard-copy" && (
                    <p className="warning-text">
                      <i className="fal fa-exclamation-triangle"></i>
                      Courier charges will be based on destination.
                    </p>
                  )}
                </div>
                {receiveAs == "hard-copy" && (
                  <div className="col-md-5">
                    <label htmlFor="">Address</label>
                    <textarea
                      onChange={onChangeHandler.bind(null, setAddress)}
                      name=""
                      id=""
                      placeholder="Enter address"
                    ></textarea>
                  </div>
                )}
                <div className="col-md-5">
                  <label htmlFor="">Special Notes</label>
                  <textarea
                    name=""
                    id=""
                    onChange={onChangeHandler.bind(null, setNote)}
                    placeholder="Enter note"
                  ></textarea>
                </div>
              </div>
            )}
            {selectedType?.value == "other" && (
              <div className="custom-form">
                <div className="col-md-5 mb-3">
                  <label htmlFor="">Service Type</label>
                  <Select
                    className="custom-select w-100 ptb-1 mb-2"
                    placeholder="Select service type(s)"
                    mode="multiple"
                    allowClear
                    onChange={(value) => setServiceType(value)}
                    options={[
                      {
                        value: "reinstatement",
                        label: "Reinstatement",
                      },
                      {
                        value: "document-certification",
                        label: "Document Certification",
                      },
                    ]}
                  />
                </div>
                {serviceType.length > 0 && (
                  <div className="col-md-3 mb-4">
                    <label htmlFor="">Transfer Confirmation</label>
                    {/* <UploadZ customClass="mb-0" onStoreFile={storeDocument} /> */}
                    <Each
                      array={arr}
                      render={(item) => (
                        <UploadZ onStoreFile={storeDocuments} fileName={item} />
                      )}
                    />{" "}
                  </div>
                )}
                <h4 className="form-title">Additional Documents</h4>
                <div className="col-md-3 my-3">
                  <label htmlFor="">Other</label>
                  {/* <UploadZ customClass="mb-0" onStoreFile={storeDocument} /> */}

                  <Each
                    array={arr}
                    render={(item) => (
                      <UploadZ onStoreFile={storeDocuments} fileName={item} />
                    )}
                  />
                </div>
              </div>
            )}
            {selectedType?.value == "TO" && (
              <div className="custom-form">
                <p className="note-text">
                  NOTE: An updated KYC review will be conducted and if there is
                  any information or documents pending, it will need to be
                  submitted before completing this request.
                </p>
                {/* <div className="row">
                  <div className="col-md-3 my-3">
                    <label htmlFor="">Transfer Out Resolution</label>
                    <UploadZ
                      customClass="mb-0"
                      onStoreFile={storeDocument}
                      fileName={item}
                    />
                  </div>
                  <div className="col-md-3 my-3">
                    <label htmlFor="">Updated KYC</label>
                    <UploadZ
                      customClass="mb-0"
                      onStoreFile={storeDocument}
                      fileName={item}
                    />
                  </div>
                </div>
                <h4 className="form-title">Additional Documents</h4>
                <div className="col-md-3 my-3">
                  <label htmlFor="">Other</label>
                  <UploadZ
                    customClass="mb-0"
                    onStoreFile={storeDocument}
                    fileName={item}
                  />
                </div> */}
                <div className="row">
                  <Each
                    array={transferOut}
                    render={(item) => (
                      <div className="col-md-4">
                        <label htmlFor={item} className="me-3">
                          {item}
                        </label>
                        <UploadZ onStoreFile={storeDocument} fileName={item} />
                      </div>
                    )}
                  />
                </div>{" "}
              </div>
            )}
            {selectedType?.value == "SL" && (
              <div className="row gy-2 gx-4 mt-1">
                <Each
                  array={docUploadSchema[selectedType?.value]["individual"]}
                  render={(item) => (
                    <div className="col-md-3">
                      <label htmlFor={item} className="me-3">
                        {item}
                      </label>
                      <UploadZ
                        onStoreFile={storeDocuments}
                        fileName={item}
                        customClass="mb-0"
                      />
                    </div>
                  )}
                />
              </div>
            )}
          </div>
        ) : (
          docUploadSchema[selectedType?.value]?.individual?.length > 0 && (
            <div>
              {selectedType?.value == "DR" && (
                <div className="custom-form">
                  <div className="col-md-5 mb-3">
                    <label htmlFor="">Resolution Type</label>
                    <Select
                      className="custom-select w-100 ptb-1 mb-2"
                      placeholder="Select resolution type(s)"
                      options={[
                        {
                          value: "Lorem Ipsum",
                          label: "Lorem Ipsum",
                        },
                        {
                          value: "Lorem Ipsum",
                          label: "Lorem Ipsum",
                        },
                      ]}
                    />
                  </div>
                  <div className="col-md-5 mb-3">
                    <label htmlFor="">Description/Cause for Resolution</label>
                    <textarea
                      name=""
                      id=""
                      className="w-100"
                      placeholder="Lorem Ipsum"
                    ></textarea>
                  </div>
                  <h4 className="form-title">
                    Individuals/Corporate Entities Involved
                  </h4>
                </div>
              )}
              <div className="my-3 my-md-4">
                <TabZ
                  labels={labels}
                  defaultActive={"1"}
                  onChange={onTabChange}
                />
              </div>
              <div
                className={`hide-content ${
                  activeTab == "individual" ? "show" : ""
                }`}
              >
                <div className="custom-box">
                  <span className="light-badge mb-4">
                    <i className="fal fa-user-circle"></i> Individual
                  </span>
                  <FormZ
                    ref={individualFormRef}
                    formSchema={individualFormSchema}
                    formClass="custom-form row mb-4 gy-3"
                    childClass="col-md-4 mb-0"
                    labelClass="mb-1"
                    inputClass="w-100"
                  />
                  <div className="row custom-form gy-2 gx-4">
                    <Each
                      array={docUploadSchema[selectedType?.value]["individual"]}
                      render={(item) => (
                        <div className="col-md-3">
                          <label htmlFor={item} className="me-3">
                            {item}
                          </label>
                          <UploadZ
                            onStoreFile={storeDocuments}
                            fileName={item}
                            customClass="mb-0"
                          />
                        </div>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div
                className={`hide-content ${
                  activeTab == "corporateKYC" ? "show" : ""
                }`}
              >
                <div className="custom-box">
                  <span className="light-badge mb-4">
                    <i className="fal fa-building"></i> Corporate Entity
                  </span>
                  {/* <div className="row mb-4">
                    <div className="col-md-4 custom-form">
                      <label htmlFor="name">Corporate Entity Name</label>
                      <input
                        className="w-100"
                        type="text"
                        placeholder="Enter corporate entity name"
                        name="name"
                        id="name"
                      />
                    </div>
                  </div> */}

                  <FormZ
                    ref={corporateFormRef}
                    formSchema={corporateFormSchema}
                    formClass="custom-form row mb-4 gy-3"
                    childClass="col-md-4 mb-0"
                    labelClass="mb-1"
                    inputClass="w-100"
                  />
                  <div className="row custom-form gy-2 gx-4">
                    <Each
                      array={
                        docUploadSchema[selectedType?.value]["corporateEntity"]
                      }
                      render={(item) => (
                        <div className="col-md-3">
                          <label htmlFor={item} className="me-3">
                            {item}
                          </label>
                          <UploadZ
                            onStoreFile={storeDocuments}
                            fileName={item}
                            customClass="mb-0"
                          />
                        </div>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
}
