import React, { useRef, useState, useEffect } from "react";
import { Table, Select, TreeSelect, Spin, Input, Button, Form, Popconfirm, message } from "antd";
import { SaveOutlined, CloseOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import FormZ from "./FormZ";
import "../assets/css/style.scss";
import { calculateLevelOfRisk, getOptions } from "../helper";
import useRiskAssessment from "../hooks/useRiskAssessment";
import { useSelector } from "react-redux";
import TableSortArrows from "./tableSortArrows";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import html2pdf from "html2pdf.js";
import PDFControls from "./PDFControls";
import countries from "../helper/countries.json";
import useAuthorizedIndividuals from "../hooks/useAuthorizedIndividuals";
import axios from "axios";
 
const EntityRAF = ({
  entityDetailFormData,
  entityDetailsOnChange,
  countryRiskDtaOnChange,
  countryRiskFromData,
  overallRiskFromData,
  overallRiskDataOnChange,
  isCooperate,
}) => {
  const defaultFormData = {
    entityId: "",
    countryRiskCountry: "",
    countryRiskCPI: "",
    countryRiskBasel: "",
    countryRiskFatfGrey: "",
    countryRiskSanction: "",
    typeOfEntity: isCooperate ? "CO_OPERATE" : "ENTITY",
    overallRiskScore: 0,
    overallRiskRating: "LOW",
    preparedDate: "",
    countryRiskScore: 0,
    businessStructureRiskScore: 0,
    productServiceRiskScore: 0,
    serviceProductsDeliveryRiskScore: 0,
    entityRiskScore: 0,
    preparedBy: "",
    approvedBy: "",
    approvedDate: "",
  };  
  const entityDetailFormRef = useRef();
  const countryRiskFromRef = useRef();
  const overallRiskFormRef = useRef();
  const [businessGeography, setBusinessGeography] = useState([]);
  const { userDetails } = useSelector((state) => state.auth);
  const pdfRef = useRef();
  const [countriesOption, setCountriesOption] = useState([]);
  const [formData, setFormData] = useState(defaultFormData);
  const {data: riskAssessmentData, saveData } = useRiskAssessment();
  const [inputTbleAuthIndiData, setInputTblAuthIndiData] = useState([]);
  const [count, setCount] = useState(inputTbleAuthIndiData.length);
  const { authIndiData, tableDataLoading, fetchAuthorizedIndividuals } = useAuthorizedIndividuals();
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const [overallRiskScore, setOverallRiskScore] = useState(0);
  const [overallRiskRating, setOverallRiskRating] = useState("LOW"); 
  const { data: countryData, isLoading } = useSelector((state) => state.country);

  const handleScoreChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  
    calculateOverallScore();
  };
 
  const calculateOverallScore = () => {
 
    const total =
      (parseInt(formData?.entityRiskScore) || 0) +
      (parseInt(formData?.countryRiskScore) || 0) +
      (parseInt(formData?.businessStructureRiskScore) || 0) +
      (parseInt(formData?.productServiceRiskScore) || 0) +
      (parseInt(formData?.serviceProductsDeliveryRiskScore) || 0);
      
 
      // setFormData((prevFormData) => ({
      //   ...prevFormData,
      //   overallRiskScore: total,
      //   preparedBy:riskAssessmentData?.preparedBy || "",
      //   preparedDate: riskAssessmentData?.preparedDate || "",
      //   approvedBy:riskAssessmentData?.approvedBy || "",
      //   approvedDate: riskAssessmentData?.approvedDate || "",
      //   authorizedEntities: riskAssessmentData.authorizedEntities || [] 
      // }));
  
    console.log(`Overall Risk Score: ${total}`);

    setOverallRiskScore(total);
    setOverallRiskRating(calculateLevelOfRisk(total));
  
    overallRiskDataOnChange({
      ...overallRiskFromData,
      overallRiskScore: total.toString(),
      overallRiskRating: calculateLevelOfRisk(total),
      preparedBy:riskAssessmentData?.preparedBy || "",
      preparedDate: riskAssessmentData?.preparedDate || "",
      approvedBy:riskAssessmentData?.approvedBy || "",
      approvedDate: riskAssessmentData?.approvedDate || "",
    });
  };
 
  const { SHOW_PARENT } = TreeSelect;
 
  const handleCountryRiskChange = (newData) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      ...newData,
    }));
    countryRiskDtaOnChange({
      ...countryRiskFromData,
      countryRiskCountry: newData.countryRiskCountry,
      countryRiskCPI: newData.countryRiskCPI,
      countryRiskBasel: newData.countryRiskBasel,
      countryRiskFatfGrey: newData.countryRiskFatfGrey,
      countryRiskSanction: newData?.countryRiskSanction,
    });
  };

  const service_products_delivery_channels_columns = [
    {
      title: "Services/Products Delivery Channels",
      dataIndex: "name",
    },
    {
      title: "Score",
      dataIndex: "score",
    },
  ];
  const product_services_columns = [
    {
      title: "Products/Services provided",
      dataIndex: "name",
    },
    {
      title: "Score",
      dataIndex: "score",
    },
  ];
  const business_structure_columns = [
    {
      title: "Business Structure/Activities",
      dataIndex: "name",
    },
    {
      title: "Score",
      dataIndex: "score",
    },
  ];
 
  const country_risk_columns = [
    {
      title: "Country Risk",
      dataIndex: "name",
    },
    {
      title: "Score",
      dataIndex: "score",
    },
  ];
 
  const entity_risk_columns = [
    {
      title: "Entity Risk",
      dataIndex: "name",
    },
    {
      title: "Score",
      dataIndex: "score",
    },
  ];
 
  const authIndicolumns = [
    {
      title: "Name",
      dataIndex: "name",
      width: 'auto',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Type",
      dataIndex: "type",
      width: 'auto',
      sorter: (a, b) => a.type.localeCompare(b.type),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Designation",
      dataIndex: "designation",
      width: '10px',
      render: (text) => (       
      <div style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>        
        {
          text.split(',').map((word, index) => (          
            <span key={index}>            
            {word.trim()}            
            <br />
            </span> 
          ))
        } 
        </div> ),
      sorter: (a, b) => a.designation.localeCompare(b.designation),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "PEP",
      dataIndex: "pep",
      width: 'auto',
      sorter: (a, b) => a.pep.localeCompare(b.pep),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Sanctioned",
      dataIndex: "sanctioned",
      width: 'auto',
      sorter: (a, b) => a.sanctioned.localeCompare(b.sanctioned),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Signatures",
      dataIndex: "sampleSignatures",
      width: 'auto',
      sorter: (a, b) => a.sampleSignatures.localeCompare(b.sampleSignatures),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Country",
      dataIndex: "countryOfResidence",
      width: 'auto',
      sorter: (a, b) =>
        a.countryOfResidence.localeCompare(b.countryOfResidence),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
  
    {
      title: () => (       
      <div>        
        Listed/<br />        
        Regulated      
      </div> ),
      dataIndex: "listedRegulated",
      width: 'auto',
      sorter: (a, b) => a.listedRegulated.localeCompare(b.listedRegulated),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Risk",
      dataIndex: "stakeholderRisk",
      width: 'auto',
      sorter: (a, b) => a.stakeholderRisk.localeCompare(b.stakeholderRisk),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
  ];
  
  const entityRiskData = [
    {
      id: 1,
      name: "Regulated financial institutions / Companies listed on a recognised stock exchange.",
      score: 4,
      colName: "entityRiskScore",
    },
    {
      id: 2,
      name: "Wholly owned subsidiaries of companies listed on a recognised stock exchange.",
      score: 8,
      colName: "entityRiskScore",
    },
    {
      id: 3,
      name: "Non Regulated Entities / associated with a Domestic or International Organization PEP with lower risk factors",
      score: 12,
      colName: "entityRiskScore",
    },
    {
      id: 4,
      name: "State Owned Entities / Non Government Organisations",
      score: 16,
      colName: "entityRiskScore",
    },
    {
      id: 5,
      name: "Associated with a Foreign PEP / Adverse media",
      score: 20,
      colName: "entityRiskScore",
    },
  ];
 
  const businessStructureData = [
    {
      id: 1,
      name: "Single Layer Ownership",
      score: 5,
      colName: "businessStructureRiskScore",
    },
    {
      id: 2,
      name: "Complex Ownership structure",
      score: 10,
      colName: "businessStructureRiskScore",
    },
    {
      id: 3,
      name: "Non-transparent structure/opaque",
      score: 15,
      colName: "businessStructureRiskScore",
    },
    {
      id: 4,
      name: "Bearer shares/Unknown/Virtual Assets Service Provider/Crypto related business structure",
      score: 20,
      colName: "businessStructureRiskScore",
    },
  ];
 
  const countryRiskData = [
    {
      id: 1,
      name: "Recognized jurisdiction / Low Risk Countries as per Basel and Corruption Perceptions Index / FATF Compliant",
      score: 4,
      colName: "countryRiskScore",
    },
    {
      id: 2,
      name: "FATF Grey-Listed / Medium Risk as per Basel / Corruption Perceptions Index",
      score: 8,
      colName: "countryRiskScore",
    },
    {
      id: 3,
      name: "High risk countries as per Basel and Corruption Perceptions Index / FATF Black-Listed / subject to sanctions (other than UK/UN sanctions)",
      score: 12,
      colName: "countryRiskScore",
    },
    {
      id: 4,
      name: "Subject to UK/UN Sanction",
      score: 16,
      colName: "countryRiskScore",
    },
    {
      id: 5,
      name: "Terrorist Sponsored state / Unknown",
      score: 20,
      colName: "countryRiskScore",
    },
  ];
 
  const productServiceData = [
    {
      id: 1,
      name: "Value-added services (e.g. directorship services)",
      score: 4,
      colName:"productServiceRiskScore", 
    },
    {
      id: 2,
      name: "non value-added services (e.g. Registered Office/Registered Agent (RO/RA)",
      score: 8,
      colName:"productServiceRiskScore", 
    },
  ];
 
  const serviceProductsDeliveryChannelsData = [
    {
      id: 1,
      name: "Face-to-face relationships",
      score: 4,
      colName: "serviceProductsDeliveryRiskScore",
    },
    {
      id:2,
      name: "Remote interactions (e.g. via video and/or audio media platforms)",
      score: 8,
      colName: "serviceProductsDeliveryRiskScore",
    },
    {
      id:3,
      name: "Non-face to face business relationships",
      score: 12,
      colName: "serviceProductsDeliveryRiskScore",
    },
  ];
 
  const riskLevels = [
    {
      label: "Low Risk",
      value: "0-40",
      bgColor: "rgba(76, 248, 76, 0.62)",
      headerBgColor: "rgba(76, 248, 76, 0.82)",
    },
    {
      label: "Medium Risk",
      value: "41-70",
      bgColor: "rgba(240, 128, 128, 0.7)",
      headerBgColor: "rgba(240, 128, 128, 0.88)",
    },
    {
      label: "High Risk",
      value: "71-100",
      bgColor: "rgba(255, 0, 0, 0.63)",
      headerBgColor: "rgba(255, 0, 0, 0.84)",
    },
  ];
  const convertToOptions = () => {
    let options = [
      {
        title: "Worldwide",
        value: "0",
        key: "0",
        children: [],
      },
    ];
 
    Object.keys(countries).forEach(function (key, index) {
      let continents = {
        title: key,
        value: `0-${index}`,
        key: `0-${index}`,
        children: [],
      };
      countries[key].forEach((country, i) => {
        let cat = {
          title: country?.name,
          value: `0-${index}-${i}`,
          key: `0-${index}-${i}`,
        };
        continents.children.push(cat);
      });
 
      options[0]?.children.push(continents);
    });
 
    setCountriesOption(options);
  };
  const onChange = (newValue) => {
    setBusinessGeography(newValue);
  };
  const rowSelection = {
    type: "radio",
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );

      if (selectedRows.length > 0) {
        const record = selectedRows[0];
        handleScoreChange(record.colName, record.score);
      }
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };
 
  const tProps = {
    treeData: countriesOption,
    value: businessGeography,
    onChange,
    className: "custom-select w-100 ptb-1",
    treeCheckable: true,
    showSearch: false,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: "Select geography of business",
    allowClear: true,
  };
 
  let overallRiskFormSchema = [
    {
      name: "overallRiskScore",
      value: "",
      type: "text",
      label: "Overall Risk Score",
      placeholder: "Enter Overall Risk Score",
      disabled: true,
    },
    {
      name: "overallRiskRating",
      value: "",
      type: "select",
      label: "Overall Risk Rating",
      placeholder: "Select Overall Risk Rating",
      options: [
        { value: "Low", label: "Low" },
        { value: "Medium", label: "Medium" },
        { value: "High", label: "High" },
      ],
      disabled: true,
    },
    {
      name: "preparedBy",
      value: "",
      type: "text",
      label: "Prepared By",
      placeholder: "Enter Prepared By",
      disabled: true,
    },
    {
      name: "preparedDate",
      value: "",
      type: "date",
      label: "Prepared Date",
      placeholder: "Enter Date (YYYY-MM-DD)",
      disabled: true,
    },
    {
      name: "approvedBy",
      value: "",
      type: "text",
      label: "Approved By",
      placeholder: "Enter Approved By",
      disabled: true,
    },
    {
      name: "approvedDate",
      value: "",
      type: "date",
      label: "Approved Date",
      placeholder: "Enter Date (YYYY-MM-DD)",
      disabled: true,
    },
  ];
 
  let countryRiskFormSchema = [
    {
      name: "countryRiskCountry",
      value: "",
      type: "select",
      label: "Country",
      placeholder: "Enter country",
      options: getOptions(countryData),
      loading: isLoading,
      showSearch: true,
    },
    {
      name: "countryRiskCPI",
      value: "",
      type: "text",
      label: "CPI",
      placeholder: "Enter CPI",
    },
    {
      name: "countryRiskBasel",
      value: "",
      type: "text",
      label: "Basel",
      placeholder: "Enter Basel",
    },
    {
      name: "countryRiskFatfGrey",
      value: "",
      type: "select",
      label: "FATF ",
      placeholder: "Select Yes or No ",
      options: [
        { value: "Yes", label: "Yes" },
        { value: "No", label: "No" },
      ],
    },
    {
      name: "countryRiskSanction",
      value: "",
      type: "select",
      label: "Sanction",
      placeholder: "Select yes or No",
      options: [
        { value: "Yes", label: "Yes" },
        { value: "No", label: "No" },
      ],
    },
  ];
 
  let entityDetailFormSchema = [
    {
      name: "name",
      value: "",
      type: "text",
      label: "Entity Name",
      placeholder: "Enter entity name",
      disabled: true,
    },
    // {
    //   name: "busGeography",
    //   value: "",
    //   type: "text",
    //   label: "Geography of Business",
    //   placeholder: "Enter Geography of Business",
    // },
    {
      label: "Geography of Business",
      customElement: <TreeSelect {...tProps} placement="topLeft" />,
      disabled: true,
      // required: true,
    },
    {
      name: "busDesc",
      value: "",
      type: "textarea",
      label: "Description of Client",
      placeholder: "Enter description of client",
      customClass: "col-md-5",
      disabled: true,
    },
    {
      name: "address",
      value: "",
      type: "textarea",
      label: "Address",
      placeholder: "Enter address",
      customClass: "col-md-5",
      disabled: true,
    },
  ];
  const generatePdf = () => {
    const element = pdfRef.current;
    const opt = {
      margin: 0.5,
      filename: "EntityRAF.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 4, logging: true, dpi: 192, letterRendering: true },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };
    html2pdf().from(element).set(opt).save();
    return opt;
  };

  const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
    const inputNode = <Input />;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0, width: "auto"  }}
  
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({ 
      name: '', 
      type: '', 
      designation: '', 
      wcResult: '', 
      pep: '', 
      sanctioned: '', 
      adverseMedia: '', 
      sampleSignatures: '', 
      sourceOfWealth: '', 
      countryOfResidence: '', 
      nationality: '', 
      listedRegulated: '', 
      stakeholderRisk: '', 
      ...record 
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...inputTbleAuthIndiData];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setInputTblAuthIndiData(newData);
        setEditingKey('');
      } else {
        newData.push(row);
        setInputTblAuthIndiData(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const handleAdd = () => {
    const newData = {
      key: (count + 1).toString(),
      name: '',
      type: '',
      designation: '',
      wcResult: '',
      pep: '',
      sanctioned: '',
      adverseMedia: '',
      sampleSignatures: '',
      sourceOfWealth: '',
      countryOfResidence: '',
      nationality: '',
      listedRegulated: '',
      stakeholderRisk: '',
    };
    setInputTblAuthIndiData([...inputTbleAuthIndiData, newData]);
    setCount(count + 1);
    setEditingKey(newData.key);
    form.setFieldsValue({ 
      name: '', 
      type: '', 
      designation: '', 
      wcResult: '', 
      pep: '', 
      sanctioned: '', 
      adverseMedia: '', 
      sampleSignatures: '', 
      sourceOfWealth: '', 
      countryOfResidence: '', 
      nationality: '', 
      listedRegulated: '', 
      stakeholderRisk: '', 
      ...newData 
    });
  };

  const handleDelete = (key) => {
    const newData = inputTbleAuthIndiData.filter((item) => item.key !== key);
    setInputTblAuthIndiData(newData);
  };

  const handleSubmit = async () => {
    try {
      await axios.post('https://your-api-endpoint.com/submit', inputTbleAuthIndiData);
      message.success('Data submitted successfully!');
    } catch (error) {
      message.error('Failed to submit data.');
      console.error('Submit error:', error);
    }
  };

  const columns = [
    { title: 'Name', dataIndex: 'name', width: 'auto', editable: true },
    { title: 'Type', dataIndex: 'type', width: 'auto', editable: true },
    { title: 'Designation', dataIndex: 'designation', editable: true, width: '10px',
      render: (text) => (       
      <div style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>        
        {
          text.split(',').map((word, index) => (          
            <span key={index}>            
            {word.trim()}            
            <br />
            </span> 
          ))
        } 
      </div> ),
    },
    { title: 'PEP', dataIndex: 'pep', width: 'auto', editable: true },
    { title: 'Sanctioned', dataIndex: 'sanctioned', width: 'auto', editable: true },
    { title: 'Sample Signatures', dataIndex: 'sampleSignatures', width: 'auto', editable: true },
    { title: 'Country', dataIndex: 'countryOfResidence', width: 'auto', editable: true },
    { title: () => (       
      <div>        
        Listed/<br />        
        Regulated      
      </div> ), 
      dataIndex: 'listedRegulated', width: 'auto', editable: true,  
    },
    { title: 'Risk', dataIndex: 'stakeholderRisk', width: 'auto', editable: true },
    {
      title: '',
      width: 'auto',
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span className="button-group">
            <Button onClick={() => save(record.key)} style={{ marginRight: 8 }} icon={<SaveOutlined />}/>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
            <Button icon={<CloseOutlined />}/>
            </Popconfirm>
          </span>
        ) : (
          <span className="button-group">
            <Button disabled={editingKey !== ''} onClick={() => edit(record)} style={{ marginRight: 8 }} icon={<EditOutlined />}/>

            <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
            <Button type="danger" icon={<DeleteOutlined />} />
            </Popconfirm>
          </span>
        );
      },
    },
    
  ];

  const inputTblauthIndicolumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
      record,
      inputType: 'text',
      dataIndex: col.dataIndex,
      title: col.title,
      editing: isEditing(record),
      }),
    };
  });

 
useEffect(() => {
    if (riskAssessmentData) {
      setFormData(riskAssessmentData);
      calculateOverallScore();
      countryRiskDtaOnChange({
        ...countryRiskFromData,
        countryRiskCountry: riskAssessmentData.countryRiskCountry || "",
        countryRiskCPI: riskAssessmentData.countryRiskCPI || "",
        countryRiskBasel: riskAssessmentData.countryRiskBasel || "",
        countryRiskFatfGrey: riskAssessmentData.countryRiskFatfGrey || "",
        countryRiskSanction: riskAssessmentData?.countryRiskSanction || "",
      });
      //set data for Authorized Individuals/Corporate table in cooperate RAF
      if(isCooperate){
        setInputTblAuthIndiData(riskAssessmentData.authorizedEntities || []);
        setCount((riskAssessmentData?.authorizedEntities?.length ?? 0));
      }
    }
    convertToOptions();
  }, [riskAssessmentData]);
 
  useEffect(() => {
    if (formData) {
      calculateOverallScore();
    }
  }, [formData]);

  useEffect(() => {
    if (entityDetailFormData?._id) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        entityId: entityDetailFormData._id,
      }));
      setBusinessGeography(entityDetailFormData.busGeography);
      //set data for Authorized Individuals/Corporate table in entity RAF
      if(!isCooperate){
        fetchAuthorizedIndividuals(entityDetailFormData?._id);
      }
    }
  }, [entityDetailFormData]);

  function checkUserRole(userDetails) {
    return userDetails.role === "lead" || userDetails.role === "admin";
  }

  function handleApproveClick(userDetails, setFormData, saveData) {
    setFormData((prevFormData) => {
      const updatedFormData = {
        ...prevFormData,
        approvedBy: userDetails.userName,
      };
      saveData(updatedFormData);
    });
  }
 
  return (
    <div className="page-header">
      <div className="page-content">
        <div className="pdf-zoom-wrapper">
          <TransformWrapper initialScale={1} centerOnInit panning={{ excluded: ['input'] }}>
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
              <div>
                <PDFControls generatePdf={generatePdf} />
                <TransformComponent wrapperClass="w-100 pdf-transform" contentClass="w-100">
                  <div
                    className="w-100 border-box mb-4"
                    ref={pdfRef}
                  >
                <h5 className="primary-title mb-4">Entity Details</h5>
                <FormZ
                  ref={entityDetailFormRef}
                  formSchema={entityDetailFormSchema}
                  formData={entityDetailFormData}
                  onChange={entityDetailsOnChange}
                  formClass="row custom-form mb-4 mb-md-5 gy-2 gx-4"
                  childClass="col-md-4 mb-0"
                  labelClass="mb-1"
                  inputClass="w-100"
                />
                <h5 className="primary-title mb-4">Authorized Individuals/Corporate</h5>
                {isCooperate? 

                <><span className="input-table-add-row">
                <Button onClick={handleAdd} type="primary" className="add-row-button">
                  Add new user
                </Button>
              </span>
              <Form form={form} component={false}>
                  <Table
                    className="custom-table mb-4"
                    components={{
                      body: {
                        cell: EditableCell,
                      },
                    }}
                    bordered
                    dataSource={inputTbleAuthIndiData}
                    columns={inputTblauthIndicolumns}
                    rowClassName="editable-row"
                    scroll={{ x: "max-content" }}
                    pagination={false} />
                </Form></>
                      
                  : tableDataLoading ? (                                                                                                                                       
                  <Spin spinning={tableDataLoading}/>
                  ) : (
                    <>
                  <Table
                    className="custom-table mb-4"
                    columns={authIndicolumns}
                    dataSource={authIndiData}
                    scroll={{ x: "max-content" }}
                    pagination={false} /></>
                )}
                <div className="col-md-3 ms-auto">
                  <div className="input-box w-100">
                    <label htmlFor="overallRisk" className="mb-1">
                      Overall Risk
                    </label>
                    <Select
                      id="overallRisk"
                      className="custom-select w-100"
                      placeholder="Select Overall Risk"
                      options={[
                        { value: "High", label: "High" },
                        { value: "Medium", label: "Medium" },
                        { value: "Low", label: "Low" },
                      ]}
                    />
                  </div>
                </div>
                <Table
                  className="custom-table mb3-"
                  columns={entity_risk_columns}
                  rowSelection={{ type: "radio", ...rowSelection }}
                  rowKey={"id"}
                  dataSource={entityRiskData}
                  scroll={{ x: 500 }}
                  pagination={false}
                />
                <div className="col-md-3 ms-auto">
                  <div className="input-box w-100">
                    <label htmlFor="score" className="mb-1">
                      Score
                    </label>
                    <input
                      id="score"
                      type="number"
                      className="w-100"
                      placeholder="Enter Score"
                      value={formData?.entityRiskScore}
                      min="0"
                      max="20"
                      disabled= {true}
                    />
                  </div>
                </div>
                <h5 className="primary-title mb-4">Country Risk</h5>
                <FormZ
                  ref={countryRiskFromRef}
                  formSchema={countryRiskFormSchema}
                  formData={countryRiskFromData}
                  onChange={handleCountryRiskChange}
                  formClass="row custom-form mb-4 mb-md-5 gy-3 gx-4"
                  childClass="col-md-4 mb-0"
                  labelClass="mb-1"
                  inputClass="w-100"
                />
                <Table
                  className="custom-table mb3-"
                  columns={country_risk_columns}
                  rowSelection={{ type: "radio", ...rowSelection }}
                  rowKey={"id"}
                  dataSource={countryRiskData}
                  scroll={{ x: 500 }}
                  pagination={false}
                />
                <div className="col-md-3 ms-auto">
                  <div className="input-box w-100">
                    <label htmlFor="countryRiskScore" className="mb-1">
                      Score
                    </label>
                    <input
                      id="countryRiskScore"
                      type="number"
                      className="w-100"
                      placeholder="Enter Score"
                      value={formData?.countryRiskScore}
                      min="0"
                      max="20"
                      disabled= {true}
                    />
                  </div>
                </div>
                <Table
                  className="custom-table mb3-"
                  columns={business_structure_columns}
                  rowSelection={{ type: "radio", ...rowSelection }}
                  rowKey={"id"}
                  dataSource={businessStructureData}
                  scroll={{ x: 500 }}
                  pagination={false}
                />
                <div className="col-md-3 ms-auto">
                  <div className="input-box w-100">
                    <label htmlFor="countryRiskScore" className="mb-1">
                      Score
                    </label>
                    <input
                      id="countryRiskScore"
                      type="number"
                      className="w-100"
                      placeholder="Enter Score"
                      value={formData?.businessStructureRiskScore}
                      min="0"
                      max="20"
                      disabled= {true}
                    />
                  </div>
                </div>
                <Table
                  className="custom-table mb3-"
                  columns={product_services_columns}
                  rowSelection={{ type: "radio", ...rowSelection }}
                  rowKey={"id"}
                  dataSource={productServiceData}
                  scroll={{ x: 500 }}
                  pagination={false}
                />
                <div className="col-md-3 ms-auto">
                  <div className="input-box w-100">
                    <label htmlFor="countryRiskScore" className="mb-1">
                      Score
                    </label>
                    <input
                      id="countryRiskScore"
                      type="number"
                      className="w-100"
                      placeholder="Enter Score"
                      value={formData?.productServiceRiskScore}
                      min="0"
                      max="20"
                      disabled={true}
                    />
                  </div>
                </div>
                <Table
                  className="custom-table mb3-"
                  columns={service_products_delivery_channels_columns}
                  rowSelection={{ type: "radio", ...rowSelection }}
                  rowKey={"id"}
                  dataSource={serviceProductsDeliveryChannelsData}
                  scroll={{ x: 500 }}
                  pagination={false}
                />
                <div className="col-md-3 ms-auto">
                  <div className="input-box w-100">
                    <label htmlFor="countryRiskScore" className="mb-1">
                      Score
                    </label>
                    <input
                      id="countryRiskScore"
                      type="number"
                      className="w-100"
                      placeholder="Enter Score"
                      value={formData?.serviceProductsDeliveryRiskScore}
                      min="0"
                      max="20"
                      disabled= {true}
                    />
                  </div>
                </div>
                <table className="risk-level-table-container page-break">
                  <thead>
                    <tr>
                      {riskLevels.map((risk, index) => (
                        <th
                          key={index}
                          className={`table-header ${risk.label
                            .toLowerCase()
                            .replace(" ", "-")}`}
                        >
                          {risk.label}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {riskLevels.map((risk, index) => (
                        <td
                          key={index}
                          className={`table-cell ${risk.label
                            .toLowerCase()
                            .replace(" ", "-")}`}
                        >
                          {risk.value}
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </table>
                <FormZ
                  ref={overallRiskFormRef}
                  formSchema={overallRiskFormSchema}
                  formData={overallRiskFromData}
                  onChange={overallRiskDataOnChange}
                  formClass="custom-form row gy-3"
                  childClass="col-md-4 mb-0"
                  labelClass="mb-1"
                  inputClass="w-100"
                />
                </div>
                </TransformComponent>
            </div>
            )}
          </TransformWrapper>
        </div>
        <div className="d-flex flex-wrap gap-3 mt-4 mb-5 mb-md-2">
          <button
            className="primary-btn"
            onClick={() => {
              const updatedFormData = {
                ...formData,
                overallRiskScore: overallRiskScore,
                overallRiskRating: overallRiskRating,
                authorizedEntities: inputTbleAuthIndiData,
              };
              saveData(updatedFormData);
            }}
          >
            Update
          </button>
          {checkUserRole(userDetails) && (
            <button
              className="approvedButton"
                onClick={() => handleApproveClick(userDetails, setFormData, saveData)}
          >
            Approve
          </button>
            )}
        </div>
      </div>
    </div>
      );
    };

    export default EntityRAF;
