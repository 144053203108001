import React, { useState, useEffect, useRef } from "react";

import { useSelector, useDispatch } from "react-redux";
import moment from "moment/moment";
import { Table, Tag, Select, Modal, Radio, Checkbox } from "antd";
import TableFilterZ from "../components/TableFilterZ";
import TabZ from "../components/TabZ";
import InProgressIcon from "../assets/img/in-progress-icon.svg";
import ApprovedIcon from "../assets/img/approved-icon.svg";
import FormZ from "../components/FormZ";
import TagZ from "../components/TagZ";
import TableSortArrows from "../components/tableSortArrows";
import { languagesArray } from "../store/language/languageSlice";
import { Link } from "react-router-dom";
import masterSearch from "../helper/translations/master-search.json";
import {
  downloadAsCSV,
  masterFileDownload,
  getQuery,
  fetchApi,
  convertQueryParams,
  customPagination,
  sendNotify,
  capitalizeFirstLetter,
} from "../helper";
import Loading from "../components/Loading";
const { confirm } = Modal;

export default function MasterSearch() {
  const [loading, setLoading] = useState(false);

  const { language } = useSelector((state) => state.language);
  const [totalCount, setTotalCount] = useState("");
  const [tableData, setTableData] = useState([]);
  const [query, setQuery] = useState({});
  const [selctedLanguage, setSelctedLanguage] = useState("");
  useEffect(() => {
    let index = languagesArray.find((tc) => tc.value == language);
    setSelctedLanguage(index.value);
  }, [language]);

  let e = masterSearch.entity[selctedLanguage] || masterSearch.entity.en;
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    let query = getQuery();
    setLoading(true);
    let params = {};
    params.isAll = true;
    params.id = query.id;
    params.masterType = query.masterType;
    let queryparams = convertQueryParams(params);
    let payload = {
      method: "GET",
      url: `/client?${queryparams}`,
    };

    let data = await fetchApi(payload).then((res) => res?.data);

    const modifiedRows = data?.rows?.[0]?.entityData?.map((row) => {
      return {
        ...row,
        type: row.type
          ? capitalizeFirstLetter(row.type)
          : capitalizeFirstLetter(row.masterType),
      };
    });
    setQuery(query);
    setTotalCount(data.totalCount);
    setTableData(modifiedRows);
    setLoading(false);
  };
  const entityColumns = [
    {
      title: e.EntityName,
      dataIndex: "name",
      filterType: "text",
      sorter: (a, b) => a.entityName - b.entityName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: e.EntityCode,
      dataIndex: "code",
      filterType: "text",
      sorter: (a, b) => a.entityCode - b.entityCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: e.EntityType,
      dataIndex: "type",
      filterType: "text",
      sorter: (a, b) => a.type - b.type,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: e.ClientName,
      dataIndex: "clientName",
      filterType: "text",
      sorter: (a, b) => a.clientName - b.clientName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: e.ClientCode,
      dataIndex: "clientCode",
      filterType: "text",
      sorter: (a, b) => a.clientCode - b.clientCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: e.ClientType,
      dataIndex: "clientType",
      filterType: "text",
      sorter: (a, b) => a.clientType - b.clientType,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },

    {
      title: e.IncorpNumber,
      dataIndex: "incorporationNumber",
      sorter: (a, b) => a.incorpNumber - b.incorpNumber,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: e.IncorpDate,
      dataIndex: "incorporationDate",
      sorter: (a, b) => a.incorpDate - b.incorpDate,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: e.IncorpBatch,
      dataIndex: "incorpBatch",
      sorter: (a, b) => a.incorpBatch - b.incorpBatch,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: e.RiskLevel,
      dataIndex: "riskLevel",
      sorter: (a, b) => a.riskLevel - b.riskLevel,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { status }) => (
        <TagZ
          status={true ? "approved" : "rejected"}
          statusText={true ? "Low" : "High"}
        />
      ),
    },

    {
      title: e.Status,
      dataIndex: "status",
      filterType: "select",
      filterOptions: [
        {
          value: "Active",
          label: "Active",
        },
        {
          value: "Inactive",
          label: "Inactive",
        },
      ],
      sorter: (a, b) => a.status - b.status,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { status }) => (
        <TagZ
          status={status == "active" ? "approved" : "rejected"}
          statusText={status?.charAt(0)?.toUpperCase() + status?.slice(1)} //
        />
      ),
    },
    {
      title: "Over view",
      dataIndex: "overview",
      // fixed: "right",
      // width: 150,
      render: (_, { _id, masterType }) => (
        <Link
          to={`/app/master-file-details/?type=${masterType}&id=${_id}`}
          className="action-btn icon-btn"
        >
          <i className="far fa-eye"></i>
        </Link>
      ),
    },
  ];
  const getPagination = async (current, pageSize) => {};
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record?.name === "Disabled User",
      // Column configuration not to be checked
      name: record?.name,
    }),
  };
  return (
    <div>
      <div className="page-header">
        {" "}
        {`Client Details/${query.name}/${query.code}`}
      </div>
      <div className="page-content">
        <Table
          className="custom-table"
          loading={loading}
          columns={entityColumns}
          rowKey={"_id"}
          scroll={{
            x: 1200,
          }}
          dataSource={tableData}
          pagination={{
            className: "custom-pagination",
            defaultCurrent: 1,
            pageSize: 10,
            total: totalCount,
            onChange: getPagination,
            itemRender: customPagination,
          }}
        />
      </div>
    </div>
  );
}
