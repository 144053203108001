import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Select,
  Modal,
  TreeSelect,
  Switch,
  Popover,
  Checkbox,
  Table,
  Spin,
} from "antd";
import dayjs from "dayjs";
import { languagesArray } from "../../../store/language/languageSlice";
import FormZ from "../../../components/FormZ";
import TableSortArrows from "../../../components/tableSortArrows";

import {
  sendNotify,
  getOptions,
  focusOn,
  fetchApi,
  convertQueryParams,
  getQuery,
} from "../../../helper";
import { getOrganizationStructure } from "../../../store/organizationStructure/organizationStructureSlice";
import { getIndividualDetails } from "../../../store/individualDetails/individualDetailsSlice";
import { getCopEntityDetails } from "../../../store/copEntityDetails/copEntityDetailsSlice";
import { getRelatedEntityDetails } from "../../../store/relatedEntityDetails/relatedEntityDetailsSlice";
import Currency from "../../../helper/currency.json";

import countries from "../../../helper/countries.json";
import MasterFileCreation from "../../../helper/translations/MasterFileCreation.json";
const { SHOW_PARENT } = TreeSelect;

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}
export default function RelatedEntity(props) {
  const senderFormRef = useRef(null);
  const receiverFormRef = useRef(null);
  const allocationFormRef = useRef(null);
  const { language } = useSelector((state) => state.language);
  const [relatedEntityShow, setRelatedEntityShow] = useState(false);
  let { isLogged, userDetails } = useSelector((state) => state.auth);
  const [objData, setObjData] = useState({});
  const [shareTableData, setShareTableData] = useState([]);

  const individualDetails = useSelector((state) => state.individualDetails);
  const relatedEntityDetails = useSelector(
    (state) => state.relatedEntityDetails
  );
  const copEntityDetails = useSelector((state) => state.copEntityDetails);

  const organizationStructure = useSelector(
    (state) => state.organizationStructure
  );
  let query = getQuery();
  const dispatch = useDispatch();
  const [checkedList, setCheckedList] = useState({
    directors: false,
    shareholders: false,
    ubo: false,
    nominee: false,
    reserve: false,
  });
  const [historyShow, setHistoryShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);

  const [countriesOption, setCountriesOption] = useState([]);
  const [value, setValue] = useState([]);
  const [sanction, setSanction] = useState("Y");

  const [directorChecked, setDirectorChecked] = useState(false);
  const [reserveChecked, setReserveChecked] = useState(false);
  const [shareholderChecked, setShareholderChecked] = useState(false);
  const [nomineeChecked, setNomineeChecked] = useState(false);
  const [uboChecked, setUboChecked] = useState(false);
  const [openRelatedEntity, setOpenRelatedEntity] = useState(false);
  const [currency, setCurrency] = useState([]);

  const [selectedId, setSelectedId] = useState("");
  const [queryParams, setQueryParams] = useState({
    _start: 0,
    _limit: 5,
    all: true,
  });
  const [selectedIndex, setSelectedIndex] = useState(0);

  const [formData, setFormData] = useState({});
  const [relatedEntityArray, setRelatedEntityArray] = useState([]);
  const [relatedEntityName, setRelatedEntityName] = useState([]);
  const [relatedEntityData, setRelatedEntityData] = useState([]);
  const [entityArray, setEntityArray] = useState([]);
  const [senderFormData, setSenderFormData] = useState({});
  const [receiveFormData, setReceiveFormData] = useState({});
  const [receiverOptions, setReceiverOptions] = useState({});
  const [allocationFormData, setAllocationFormData] = useState({});
  const [receiverArray, setReceiverArray] = useState([]);
  const [schema1, setSchema1] = useState({});
  const [schema2, setSchema2] = useState({});
  const [schema3, setSchema3] = useState({});
  const [shareForm, setShareForm] = useState({
    show: false,
    type: "",
  });
  const [selctedLanguage, setSelctedLanguage] = useState("");
  const [findId, setFindId] = useState(null);
  useEffect(() => {
    let index = languagesArray.find((tc) => tc.value == language);
    setSelctedLanguage(index.value);
  }, [language]);

  let m =
    MasterFileCreation.entity[selctedLanguage] || MasterFileCreation.entity.en;

  let { data, isLoading, entityData, id } = props;

  const [forms, setForms] = useState({
    individual: [1],
    directors: [1],
    shareholders: [1],
    ubo: [1],
    financialRecord: [],
    corporateEntity: [1],
  });

  const convertToOptions = () => {
    let options = [
      {
        title: "Worldwide",
        value: "0",
        key: "0",
        children: [],
      },
    ];

    Object.keys(countries).forEach(function (key, index) {
      let continents = {
        title: key,
        value: `0-${index}`,
        key: `0-${index}`,
        children: [],
      };
      countries[key].forEach((country, i) => {
        let cat = {
          title: country?.name,
          value: `0-${index}-${i}`,
          key: `0-${index}-${i}`,
        };
        continents.children.push(cat);
      });

      options[0]?.children.push(continents);
    });

    // console.log(options);
    setCountriesOption(options);
  };

  const onChange = (newValue) => {
    console.log("onChange ", newValue);
    setValue(newValue);
  };
  const relatedEntityDeleteOnClick = async (id, index) => {
    setSelectedId(id);
    const details = relatedEntityDetails.data.rows.find((e) => e._id === id);
    let payload = {};

    payload.method = "DELETE";
    payload.url = `/relatedEntityDetails?id=${id}`;

    fetchApi(payload)
      .then((res) => {
        const params = { ...queryParams, enId: details.enId };
        dispatch(getRelatedEntityDetails(params));
        sendNotify("success", res?.message);
      })
      .catch((error) => {
        sendNotify("error", error?.message);
      });
  };

  const tProps = {
    treeData: countriesOption,
    value,
    onChange,
    className: "custom-select w-100 ptb-1",
    treeCheckable: true,
    showSearch: false,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: "Select geography of business",
    allowClear: true,
  };

  const formRef = useRef();
  const adsFormRef = useRef();
  const riskFormRef = useRef();

  const handleChange = (value) => {
    setSanction(value);
  };

  let adsFormSchema = [
    {
      name: "line_1",
      value: "",
      type: "text",
      label: "Address Line 01",
      placeholder: "Enter address",
      disabled: true,
    },
    {
      name: "line_2",
      value: "",
      type: "text",
      label: "Address Line 02",
      placeholder: "Enter address",
      disabled: true,
    },
    {
      name: "line_3",
      value: "",
      type: "text",
      label: "Address Line 03",
      placeholder: "Enter address",
      disabled: true,
    },
    {
      name: "country",
      value: "",
      type: "select",
      label: "Country",
      placeholder: "Select country",
      options: getOptions(data),
      loading: isLoading,
      showSearch: true,
      disabled: true,
      labelClass: "mb-1",
      customClass: "col-md-3 flex-column align-items-start",
    },
    {
      name: "zipCode",
      value: "",
      type: "number",
      label: "Post/Zip code",
      labelClass: "mb-1",
      disabled: true,
      placeholder: "Enter post/zip code",
      customClass: "col-md-3 flex-column align-items-start",
    },
  ];

  let riskFormSchema = [
    {
      name: "riskRating",
      value: "",
      type: "select",
      label: "Risk Rating",
      placeholder: "Select risk level",
      disabled: true,
      options: [
        {
          value: "low",
          label: "Low",
        },
        {
          value: "medium",
          label: "Medium",
        },
        {
          value: "high",
          label: "High",
        },
      ],
    },
    {
      name: "listed",
      value: "",
      type: "select",
      label: "Listed or Regulated",
      placeholder: "Select",
      disabled: true,
      options: [
        {
          value: "Y",
          label: "Yes",
        },
        {
          value: "N",
          label: "No",
        },
      ],
      customClass: "w-150px",
    },
    {
      customClass: sanction == "Y" ? "col-md-3" : "col-lg-1",
      label: "Sanction",
      customElement: (
        <div className="d-flex gap-2">
          <Select
            className={`custom-select ${sanction == "Y" ? "w-50" : "w-100"}`}
            placeholder="Select"
            value={sanction}
            disabled={true}
            onChange={handleChange}
            options={[
              {
                value: "Y",
                label: "Yes",
              },
              {
                value: "N",
                label: "No",
              },
            ]}
          />
          {sanction == "Y" && (
            <input
              type="text"
              disabled={true}
              className="w-100"
              placeholder="Enter sanction"
            />
          )}
        </div>
      ),
    },
    // {
    //   customElement: fileCreation ? (
    //     <div></div>
    //   ) : (
    //     <button className="primary-btn mb-15">Confirm</button>
    //   ),
    // },
  ];

  const [entityFormSchema, setEntityFormSchema] = useState([]);

  useEffect(() => {
    setEntityFormSchema([
      {
        name: "code",
        value: "",
        type: "text",
        label: m.code,
        placeholder: m.EnterEntityCode,
        disabled: true,
      },
      {
        name: "name",
        value: "",
        type: "text",
        label: m.name,
        placeholder: m.EnterEntityName,
        disabled: true,
      },
      {
        name: "type",
        value: "",
        type: "select",
        label: m.type,
        placeholder: m.SelectEntityType,
        options: [
          { value: "BVIBC", label: "BVIBC" },
          { value: "trust", label: "Trust" },
          { value: "foundation", label: "Foundation" },
          { value: "general-partnership", label: "General Partnership" },
          { value: "limited-partnership", label: "Limited Partnership" },
        ],
        disabled: true,
      },
      {
        name: "status",
        value: "",
        type: "select",
        label: m.status,
        placeholder: m.SelectEntityStatus,
        options: [
          { value: "active", label: "Active" },
          { value: "inactive", label: "Inactive" },
          { value: "closing", label: "Closing" },
          { value: "closed", label: "Closed" },
          { value: "dissolved", label: "Dissolved" },
          { value: "transferringOut", label: "Transferring Out" },
          { value: "prospect", label: "Prospect" },
          { value: "transferringIn", label: "Transferring In" },
          { value: "liquidated", label: "Liquidated" },
          { value: "struckOff", label: "Struck Off" },
        ],
        disabled: true,
      },
      {
        name: "clientType",
        value: "",
        type: "select",
        label: m.clientType,
        placeholder: m.EnterClientType,
        options: [
          { value: "introducer", label: "Introducer Client" },
          { value: "endUser", label: "End User Client" },
        ],
        disabled: true,
      },
      {
        name: "incorporationDate",
        value: "",
        type: "date",
        upcoming: true,
        label: m.incorporationDate,
        placeholder: m.formet,
        disabled: true,
      },
      {
        name: "incorporationNumber",
        value: "",
        type: "text",
        label: m.incorporationNumber,
        placeholder: m.EnterIncorporationNumber,
        disabled: true,
      },
      {
        name: "countryOfIncorporation",
        value: "BVI",
        type: "select",
        label: m.countryOfIncorporation,
        placeholder: m.SelectCountryOfIncorporation,
        options: getOptions(data),
        loading: isLoading,
        disabled: true,
      },
      {
        name: "feeType",
        value: "",
        type: "select",
        label: m.feeType,
        placeholder: m.SelectFeeType,
        options: [
          { value: "Pre-Paid", label: "Pre-paid" },
          { value: "Post-Paid", label: "Post-paid" },
        ],
        disabled: true,
      },
      {
        name: "subCat",
        value: null,
        type: "select",
        label: m.subCat,
        placeholder: m.SelectFeeSubCategory,
        inputClass: "ptb-1",
        allowClear: true,
        disabled: true,
      },
      {
        name: "relationshipManager",
        value: "",
        type: "text",
        label: m.relationshipManager,
        placeholder: m.EnterRelationshipManagerName,
        disabled: true,
      },
      {
        name: "administrator",
        value: "",
        type: "text",
        label: m.administrator,
        placeholder: m.EnterAdministratorName,
        disabled: true,
      },
      {
        name: "adminManager",
        value: "",
        type: "text",
        label: m.adminManager,
        placeholder: m.EnterAdminManagerName,
        disabled: true,
      },
      {
        name: "email",
        value: "",
        type: "email",
        label: m.email,
        placeholder: m.EnterEmailAddress,
        disabled: true,
      },
      {
        name: "contactNo",
        value: "",
        type: "number",
        label: m.contactNo,
        placeholder: m.EnterContactNumber,
        customClass: "hide-arrows",
        disabled: true,
      },
      {
        name: "authorisedShares",
        value: "",
        type: "number",
        label: m.authorisedShares,
        placeholder: m.EnterAuthorisedShares,
        customClass: "hide-arrows",
        disabled: true,
      },
      {
        name: "YearOfEndDate",
        value: "",
        type: "date",
        label: m.YearOfEndDate,
        placeholder: m.formet,
        disabled: true,
      },
      {
        name: "createdAt",
        value: "",
        type: "date",
        label: m.createdAt,
        placeholder: m.SelectDate,
        disabled: true,
      },
    ]);
  }, [isLoading]);

  useEffect(() => {
    let params = { ...queryParams, enId: id ? id : query.id };
    dispatch(getIndividualDetails(params));
    dispatch(getRelatedEntityDetails(params));
    dispatch(getCopEntityDetails(params));
    dispatch(getOrganizationStructure(params));
    handleRefresh();
    convertToOptions();
  }, [id]);
  useEffect(() => {
    if (id || query.id) {
      neworganizationStructure();
    }
  }, [
    individualDetails,
    organizationStructure,
    copEntityDetails,
    relatedEntityDetails,
  ]);
  const neworganizationStructure = async () => {
    if (organizationStructure?.data?.rows?.length) {
    } else {
      let obj = {
        master: true,
        enId: id ? id : query.id,
      };
      let payload = {
        method: "POST",
        url: `/organizationStructure`,
        data: obj,
      };

      fetchApi(payload)
        .then((res) => {
          if (res.status == 201 && res?.data?.length) {
            dispatch(getOrganizationStructure(queryParams));
          }
        })
        .catch((error) => {});
    }
  };
  let directorFormSchema = [
    {
      name: "dateAppointment",
      value: "",
      type: "date",
      label: "Date of Appointment",
      placeholder: "Enter Date (YYYY-MM-DD)",
      format: "YYYY-MM-DD",
    },
    {
      name: "dateResignation",
      value: "",
      type: "date",
      label: "Date of Resignation",
      placeholder: "Enter Date (YYYY-MM-DD)",
      format: "YYYY-MM-DD",
    },
  ];

  let shareholderFormSchema = [
    {
      name: "sharesHeld",
      value: "",
      type: "number",
      label: "No. of Shares Held",
      placeholder: "Enter no. of shares held",
    },
    {
      name: "shareEntry",
      value: null,
      type: "select",
      options: [
        {
          value: "Share Transfer",
          label: "Share Transfer",
        },
        {
          value: "New Allocation",
          label: "New Allocation",
        },
      ],
      label: "Add share entry",
      placeholder: "Select",
    },
  ];

  let uboFormSchema = [
    {
      name: "totalSharesHeld",
      value: "",
      type: "number",
      label: "Total Shares Held",
      placeholder: "Enter total shares held",
    },
    {
      name: "ownershipPercentage",
      value: "",
      type: "text",
      label: "Ownership Percentage",
      placeholder: "Enter ownership percentage",
    },
  ];

  let pocFormSchema = [
    {
      name: "contactDetails",
      value: "9023423423",
      type: "number",
      label: "Contact Details",
      placeholder: "Enter contact details",
    },
    {
      name: "email",
      value: "info@gmail.com",
      type: "email",
      label: "Email Address",
      placeholder: "Enter email address",
    },
  ];

  let nomineeShareholder = [
    {
      name: "nomineeAgreementDate",
      value: dayjs("16-08-2023", "DD/MM/YYYY"),
      type: "date",
      label: "Nominee Agreement Date",
      placeholder: "Enter nominee agreement Date",
    },
    {
      name: "nomineeShareEntry",
      value: null,
      type: "select",
      options: [
        {
          value: "Share Transfer",
          label: "Share Transfer",
        },
        {
          value: "New Allocation",
          label: "New Allocation",
        },
      ],
      label: "Add share entry",
      placeholder: "Select",
    },
  ];

  const columns = [
    {
      title: "Share Certificate Number",
      dataIndex: "shareNumber",
      sorter: (a, b) => a.shareNumber - b.shareNumber,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Number of Shares",
      dataIndex: "noShares",
      sorter: (a, b) => a.noShares - b.noShares,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Share Type",
      dataIndex: "shareType",
      sorter: (a, b) => a.shareType - b.shareType,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: (
        <div className="d-flex align-items-center">
          Issued Date&nbsp;<i className="fal fa-question-circle"></i>
        </div>
      ),
      dataIndex: "issuedDate",
      sorter: (a, b) => a.issuedDate - b.issuedDate,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Total No. of Shares held",
      dataIndex: "sharesHeld",
      sorter: (a, b) => a.sharesHeld - b.sharesHeld,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: (
        <div className="d-flex align-items-center">
          Transfer Date&nbsp;<i className="fal fa-question-circle"></i>
        </div>
      ),
      dataIndex: "transferDate",
      sorter: (a, b) => a.transferDate - b.transferDate,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Transfer Amount",
      dataIndex: "transferAmount",
      sorter: (a, b) => a.transferAmount - b.transferAmount,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Par value",
      dataIndex: "parValue",
      sorter: (a, b) => a.parValue - b.parValue,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Date Ceased to be a Member",
      dataIndex: "dateCeased",
      sorter: (a, b) => a.dateCeased - b.dateCeased,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Comments",
      dataIndex: "comments",
      sorter: (a, b) => a.comments - b.comments,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
  ];

  // userDetails.role == "admin" &&
  columns.push({
    title: "",
    render: (_, { _id }, index) => (
      <div className="d-flex gap-2 align-items-center justify-content-end">
        <button className="action-btn delete-btn">
          <i className="far fa-trash-alt"></i>
        </button>
        <button className="action-btn">
          <i className="far fa-pencil"></i>
        </button>
      </div>
    ),
  });

  const tableData = [
    {
      key: "1",
      id: "1",
      shareNumber: 55,
      noShares: 100,
      shareType: "Type 1",
      issuedDate: "11-12-2023",
      sharesHeld: "02",
      transferDate: "12-12-2023",
      transferAmount: 500,
      parValue: 100,
      dateCeased: "Sample",
      comments: "Sample",
    },
    {
      key: "2",
      id: "2",
      shareNumber: 56,
      noShares: 500,
      shareType: "Type 2",
      issuedDate: "13-12-2023",
      sharesHeld: "05",
      transferDate: "14-12-2023",
      transferAmount: 800,
      parValue: 100,
      dateCeased: "Sample",
      comments: "Sample",
    },
    {
      key: "3",
      id: "3",
      shareNumber: 57,
      noShares: 250,
      shareType: "Type 1",
      issuedDate: "15-12-2023",
      sharesHeld: "05",
      transferDate: "17-12-2023",
      transferAmount: 400,
      parValue: 100,
      dateCeased: "Sample",
      comments: "Sample",
    },
    {
      key: "4",
      id: "4",
      shareNumber: 58,
      noShares: 120,
      shareType: "Type 2",
      issuedDate: "18-12-2023",
      sharesHeld: "05",
      transferDate: "19-12-2023",
      transferAmount: 1500,
      parValue: 100,
      dateCeased: "Sample",
      comments: "Sample",
    },
  ];
  const senderOnChange = async (value, fields) => {
    let receiveData = { ...receiveFormData };
    receiveData.createdAt = new Date();

    if (fields == "numberOfShares") {
      let noShares = value.numberOfShares ?? 0;

      if (Number(noShares) <= Number(formData.sharesHeld)) {
        receiveData.numberOfShares = Number(value.numberOfShares);
        if (noShares) {
          value.totalShares =
            Number(formData.sharesHeld) - Number(noShares) == 0
              ? 0
              : Number(formData.sharesHeld) - Number(noShares);

          //  percentage;

          let percentage =
            (Number(noShares) / Number(entityData?.authorisedShares)) * 100;
          value.percentage = `${Math.round(Number(percentage) * 100) / 100} %`;
        }
      } else {
        // 10-01-2025
        // sendNotify(
        //   "error",
        //   "Requested shares exceed the Individual's allocated total."
        // );
      }
    }
    // if (fields == "shareCapital") {
    //   receiveData.shareCapital = value.shareCapital;
    // }
    receiveData.shareCapital = entityData?.authorisedShares;

    if (fields == "totalShares") {
      receiveData.totalShares = value.totalShares;
    }
    if (fields == "effectiveDate") {
      receiveData.effectiveDate = value.effectiveDate;
    }
    if (fields == "to") {
      receiveData.to = value.to;
    }
    if (fields == "from") {
      receiveData.from = value.from;
    }

    setSenderFormData(value);
    setReceiveFormData(receiveData);
  };
  const receiverOnChange = async (value, fields) => {
    if (!value || !fields) {
      console.warn("Value or fields is missing", { value, fields });
      return;
    }

    if (fields === "masterId" || fields === "name") {
      let find = null;

      console.log("wedryfguhioj[p");

      if (fields === "name") {
        find = receiverArray.find((e) => e.name === value?.name);
        if (!find) {
          find = receiverArray.find((e) => e.firstName === value?.name);
        }
      } else {
        find = receiverArray.find((e) => e._id === value.masterId);
      }

      if (find) {
        // totalShares;

        let findedDetails = [];
        if (find.masterType == "individual") {
          findedDetails = individualDetails?.data?.rows?.filter(
            (f) => f.invId == find._id
          );
        } else if (find.masterType == "cop-entity") {
          findedDetails = copEntityDetails?.data?.rows?.filter(
            (f) => f.invId == find._id
          );
        } else if (find.masterType == "entity") {
          findedDetails = relatedEntityDetails?.data?.rows?.filter(
            (f) => f.invId == find._id
          );
        }
        let noOfShares = 0;
        await asyncForEach(findedDetails, async (oneDoc) => {
          if (oneDoc.noOfShares) {
            noOfShares += Number(oneDoc.noOfShares);
          }
        });
        let totalShares = noOfShares + value.numberOfShares;

        const data = {
          masterId: find._id,
          name: find.name ?? find.firstName,
          masterType: find.masterType,
          totalShares: noOfShares + value.numberOfShares,
        };
        if (totalShares) {
          let percentage =
            (Number(totalShares) / Number(entityData.authorisedShares)) * 100;
          data.percentage = `${Math.round(Number(percentage) * 100) / 100} %`;
        }
        console.log(data, "rtyuio");

        setReceiveFormData((prev) => ({
          ...prev,
          ...data,
        }));
      } else {
        console.warn("No match found in receiverArray", { value, fields });
      }
    }
    if (fields == "numberOfShares") {
      let noShares = value.numberOfShares ?? 0;

      if (Number(noShares) <= Number(senderFormData.sharesHeld)) {
        if (noShares) {
          value.totalShares =
            Number(senderFormData.sharesHeld) - Number(noShares) == 0
              ? 0
              : Number(senderFormData.sharesHeld) - Number(noShares);

          //  percentage;

          let percentage =
            (Number(noShares) / Number(senderFormData.sharesHeld)) * 100;
          value.percentage = `${Math.round(Number(percentage) * 100) / 100} %`;
        }
      } else {
        // 10-01-2025
        // sendNotify(
        //   "error",
        //   "Requested shares exceed the Individual's allocated total."
        // );
      }
    }
  };
  const allocationOnChange = async (value, fields) => {
    if (!value || !fields) {
      console.warn("Value or fields is missing", { value, fields });
      return;
    }

    if (fields === "masterId" || fields === "name") {
      let find = null;

      if (fields === "name") {
        find = receiverArray.find((e) => e.name === value?.name);
        if (!find) {
          find = receiverArray.find((e) => e.firstName === value?.name);
        }
      } else {
        find = receiverArray.find((e) => e._id === value.masterId);
      }

      if (find) {
        console.log(find, "Matched receiver");
        const data = {
          masterId: find._id,
          name: find.name ?? find.firstName,
          masterType: find.masterType,
        };
        setAllocationFormData((prev) => ({
          ...prev,
          ...data,
        }));
      } else {
        console.warn("No match found in receiverArray", { value, fields });
      }
    }
  };
  const addNewCol = (key, { focus }) => {
    let obj = { ...forms };
    let arr = [...obj[key]];
    arr.push(arr.length + 1);
    obj[key] = arr;
    setForms(obj);
    // console.log(focus);
    if (focus) {
      focusOn(key + (arr.length - 1));
    }
  };

  const removeNewCol = (key, index) => {
    let obj = { ...forms };
    let arr = [...obj[key]];
    arr.splice(index, 1);
    obj[key] = arr;
    setForms(obj);
  };

  function removeArrItem(values, arr) {
    for (let i = 0; i < arr.length; i++) {
      for (let index = 0; index < values.length; index++) {
        if (arr[i]?.name === values[index]?.name) {
          arr.splice(i, 1);
        }
      }
    }
  }

  // function updateChecked(key, checked) {
  //   let obj = { ...checkedList };
  //   if (key == "nominee" && checked) {
  //     obj["shareholders"] = false;
  //   }
  //   if (key == "shareholders" && checked) {
  //     obj["nominee"] = false;
  //   }
  //   obj[key] = checked;

  //   setCheckedList(obj);
  // }
  function updateChecked(key, checked) {
    setCheckedList((prevCheckedList) => {
      const updatedCheckedList = { ...prevCheckedList };

      if (key === "nominee" && checked) {
        updatedCheckedList["shareholders"] = false;
      }
      if (key === "shareholders" && checked) {
        updatedCheckedList["nominee"] = false;
      }

      updatedCheckedList[key] = checked;

      console.log(updatedCheckedList, "Updated CheckedList");
      return updatedCheckedList;
    });
  }
  const addNewChange = (values, checkStatus, name) => {
    updateChecked("ubo", checkStatus);

    let arr = [...entityFormSchema];
    if (checkStatus) {
      arr.push(...values);
    } else {
      removeArrItem(values, arr);
    }
    setEntityFormSchema(arr);
  };
  const clearForm = () => {
    setSchema1({});
    setSchema2({});
    setObjData({});
    setSchema3({});
    setUboChecked(false);
    setDirectorChecked(false);
    setReserveChecked(false);
    setShareholderChecked(false);
    setNomineeChecked(false);
    setOpenRelatedEntity(false);
    clearFormRef();
    setShareForm({
      show: false,
      type: "",
    });
    setCheckedList({
      directors: false,
      shareholders: false,
      ubo: false,
      nominee: false,
      reserve: false,
    });
    clearArrItem();
  };
  const addNewRow = (values, checkStatus, name) => {
    updateChecked(name, checkStatus);
    let arr = [...entityFormSchema];

    if (checkStatus) {
      removeArrItem(values, arr);
    }

    if (name == "directors") {
      if (checkStatus) {
        arr.push(...values);
        setDirectorChecked(true);
        setReserveChecked(false);
      } else {
        removeArrItem(values, arr);
        setDirectorChecked(false);
      }
    }
    if (name == "reserve") {
      if (checkStatus) {
        arr.push(...values);
        setReserveChecked(true);
        setDirectorChecked(false);
      } else {
        removeArrItem(values, arr);
        setReserveChecked(false);
      }
    }
    setEntityFormSchema(arr);
  };

  const addNewCustom = (values, name, checkStatus) => {
    let arr = [...entityFormSchema];

    updateChecked(name, checkStatus);

    let corporateData = { ...schema1 };

    if (name == "shareholders") {
      if (checkStatus) {
        arr.push(...values);
        setShareholderChecked(true);
        setNomineeChecked(false);
        removeArrItem(nomineeShareholder, arr);
      } else {
        setShareForm({
          show: false,
          type: "",
        });
        setFormData({
          ...corporateData,
          shareEntry: null,
          nomineeShareEntry: null,
        });
        removeArrItem(values, arr);
        setShareholderChecked(false);
      }
    }

    if (name == "nominee") {
      if (checkStatus) {
        arr.push(...values);
        setNomineeChecked(true);
        setShareholderChecked(false);
        removeArrItem(shareholderFormSchema, arr);
      } else {
        setShareForm({
          show: false,
          type: "",
        });
        setFormData({
          ...corporateData,
          shareEntry: null,
          nomineeShareEntry: null,
        });
        removeArrItem(values, arr);
        setNomineeChecked(false);
      }
    }
    setEntityFormSchema(arr);
  };

  const handleRefresh = async () => {
    const fetchData = async (url) => {
      const payload = { method: "GET", url };
      return fetchApi(payload).then((res) => res?.data?.rows);
    };
    const params = {
      isAll: true,
      masterType: "entity",
      options: true,
    };
    const query = convertQueryParams(params);
    const payload = {
      method: "GET",
      url: `/client?${query}`,
    };
    const paramsName = convertQueryParams({
      isAll: true,
      masterType: "entity",
      exportAll: true,
    });
    const queryParam = convertQueryParams({
      isAll: true,
      masterType: "entity",
      exportAll: true,
    });
    let entityPayload = {
      method: "get",
      url: `/client?${queryParam}`,
    };
    let currency = Currency.map((a) => {
      let obj = {
        value: `${a.symbol}`,
        label: `${a.name} - ${a.code} - ${a.symbol}`,
      };
      return obj;
    });
    setCurrency(currency);
    try {
      const clientData = await fetchApi(payload).then(
        (res) => res?.data?.rows || []
      );
      const entityData = await fetchApi(entityPayload).then(
        (res) => res?.data?.rows || []
      );
      setEntityArray(entityData);
      setRelatedEntityData(clientData);
      setRelatedEntityArray(
        clientData.map((d) => ({ value: d?.code, label: d?.code }))
      );
      const copEntityName = await fetchData(`/client?${paramsName}`);

      let name = copEntityName.map((d) => ({
        value: d?._id,
        label: d?.name,
      }));
      setRelatedEntityName(name);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleNameChange = (name) => {
    let val,
      val2,
      val3 = {};
    const data = relatedEntityData.find((ent) => ent.name === name);
    console.log(data, "dvzbnfgm");
    if (data) {
      val = {
        _id: data?._id,
        code: data?.code,
        corpInfoNo: data?.corpInfoNo,
        name: data?.name,
        type: data?.type,
        status: data?.status,
        clientType: data?.clientType,
        incorporationDate: data?.incorporationDate,
        incorporationNumber: data?.incorporationNumber,
        incorporationCountry: data?.incorporationCountry,
        relatedEntityCode: data?.relatedEntityCode,
        createdAt: data?.createdAt,
        adminManager: data?.adminManager,
        relationshipManager: data?.relationshipManager,
        administrator: data?.administrator,
        email: data?.email,
        contactNo: data?.contactNo,
        authorisedShares: data?.authorisedShares,
        YearOfEndDate: data?.YearOfEndDate,
        feeType: data?.feeType,
        subCat: data?.subCat,
      };
      val2 = {
        residentialAddress: data?.residentialAddress,
        serviceAddress: data?.serviceAddress,
        line_1: data?.details?.address?.line_1,
        line_2: data?.details?.address?.line_2,
        line_3: data?.details?.address?.line_3,
        zipCode: data?.details?.address?.zipCode,
        country: data?.details?.address?.country,
      };
      val3 = {
        riskRating: data?.details?.riskRating,
        pep: data?.details?.pep,
        sanction: data?.details?.sanction,
      };
      setSchema1(val);
      setSchema2(val2);
      setSchema3(val3);
    }
  };
  const clearFormRef = () => {
    try {
      if (receiverFormRef.current) receiverFormRef?.current?.clearForm();
      if (allocationFormRef.current) allocationFormRef?.current?.clearForm();
      if (senderFormRef.current) senderFormRef?.current?.clearForm();
    } catch (err) {
      console.log(err, "Error clearing form references");
      return err;
    }
  };
  const senderForm = [
    {
      name: "masterId",
      value: "",
      type: "text",
      label: "Master file Code",
      placeholder: "Enter master file code",
      disabled: true,
    },
    {
      name: "name",
      value: "",
      type: "text",
      label: "Master file Name",
      placeholder: "Enter master file Name",
      disabled: true,
    },
    {
      name: "shareCapital",
      value: "",
      type: "text",
      label: "Authorised Share Capital",
      placeholder: "Enter authorised share Capital",
      disabled: true,
    },
    {
      name: "numberOfShares",
      value: "",
      type: "number",
      label: "Number of shares transferred",
      placeholder: "Enter number of shares transferred",
    },
    {
      name: "from",
      value: "",
      type: "text",
      label: "From",
      //  placeholder: "Enter Date (YYYY-MM-DD)",
      //  format: "YYYY-MM-DD",
    },
    {
      name: "to",
      value: "",
      type: "text",
      label: "To",
      // placeholder: "Enter Date (YYYY-MM-DD)",
      // format: "YYYY-MM-DD",
    },
    {
      name: "shareType",
      type: "select",
      options: [
        {
          value: "Ordinary Shares",
          label: "Ordinary Shares",
        },
        {
          value: "Preference Shares",
          label: "Preference Shares",
        },
        {
          value: "Non-Voting Shares",
          label: "Non-Voting Shares",
        },
        {
          value: "Shares Without Par Value",
          label: "Shares Without Par Value",
        },
      ],
      label: "Share type",
      placeholder: "Select",
    },
    {
      name: "parValue",
      value: "",
      type: "number",
      label: "Par Value",
      placeholder: "Enter par value",
    },
    {
      name: "currency",
      value: "",
      type: "select",
      showSearch: true,
      options: currency,
      label: "Currency",
      placeholder: "Enter currency",
    },

    {
      name: "effectiveDate",
      value: "",
      type: "date",
      label: "Effective date",
      placeholder: "Enter Date (YYYY-MM-DD)",
      format: "YYYY-MM-DD",
      upcoming: true,
    },
    {
      name: "ceasedDate",
      value: "",
      type: "date",
      label: "Ceased date",
      placeholder: "Enter Date (YYYY-MM-DD)",
      format: "YYYY-MM-DD",
    },
    {
      name: "totalShares",
      value: "",
      type: "number",
      label: "Total Shares held",
      placeholder: "Enter total shares held",
    },
    {
      name: "percentage",
      value: "",
      type: "text",
      label: "Ownership Percentage",
      placeholder: "Enter ownership percentage",
      disabled: true,
    },
    {
      name: "certificateNumber",
      value: "",
      type: "text",
      label: "Certificate Number",
      placeholder: "Enter certificate number",
    },
    {
      name: "createdAt",
      value: "",
      type: "date",
      label: "Date of entry",
      placeholder: "Enter Date (YYYY-DD-MM)",
      format: "YYYY-DD-MM",
      disabled: true,
    },
    {
      name: "comment",
      value: "",
      type: "textarea",
      label: "Comment",
      placeholder: "Enter comment/details",
      customClass: "col-md-10",
    },
  ];

  const receiverForm = [
    {
      name: "masterId",
      value: "",
      type: "select",
      label: "Master file Code",
      placeholder: "Enter master file code",
    },
    {
      name: "name",
      value: "",
      type: "select",
      label: "Master file Name",
      placeholder: "Enter master file Name",
    },
    {
      name: "shareCapital",
      value: "",
      type: "text",
      label: "Authorised Share Capital",
      placeholder: "Enter authorised share Capital",
      disabled: true,
    },
    {
      name: "numberOfShares",
      value: "",
      type: "number",
      label: "Number of shares received",
      placeholder: "Enter Number of shares received",
      disabled: true,
    },
    {
      name: "from",
      value: "",
      type: "text",
      label: "From",
      //  placeholder: "Enter Date (YYYY-MM-DD)",
      // format: "YYYY-MM-DD",
      disabled: true,
    },
    {
      name: "to",
      value: "",
      type: "text",
      label: "To",
      // placeholder: "Enter Date (YYYY-MM-DD)",
      // format: "YYYY-MM-DD",
      disabled: true,
    },
    {
      name: "effectiveDate",
      value: "",
      type: "date",
      label: "Effective date",
      placeholder: "Enter Date (YYYY-MM-DD)",
      format: "YYYY-MM-DD",
      upcoming: true,
    },
    {
      name: "totalShares",
      value: "",
      type: "number",
      label: "Total Shares held",
      placeholder: "Enter total shares held",
      upcoming: true,
      disabled: true,
    },
    {
      name: "percentage",
      value: "",
      type: "text",
      label: "Ownership Percentage",
      placeholder: "Enter ownership percentage",
      disabled: true,
    },
    {
      name: "certificateNumber",
      value: "",
      type: "text",
      label: "Certificate Number",
      placeholder: "Enter certificate number",
    },
    {
      name: "createdAt",
      value: "",
      type: "date",
      label: "Date of entry",
      placeholder: "Enter Date (YYYY-MM-DD)",
      format: "YYYY-MM-DD",
      disabled: true,
    },
  ];

  const allocationForm = [
    {
      name: "masterId",
      value: "",
      type: "select",
      label: "Master file Code",
      placeholder: "Enter master file code",
      disabled: true,
    },
    {
      name: "name",
      value: "",
      type: "select",
      label: "Master file Name",
      placeholder: "Enter master file Name",
      disabled: true,
    },
    {
      name: "shareCapital",
      value: "",
      type: "text",
      label: "Authorised Share Capital",
      placeholder: "Enter authorised share Capital",
      disabled: true,
    },
    {
      name: "numberOfShares",
      value: "",
      type: "number",
      label: "Number of shares allocated",
      placeholder: "Enter number of shares allocated",
    },
    {
      name: "from",
      value: "",
      type: "text",
      label: "From",
      placeholder: "Enter number of From",
    },
    {
      name: "to",
      value: "",
      type: "text",
      label: "To",
      placeholder: "Enter number of To",
    },
    {
      name: "shareType",
      type: "select",
      options: [
        {
          value: "Ordinary Shares",
          label: "Ordinary Shares",
        },
        {
          value: "Preference Shares",
          label: "Preference Shares",
        },
        {
          value: "Non-Voting Shares",
          label: "Non-Voting Shares",
        },
        {
          value: "Shares Without Par Value",
          label: "Shares Without Par Value",
        },
      ],
      label: "Share type",
      placeholder: "Select",
    },
    {
      name: "parValue",
      value: "",
      type: "number",
      label: "Par Value",
      placeholder: "Enter par value",
    },
    {
      name: "currency",
      value: "",
      type: "select",
      showSearch: true,
      options: currency,
      label: "Currency",
      placeholder: "Enter currency",
    },

    {
      name: "effectiveDate",
      value: "",
      type: "date",
      label: "Effective date",
      placeholder: "Enter Date (YYYY-MM-DD)",
      format: "YYYY-MM-DD",
      upcoming: true,
    },
    {
      name: "ceasedDate",
      value: "",
      type: "date",
      label: "Ceased date",
      placeholder: "Enter Date (YYYY-MM-DD)",
      format: "YYYY-MM-DD",
    },

    {
      name: "totalShares",
      value: "",
      type: "number",
      label: "Total Shares held",
      placeholder: "Enter total shares held",
      disabled: true,
    },
    {
      name: "percentage",
      value: "",
      type: "text",
      label: "Ownership Percentage",
      placeholder: "Enter ownership percentage",
      disabled: true,
    },
    {
      name: "certificateNumber",
      value: "",
      type: "text",
      label: "Certificate Number",
      placeholder: "Enter certificate number",
    },

    {
      name: "createdAt",
      value: "",
      type: "date",
      label: "Date of entry",
      placeholder: "Enter Date (YYYY-MM-DD)",
      format: "YYYY-MM-DD",
    },
    {
      name: "comment",
      value: "",
      type: "textarea",
      label: "Comment",
      placeholder: "Enter comment/details",
      customClass: "col-md-10",
    },
  ];
  const getReceiverList = async () => {
    const fetchData = async (url) => {
      const payload = { method: "GET", url };
      return fetchApi(payload).then((res) => res.data.rows);
    };
    const params = convertQueryParams({
      isAll: true,
      alltype: ["individual", "cop-entity"],
      options: true,
    });
    const receiverList = await fetchData(`/client?${params}`);

    const paramsName = convertQueryParams({
      isAll: true,
      alltype: ["individual", "cop-entity"],
      exportAll: true,
    });
    const receiverName = await fetchData(`/client?${paramsName}`);
    setReceiverArray(receiverName);
    let namelist = receiverName
      ? receiverName?.map((d) => {
          let obj = {
            value: d?.firstName ?? d?.name,

            label: d?.name
              ? d?.name
              : ` ${
                  d?.firstName && d?.lastName
                    ? `${d?.firstName} ${
                        d?.middleName && d?.middleName !== "."
                          ? d?.middleName + " "
                          : ""
                      }${d?.lastName}`
                    : ""
                }`,
          };
          return obj;
        })
      : [];

    let data = {
      name: namelist,
      masterId: receiverList,
    };
    setReceiverOptions(data);
  };
  const relatedEntityOnChange = async (value, field) => {
    let val = {},
      val2 = {},
      val3 = {};

    let {
      totalSharesHeld,
      code,
      dateAppointment,
      dateResignation,
      ownershipPercentage,
      sharesHeld,
    } = value;

    let corporateData = { ...schema1 };
    let obj = { ...shareForm };

    if (
      field == "shareEntry" ||
      field == "nomineeShareEntry" ||
      field == "sharesHeld"
    ) {
      setSchema1({
        ...corporateData,
        sharesHeld: sharesHeld,
        shareEntry: value[field],
        nomineeShareEntry: value[field],
      });
      obj["show"] = true;
      if (value[field] == "Share Transfer") {
        obj["type"] = "transfer";
        setShareForm(obj);
      }
      if (value[field] == "New Allocation") {
        obj["type"] = "new";
        setShareForm(obj);
      }
      setSenderFormData({
        name: ` ${schema1.name}`,
        masterId: schema1._id,
        shareCapital: entityData?.authorisedShares,
        createdAt: new Date(),
      });
      let percentage =
        (Number(value.sharesHeld) / Number(entityData?.authorisedShares)) * 100;
      percentage = `${Math.round(Number(percentage) * 100) / 100} %`;
      setAllocationFormData({
        name: ` ${schema1.name}`,
        shareCapital: entityData?.authorisedShares,
        totalShares: value.sharesHeld,
        numberOfShares: value.sharesHeld,
        percentage: percentage,
        masterId: schema1._id,
        createdAt: new Date(),
      });
      getReceiverList();
    }

    if (field === "code" && !totalSharesHeld) {
      let ctr = {};
      if (value.code) {
        if (value.code.startsWith("EN")) {
          ctr.code = value.code;
        } else {
          ctr.id = value.code;
        }
      } else {
        ctr = value;
      }

      let params = convertQueryParams(ctr);
      let payload = {
        method: "GET",
        url: `/entity?${params}`,
      };

      let data = await fetchApi(payload).then((res) => res.data.rows[0]);

      if (data) {
        val = {
          _id: data?._id,
          code: data?.code,
          corpInfoNo: data?.corpInfoNo,
          name: data?.name,
          type: data?.type,
          status: data?.status,
          clientType: data?.clientType,
          incorporationDate: data?.incorporationDate,
          incorporationNumber: data?.incorporationNumber,
          incorporationCountry: data?.incorporationCountry,
          relatedEntityCode: data?.relatedEntityCode,
          createdAt: data?.createdAt,
          adminManager: data?.adminManager,
          relationshipManager: data?.relationshipManager,
          administrator: data?.administrator,
          email: data?.email,
          contactNo: data?.contactNo,
          authorisedShares: data?.authorisedShares,
          YearOfEndDate: data?.YearOfEndDate,
          feeType: data?.feeType,
          subCat: data?.subCat,
          dateResignation: dateResignation,
          totalSharesHeld: totalSharesHeld,
          ownershipPercentage: ownershipPercentage,
          sharesHeld: sharesHeld,
        };

        val2 = {
          residentialAddress: data?.residentialAddress,
          serviceAddress: data?.serviceAddress,
          line_1: data?.details?.address?.line_1,
          line_2: data?.details?.address?.line_2,
          line_3: data?.details?.address?.line_3,
          zipCode: data?.details?.address?.zipCode,
          country: data?.details?.address?.country,
        };

        val3 = {
          riskRating: data.details.riskRating,
          pep: data.details.pep,
          sanction: data.details.sanction,
        };

        setSchema1(val);
        setSchema2(val2);
        setSchema3(val3);
      }
    }
    if (field === "name") {
      handleNameChange(value.name);
    }

    if (data?.totalSharesHeld !== totalSharesHeld || !data?.totalSharesHeld) {
      if (totalSharesHeld) {
        let percentage =
          (Number(totalSharesHeld) / Number(entityData?.authorisedShares)) *
          100;
        val.ownershipPercentage = `${percentage.toFixed(2)} %`;
        val._id = val?._id ?? "";
      }
    }
    setObjData(value);
  };

  const shareTableColumn = [
    {
      title: "Action type",
      dataIndex: "transactionType",
      sorter: (a, b) => a.transactionType - b.transactionType,
    },
    {
      title: "Master file Code",
      dataIndex: "senderMasterId",
      sorter: (a, b) => a.code - b.code,
    },
    {
      title: "Share Type",
      dataIndex: "shareType",
      sorter: (a, b) => a.shareType - b.shareType,
    },
    {
      title: "Authorised Share Capital",
      dataIndex: "sharesHeld",
      sorter: (a, b) => a.shareCapital - b.shareCapital,
    },
    {
      title: "From",
      dataIndex: "from",
      sorter: (a, b) => a.from - b.from,
    },
    {
      title: "To",
      dataIndex: "to",
      sorter: (a, b) => a.to - b.to,
    },
    {
      title: "Par Value",
      dataIndex: "parValue",
      sorter: (a, b) => a.parValue - b.parValue,
    },
    {
      title: "Effective date",
      dataIndex: "effectiveDate",
      sorter: (a, b) => a.effectiveDate - b.effectiveDate,
      format: "YYYY-MM-DD",
    },
    {
      title: "Total Shares held",
      dataIndex: "sharesHeld",
      sorter: (a, b) => a.totalShares - b.totalShares,
    },
    {
      title: "Ownership Percentage",
      dataIndex: "percentage",
      sorter: (a, b) => a.percentage - b.percentage,
    },
    {
      title: "Certificate Number",
      dataIndex: "shareNumber",
      sorter: (a, b) => a.shareNumber - b.shareNumber,
    },
    {
      title: "Date of entry",
      dataIndex: "createdAt",
      sorter: (a, b) => a.createdAt - b.createdAt,
    },
    {
      title: "Comment",
      render: (_, { _id, comment }, index) => (
        <Popover
          className="custom-popover"
          placement="rightBottom"
          content={<p className="mb-0">{comment}</p>}
        >
          <button className="primary-btn">View</button>
        </Popover>
      ),
    },
  ];
  const handleRefreshTransaction = async (data) => {
    console.log(data, "drtyfuihoj");
    try {
      const fetchData = async (url) => {
        const payload = { method: "GET", url };
        const response = await fetchApi(payload);
        return response?.data?.rows || [];
      };

      const clientParams = convertQueryParams({
        isAll: true,
        options: true,
      });
      const clientData = await fetchData(`/client?${clientParams}`);
      console.log(clientData, "clientData");

      const clientDataMap = new Map(
        clientData.map((item) => [item.value, item.label])
      );

      const transformedResponse = {
        rows: data.map((row) => ({
          ...row,
          senderMasterId: row.senderForm?.length
            ? clientDataMap.get(row.senderForm[0]?.masterId)
            : row.allocationForm?.length
            ? clientDataMap.get(row.allocationForm[0]?.masterId)
            : null,

          shareNumber: row.senderForm?.length
            ? row.senderForm[0]?.certificateNumber
            : row.receiverForm?.length
            ? row.receiverForm[0]?.certificateNumber
            : row.allocationForm?.length
            ? row.allocationForm[0]?.certificateNumber
            : null,

          noShares: row.senderForm?.length
            ? row.senderForm[0]?.numberOfShares
            : row.receiverForm?.length
            ? row.receiverForm[0]?.numberOfShares
            : row.allocationForm?.length
            ? row.allocationForm[0]?.numberOfShares
            : null,

          sharesHeld: row.senderForm?.length
            ? row.senderForm[0]?.shareCapital
            : row.receiverForm?.length
            ? row.receiverForm[0]?.shareCapital
            : row.allocationForm?.length
            ? row.allocationForm[0]?.shareCapital
            : null,

          parValue: row.senderForm?.length
            ? row.senderForm[0]?.parValue
            : row.receiverForm?.length
            ? row.receiverForm[0]?.parValue
            : row.allocationForm?.length
            ? row.allocationForm[0]?.parValue
            : null,

          ceasedDate: row.senderForm?.length
            ? row.senderForm[0]?.ceasedDate
            : row.receiverForm?.length
            ? row.receiverForm[0]?.ceasedDate
            : row.allocationForm?.length
            ? row.allocationForm[0]?.ceasedDate
            : null,

          effectiveDate: row.senderForm?.length
            ? row.senderForm[0]?.effectiveDate
            : row.receiverForm?.length
            ? row.receiverForm[0]?.effectiveDate
            : row.allocationForm?.length
            ? row.allocationForm[0]?.effectiveDate
            : null,

          totalShares: row.senderForm?.length
            ? row.senderForm[0]?.totalShares
            : row.receiverForm?.length
            ? row.receiverForm[0]?.totalShares
            : row.allocationForm?.length
            ? row.allocationForm[0]?.totalShares
            : null,

          percentage: row.senderForm?.length
            ? row.senderForm[0]?.percentage
            : row.receiverForm?.length
            ? row.receiverForm[0]?.percentage
            : row.allocationForm?.length
            ? row.allocationForm[0]?.percentage
            : null,

          comment: row.allocationForm?.length
            ? row.allocationForm[0]?.comment
            : null,

          currency: row.allocationForm?.length
            ? row.allocationForm[0]?.currency
            : null,
          from: row.senderForm?.length
            ? row.senderForm[0]?.from
            : row.receiverForm?.length
            ? row.receiverForm[0]?.from
            : row.allocationForm?.length
            ? row.allocationForm[0]?.from
            : null,
          to: row.senderForm?.length
            ? row.senderForm[0]?.to
            : row.receiverForm?.length
            ? row.receiverForm[0]?.to
            : row.allocationForm?.length
            ? row.allocationForm[0]?.to
            : null,
          shareType: row.senderForm?.length
            ? row.senderForm[0]?.shareType
            : row.receiverForm?.length
            ? row.receiverForm[0]?.shareType
            : row.allocationForm?.length
            ? row.allocationForm[0]?.shareType
            : null,

          combinedForm: {
            sender: row.senderForm || [],
            receiver: row.receiverForm || [],
            allocation: row.allocationForm || [],
          },
        })),
      };

      setShareTableData(transformedResponse.rows);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // const shareTableData = [
  //   {
  //     type: "Share Transfer",
  //     code: "INV000001",
  //     name: "Vaheeshan",
  //     shareCapital: "N/A",
  //     numberOfShares: 200,
  //     from: "20-10-2022",
  //     to: "20-10-2024",
  //     parValue: "N/A",
  //     effectiveDate: "20-10-2026",
  //     totalShares: 800,
  //     percentage: 20,
  //     certificateNumber: "FJC038932",
  //     dateOfEntry: "30-10-2024",
  //   },
  //   {
  //     type: "Share Transfer",
  //     code: "INV000001",
  //     name: "Vaheeshan",
  //     shareCapital: "N/A",
  //     numberOfShares: 200,
  //     from: "20-10-2022",
  //     to: "20-10-2024",
  //     parValue: "N/A",
  //     effectiveDate: "20-10-2026",
  //     totalShares: 800,
  //     percentage: 20,
  //     certificateNumber: "FJC038932",
  //     dateOfEntry: "30-10-2024",
  //   },
  //   {
  //     type: "New Allocation",
  //     code: "INV000001",
  //     name: "Vaheeshan",
  //     shareCapital: "N/A",
  //     numberOfShares: 200,
  //     from: "20-10-2022",
  //     to: "20-10-2024",
  //     parValue: "N/A",
  //     effectiveDate: "20-10-2026",
  //     totalShares: 800,
  //     percentage: 20,
  //     certificateNumber: "FJC038932",
  //     dateOfEntry: "30-10-2024",
  //   },
  // ];

  const copEntityDropDownOnClick = async (value) => {
    let params = {};
    params.isAll = true;
    params.masterType = "entity";
    params.code = value;
    let query = convertQueryParams(params);
    let payload = {
      method: "GET",
      url: `/client?${query}`,
    };
    let clientData = await fetchApi(payload).then((res) => {
      return res?.data;
    });
    let list = clientData.rows.map((d) => {
      let obj = {
        value: d?._id,
        label: d?.code,
      };
      return obj;
    });
    setRelatedEntityData(list);

    let name = clientData.rows.map((d) => {
      let obj = {
        value: d?.name,
        label: d?.name,
      };
      return obj;
    });
    setRelatedEntityName(name);
    setRelatedEntityArray(list);
  };
  const saveOnClick = () => {
    saveData(selectedId);
  };

  const saveData = async () => {
    let transaction = {};
    if (schema1.shareEntry == "Share Transfer") {
      let receiverFormSchema = receiverFormRef.current.getPayload();
      let senderFormSchema = senderFormRef.current.getPayload();
      if (receiverFormSchema.masterId) {
        transaction = {
          receiverformFields: {
            masterId: receiverFormSchema.masterId,
            masterType: receiveFormData.masterType,
            shareCapital: receiverFormSchema.shareCapital,
            numberOfShares: receiverFormSchema.numberOfShares,
            from: receiverFormSchema.from,
            to: receiverFormSchema.to,
            effectiveDate: receiverFormSchema.effectiveDate,
            totalShares: receiverFormSchema.totalShares,
            percentage: receiverFormSchema.percentage,
            certificateNumber: receiverFormSchema.certificateNumber,
          },
          senderFormFields: {
            masterId: senderFormSchema.masterId,
            masterType: "individual",
            shareCapital: senderFormSchema.shareCapital,
            numberOfShares: senderFormSchema.numberOfShares,
            from: senderFormSchema.from,
            to: senderFormSchema.to,
            effectiveDate: senderFormSchema.effectiveDate,
            totalShares: senderFormSchema.totalShares,
            percentage: senderFormSchema.percentage,
            certificateNumber: senderFormSchema.certificateNumber,
            shareType: senderFormSchema.shareType,
            parValue: senderFormSchema.parValue,
            comment: senderFormSchema.comment,
            currency: senderFormSchema.currency,
            ceasedDate: senderFormSchema.ceasedDate,
          },
        };
      } else {
        sendNotify("error", "plz select receiver");
      }
    } else if (schema1.shareEntry == "New Allocation") {
      let allocationFormSchema = allocationFormRef.current.getPayload();
      transaction = {
        allocationformFields: {
          masterId: allocationFormSchema.masterId,
          masterType: "individual",
          shareCapital: allocationFormSchema.shareCapital,
          numberOfShares: allocationFormSchema.numberOfShares,
          from: allocationFormSchema.from,
          to: allocationFormSchema.to,
          effectiveDate: allocationFormSchema.effectiveDate,
          totalShares: allocationFormSchema.totalShares,
          percentage: allocationFormSchema.percentage,
          certificateNumber: allocationFormSchema.certificateNumber,
          shareType: allocationFormSchema.shareType,
          parValue: allocationFormSchema.parValue,
          comment: allocationFormSchema.comment,
          currency: allocationFormSchema.currency,
          ceasedDate: allocationFormSchema.ceasedDate,
        },
      };
      // (transaction.allocationFormSchema = allocationFormSchema);
    }
    let options1 = formRef.current.getPayload();
    let options2 = adsFormRef.current.getPayload();
    let options3 = riskFormRef.current.getPayload();
    let relEntity = relatedEntityData.find((f) => f.label == options1.code);
    console.log(relEntity, options1.code);
    //  let cop = copEntityData.find(
    //    (f) => f.code == options1.code || f._id == options1.code
    //  );
    const prepareData = () => ({
      relEntity: schema1._id,
      enId: entityData._id,
      resignation: options1.dateResignation,
      appointment: options1.dateAppointment,
      noOfShares: options1.sharesHeld || options1.nomineeSharesHeld,
      totalShares: options1.totalSharesHeld,
      ownershipPer: options1.ownershipPercentage,
      agreementDate: options1.nomineeAgreementDate,
      subType: "Direct Entry",
      shareEntry: schema1.shareEntry,
      checkedList: checkedList,
      transaction: transaction,
    });

    const handleCheckedList = () => {
      const organizationArray = organizationStructure.data.rows.filter(
        (a) => a.pid == null
      );

      const fullName = schema1.name;

      const createObj = (
        role,
        shares,
        percentage = null,
        shareholders = null,
        nominee = null
      ) => {
        const roleData = organizationArray.find((i) => i.role === role);
        return roleData
          ? {
              name: fullName,
              pid: roleData._id,
              color: roleData.color,
              role: "Related Entity",
              pType: role,
              shares,
              percentage,
              directShareholder: shareholders,
              nomineeShareholder: nominee,
            }
          : null;
      };

      const objArray = [];
      if (checkedList.directors || checkedList.reserve) {
        objArray.push({
          ...createObj("Director"),
          reserveDirector: checkedList.reserve,
        });
      }
      if (checkedList.shareholders || checkedList.nominee) {
        const sharePercentage = `${Math.round(
          (Number(formData.sharesHeld) / Number(entityData?.authorisedShares)) *
            100
        )}%`;
        objArray.push(
          createObj(
            "Shareholder",
            formData.sharesHeld,
            sharePercentage,
            checkedList.shareholders,
            checkedList.nominee
          )
        );
      }
      if (checkedList.ubo) {
        objArray.push(
          createObj(
            "UBO",
            formData.totalSharesHeld,
            formData.ownershipPercentage
          )
        );
      }

      return objArray.filter(Boolean);
    };

    const handleApiPost = async (url, data) => {
      try {
        const response = await fetchApi({ method: "POST", url, data });
        return response;
      } catch (error) {
        sendNotify("error", error?.message);
        throw error;
      }
    };
    const handleApiPut = async (url, data) => {
      try {
        const response = await fetchApi({ method: "PUT", url, data });
        return response;
      } catch (error) {
        sendNotify("error", error?.message);
        throw error;
      }
    };
    const processRows = async (objArray) => {
      for (const obj of objArray) {
        await handleApiPost(`/organizationStructure`, obj);
      }
    };
    console.log(selectedId, "xrytyuio");

    const data = prepareData();
    if (Object.values(checkedList).some(Boolean)) {
      try {
        const response = selectedId
          ? await handleApiPut(
              `/relatedEntityDetails/update/${selectedId}`,
              data
            )
          : await handleApiPost(`/relatedEntityDetails`, data);

        if (response?.status == 208) {
          sendNotify("error", response?.message);
        } else {
          sendNotify("success", response?.message);
          closeModalOnClick();
          setCheckedList({
            directors: false,
            shareholders: false,
            ubo: false,
            nominee: false,
            reserve: false,
          });
        }
        if (organizationStructure?.data?.rows?.length) {
          const objArray = handleCheckedList();
          await processRows(objArray);
        } else {
          const params = { ...queryParams, enId: id || query.id };
          dispatch(getRelatedEntityDetails(params));
          dispatch(getOrganizationStructure(params));
        }
      } catch (error) {
        sendNotify("error", error?.message);
      }

      const params = { ...queryParams, enId: id || query.id };
      dispatch(getRelatedEntityDetails(params));
    } else {
      sendNotify("error", "Please enter at least one name");
    }
  };
  const relatedEntityEditModalOnClick = async (id, index) => {
    let val,
      val2,
      val3 = {};
    let arr = [...entityFormSchema];
    // setSelectedIndex(index + 1);
    setSelectedIndex(relatedEntityDetails.data.totalCount - index);
    setSelectedId(id);
    const details = relatedEntityDetails.data.rows.find((e) => e._id === id);

    if (!details) return;
    const { shareholders, ubo, nominee, directors, reserve } =
      details.checkedList;
    const params = {
      isAll: true,
      masterType: "entity",
      exportAll: true,
      //id: details.relEntity,
    };
    const query = convertQueryParams(params);
    const payload = {
      method: "GET",
      url: `/client?${query}`,
    };
    try {
      const clientData = await fetchApi(payload).then(
        (res) => res?.data?.rows || []
      );

      setRelatedEntityData(clientData);
      setRelatedEntityArray(
        clientData.map((d) => ({ value: d?._id, label: d?.code }))
      );

      const finded = clientData.find((e) => e._id === details.relEntity);

      if (finded) {
        val = {
          _id: finded?._id,
          code: finded?.code,
          corpInfoNo: finded?.corpInfoNo,
          name: finded?.name,
          type: finded?.type,
          status: finded?.status,
          clientType: finded?.clientType,
          incorporationDate: finded?.incorporationDate,
          incorporationNumber: finded?.incorporationNumber,
          incorporationCountry: finded?.incorporationCountry,
          relatedEntityCode: finded?.relatedEntityCode,
          createdAt: finded?.createdAt,
          adminManager: finded?.adminManager,
          relationshipManager: finded?.relationshipManager,
          administrator: finded?.administrator,
          email: finded?.email,
          contactNo: finded?.contactNo,
          authorisedShares: finded?.authorisedShares,
          YearOfEndDate: finded?.YearOfEndDate,
          feeType: finded?.feeType,
          subCat: finded?.subCat,
        };

        val2 = {
          residentialAddress: finded?.residentialAddress,
          serviceAddress: finded?.serviceAddress,
          line_1: finded?.details?.address?.line_1,
          line_2: finded?.details?.address?.line_2,
          line_3: finded?.details?.address?.line_3,
          zipCode: finded?.details?.address?.zipCode,
          country: finded?.details?.address?.country,
        };
        console.log({ ...val, ...val2, ...val3 }, "wersdyguhio");

        val3 = {
          riskRating: finded?.details?.riskRating,
          pep: finded?.details?.pep,
          sanction: finded?.details?.sanction,
        };
      }

      setSchema1(val);
      setSchema2(val2);
      setSchema3(val3);
      setObjData({ ...val, ...val2, ...val3 });
      setCheckedList(details.checkedList);
      setUboChecked(ubo);
      setDirectorChecked(directors);
      setReserveChecked(reserve);
      setShareholderChecked(shareholders);
      setNomineeChecked(nominee);
      setOpenRelatedEntity(true);
      // updateChecked("ubo", ubo);
      // updateChecked("shareholders", true);
      addNewChange("ubo", ubo);
      updateChecked("shareholders", shareholders);
      addNewRow(directorFormSchema, directors, "directors");
      // setShareForm({ show: false, type: "" });
      if (details.transaction) {
        handleRefreshTransaction(details.transaction);
      }
      if (directors) {
        addNewRow(directorFormSchema, directors, "directors");
        arr.push(...directorFormSchema);
      }
      if (reserve) {
        addNewRow(directorFormSchema, reserve, "reserve");
        arr.push(...directorFormSchema);
      }
      if (ubo) {
        addNewChange(uboFormSchema, ubo, "ubo");
        arr.push(...uboFormSchema);
      }
      if (shareholders) {
        addNewCustom(shareholderFormSchema, "shareholders", shareholders);
        arr.push(...shareholderFormSchema);
      }
      if (nominee) {
        addNewCustom(nomineeShareholder, "nominee", nominee);
        arr.push(...nomineeShareholder);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const individualcolumns = [
    {
      title: "MasterFile Code",
      dataIndex: "entityCode",
      sorter: (a, b) => a.relatedEntityCode - b.relatedEntityCode,
    },
    {
      title: "MasterFile Name",
      dataIndex: "entityName",
      sorter: (a, b) => a.relatedEntityName - b.relatedEntityName,
    },
    {
      title: "Select Type",
      dataIndex: "shareType",
      sorter: (a, b) => a.shareType - b.shareType,
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      format: "YYYY-MM-DD",
      sorter: (a, b) => a.incorpDate - b.incorpDate,
    },
    {
      title: "Delete / Update",
      render: (_, { _id }, index) => (
        <div className="d-flex gap-2 align-items-center justify-content-end">
          <button
            className="action-btn delete-btn"
            onClick={() => relatedEntityDeleteOnClick(_id, index)}
          >
            <i className="far fa-trash-alt"></i>
          </button>
          <button
            className="action-btn"
            onClick={() => relatedEntityEditModalOnClick(_id, index)}
          >
            <i className="far fa-pencil"></i>
          </button>
        </div>
      ),
    },
  ];
  const relatedEntityModalOnClick = () => {
    setSelectedId("");
    setUboChecked(false);
    setDirectorChecked(false);
    setReserveChecked(false);
    setShareholderChecked(false);
    setNomineeChecked(false);
    setOpenRelatedEntity(true);
  };
  const closeModalOnClick = () => {
    setSelectedId("");
    setObjData({});
    setUboChecked(false);
    setDirectorChecked(false);
    setReserveChecked(false);
    setShareholderChecked(false);
    setNomineeChecked(false);
    setOpenRelatedEntity(false);
    setFormData({});
    setSchema3({});
    setSchema2({});
    setSchema1({});
    clearFormRef();
    setShareForm({
      show: false,
      type: "",
    });
    setCheckedList({
      directors: false,
      shareholders: false,
      ubo: false,
      nominee: false,
      reserve: false,
    });
    clearArrItem();
  };
  const findOneEntity = async (value) => {
    setLoading(true);
    let ctr = {};
    if (value.code) {
      if (value.code.startsWith("EN")) {
        ctr.code = value.code;
      } else {
        ctr.id = value.code;
      }
    } else {
      ctr = value;
    }

    let params = convertQueryParams(ctr);
    let payload = {
      method: "GET",
      url: `/entity?${params}`,
    };
    let data = await fetchApi(payload).then((res) => {
      return res?.data?.rows?.[0];
    });

    let val,
      val2,
      val3 = {};
    if (data) {
      setValue(data?.busGeography);
      val = {
        _id: data?._id,
        code: data?.code,
        corpInfoNo: data?.corpInfoNo,
        name: data?.name,
        type: data?.type,
        status: data?.status,
        clientType: data?.clientType,
        incorporationDate: data?.incorporationDate,
        incorporationNumber: data?.incorporationNumber,
        incorporationCountry: data?.incorporationCountry,
        relatedEntityCode: data?.relatedEntityCode,
        createdAt: data?.createdAt,
        adminManager: data?.adminManager,
        relationshipManager: data?.relationshipManager,
        administrator: data?.administrator,
        email: data?.email,
        contactNo: data?.contactNo,
        authorisedShares: data?.authorisedShares,
        YearOfEndDate: data?.YearOfEndDate,
        feeType: data?.feeType,
        subCat: data?.subCat,
      };
      val2 = {
        residentialAddress: data?.residentialAddress,
        serviceAddress: data?.serviceAddress,
        line_1: data?.details?.address?.line_1,
        line_2: data?.details?.address?.line_2,
        line_3: data?.details?.address?.line_3,
        zipCode: data?.details?.address?.zipCode,
        country: data?.details?.address?.country,
      };
      val3 = {
        riskRating: data?.details?.riskRating,
        pep: data?.details?.pep,
        sanction: data?.details?.sanction,
        sanctionText: data?.details?.sanctionText,
      };
    }

    setSchema1(val);
    setSchema2(val2);
    setSchema3(val3);

    setLoading(false);
  };
  const searchChange = (value) => {
    if (value) {
      let ctr = {};
      ctr.id = value;
      findOneEntity(ctr);
      setFindId(value);
      setObjData(value);
    } else {
      setFindId(null);
      setObjData({});
    }
  };
  const getPagination = (current, pageSize) => {
    setTableLoading(true);
    let params = { ...queryParams };
    params._start = (current - 1) * pageSize;
    params._limit = pageSize;
    params.enId = id ? id : query.id;
    dispatch(getRelatedEntityDetails(params));
    setQueryParams(params);
    setTableLoading(false);
  };
  const clearArrItem = () => {
    let arr = [...entityFormSchema];
    const schemas = [
      nomineeShareholder,
      directorFormSchema,
      shareholderFormSchema,
      uboFormSchema,
      pocFormSchema,
    ];

    schemas.forEach((schema) => removeArrItem(schema, arr));

    setEntityFormSchema(arr);
  };
  return (
    <div>
      <div className="d-flex py-4 gap-3 align-items-center">
        <Switch
          className="custom-switch"
          onChange={(value) => setRelatedEntityShow(value)}
        />
        <h4 className="primary-title">Related Entity</h4>
        {relatedEntityShow && (
          <button
            className="primary-btn ms-auto mb-3"
            onClick={relatedEntityModalOnClick}
          >
            <i className="fal fa-plus-circle"></i>Add New
          </button>
        )}
      </div>
      {relatedEntityShow && (
        <div className="border-box">
          {relatedEntityShow && (
            <Spin spinning={tableLoading}>
              <Table
                className="custom-table-light mt-md-1 mb-3"
                columns={individualcolumns}
                dataSource={relatedEntityDetails?.data?.rows}
                //  pagination={false}
                scroll={{ x: 1200 }}
                pagination={{
                  className: "custom-pagination",
                  defaultCurrent: 1,
                  pageSize: queryParams?._limit,
                  total: relatedEntityDetails?.data?.totalCount,
                  onChange: getPagination,
                }}
              />
            </Spin>
          )}
          <Modal
            title={
              selectedId ? "Edit Related Entity " : "Add New Related Entity"
            }
            okText={selectedId ? "Update" : "Save"}
            className="custom-modal"
            open={openRelatedEntity}
            centered={true}
            width={1100}
            onOk={saveOnClick}
            onCancel={closeModalOnClick}
            // footer={false}
          >
            {relatedEntityShow && (
              <div className="mb-md-4">
                {forms.corporateEntity.map((a, i) => {
                  return (
                    <div key={i} id={`corporateEntity${i}`} className="mb-4">
                      {!selectedId ? (
                        <div className="custom-form row mb-3 mb-md-4 gy-3">
                          <div className="d-flex gap-2">
                            <div className="container mt-4">
                              <div className="d-flex align-items-center justify-content-between">
                                <h4 className="primary-title ">
                                  Related Entity
                                </h4>
                                <button
                                  className="primary-btn"
                                  onClick={clearForm}
                                >
                                  Clear Form
                                </button>
                              </div>
                              <div className="search-bar d-flex p-3 bg-light shadow-sm rounded">
                                <Select
                                  className="custom-select w-100"
                                  placeholder={m.code || "Select Code"}
                                  value={findId}
                                  onChange={(value) => searchChange(value)}
                                  options={relatedEntityData}
                                  showSearch={true}
                                  allowClear={true}
                                  filterOption={(input, option) =>
                                    option.label
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                />
                                &nbsp;
                                <Select
                                  className="custom-select w-100"
                                  placeholder={m.name}
                                  value={findId}
                                  onChange={(value) => searchChange(value)}
                                  options={relatedEntityName}
                                  showSearch={true}
                                  allowClear={true}
                                  filterOption={(input, option) =>
                                    option.label
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <div className="d-flex gap-3 mb-3 align-items-center">
                        <h3 className="sub-title">
                          Related Entity 0{i + 1} &nbsp;
                          <i className="fal fa-question-circle"></i>
                        </h3>
                        {i !== 0 && (
                          <button
                            className="outline-btn red-btn"
                            onClick={() => removeNewCol("corporateEntity", i)}
                          >
                            <i className="fal fa-trash-alt"></i> Remove
                          </button>
                        )}
                        <div className="d-flex gap-3 align-items-center">
                          <Checkbox
                            className="custom-checkbox"
                            checked={directorChecked && !reserveChecked}
                            onChange={(e) =>
                              addNewRow(
                                directorFormSchema,
                                e.target.checked,
                                "directors"
                              )
                            }
                            disabled={JSON.stringify(objData) == "{}"}
                          >
                            Directors
                          </Checkbox>
                          <Checkbox
                            className="custom-checkbox"
                            checked={shareholderChecked && !nomineeChecked}
                            onChange={(e) =>
                              addNewCustom(
                                shareholderFormSchema,
                                "shareholders",
                                e.target.checked
                              )
                            }
                            disabled={JSON.stringify(objData) == "{}"}
                          >
                            Shareholders
                          </Checkbox>
                          <Checkbox
                            className="custom-checkbox"
                            onChange={(e) =>
                              addNewChange(uboFormSchema, e.target.checked)
                            }
                            disabled={JSON.stringify(objData) == "{}"}
                          >
                            UBOs
                          </Checkbox>
                          <Checkbox
                            className="custom-checkbox"
                            checked={nomineeChecked && !shareholderChecked}
                            onChange={(e) =>
                              addNewCustom(
                                nomineeShareholder,
                                "nominee",
                                e.target.checked
                              )
                            }
                            disabled={JSON.stringify(objData) == "{}"}
                          >
                            Nominee Shareholder
                          </Checkbox>
                          <Checkbox
                            className="custom-checkbox"
                            checked={reserveChecked && !directorChecked}
                            onChange={(e) =>
                              addNewRow(
                                directorFormSchema,
                                e.target.checked,
                                "reserve"
                              )
                            }
                            disabled={JSON.stringify(objData) == "{}"}
                          >
                            Reserve Director
                          </Checkbox>
                        </div>
                      </div>
                      <Spin spinning={loading}>
                        <FormZ
                          formSchema={entityFormSchema}
                          ref={formRef}
                          formData={schema1}
                          formOptions={{
                            code: relatedEntityArray,
                            name: relatedEntityName,
                          }}
                          formClass="row custom-form mb-3 mb-md-4 gy-3 gx-4"
                          childClass="col-md-3 mb-0"
                          labelClass="mb-1"
                          inputClass="w-100"
                          onChange={relatedEntityOnChange}
                          onKeyUp={(e) =>
                            copEntityDropDownOnClick(e.target.value)
                          }
                        />
                        <FormZ
                          formSchema={adsFormSchema}
                          formData={schema2}
                          ref={adsFormRef}
                          formClass="custom-form row"
                          childClass="form-row mb-4"
                          labelClass="me-3 mb-0"
                          inputClass="w-100"
                        />
                        <FormZ
                          formSchema={riskFormSchema}
                          formData={schema3}
                          ref={riskFormRef}
                          formClass="custom-form row gy-3 align-items-end"
                          childClass="col-md-3 mb-0"
                          labelClass="mb-1"
                          inputClass="w-100"
                        />
                        <button className="primary-btn mt-4" onClick={saveData}>
                          Update
                        </button>
                      </Spin>
                    </div>
                  );
                })}
                {shareForm["show"] && (
                  <div>
                    {shareForm["type"] == "transfer" ? (
                      <div>
                        <div className="primary-title mb-2">
                          Transferor (Sender)
                        </div>
                        <FormZ
                          ref={senderFormRef}
                          formSchema={senderForm}
                          onChange={senderOnChange}
                          formData={senderFormData}
                          formClass="row custom-form mb-3 gy-3 gx-4"
                          childClass="col-md-3 mb-0"
                          labelClass="mb-1"
                          inputClass="w-100"
                        />
                        <div className="primary-title mb-2">
                          Transferee (Receiver)
                        </div>
                        <FormZ
                          ref={receiverFormRef}
                          formSchema={receiverForm}
                          onChange={receiverOnChange}
                          formOptions={receiverOptions}
                          formData={receiveFormData}
                          formClass="row custom-form mb-3 gy-3 gx-4"
                          childClass="col-md-3 mb-0"
                          labelClass="mb-1"
                          inputClass="w-100"
                        />
                      </div>
                    ) : (
                      <div>
                        <div className="primary-title mb-2">New Allocation</div>
                        <FormZ
                          ref={allocationFormRef}
                          formSchema={allocationForm}
                          onChange={allocationOnChange}
                          formData={allocationFormData}
                          formOptions={receiverOptions}
                          formClass="row custom-form mb-3 gy-3 gx-4"
                          childClass="col-md-3 mb-0"
                          labelClass="mb-1"
                          inputClass="w-100"
                        />
                      </div>
                    )}
                    {selectedId ? (
                      <>
                        <div className="primary-title mb-2">
                          Detailed History
                        </div>
                        <Table
                          className="custom-table-light mt-3 mb-3"
                          columns={shareTableColumn}
                          dataSource={shareTableData}
                          scroll={{ x: 2000 }}
                          pagination={{
                            className: "custom-pagination",
                          }}
                        />{" "}
                      </>
                    ) : null}
                  </div>
                )}
                {/* {Object.keys(checkedList).some((k) => checkedList[k]) && (
                  <div className="d-flex justify-content-between align-items-center mt-3">
                    <h6
                      className="hyper-link mb-0"
                      onClick={() => setHistoryShow(!historyShow)}
                    >
                      Detailed History{" "}
                      <i
                        className={`fas fa-chevron-down ${
                          historyShow ? "fa-rotate-180" : ""
                        }`}
                      ></i>
                    </h6>
                    {historyShow ? (
                      <button className="primary-btn ms-auto">Export</button>
                    ) : (
                      <span className="py-2">&nbsp;</span>
                    )}
                  </div>
                )} */}
                {historyShow ? (
                  <Table
                    className="custom-table-light mt-md-1 mb-3"
                    columns={columns}
                    dataSource={tableData}
                    pagination={false}
                    scroll={{ x: 1800 }}
                  />
                ) : (
                  <></>
                )}
              </div>
            )}
          </Modal>
        </div>
      )}
    </div>
  );
}
