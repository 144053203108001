import { useState, useEffect, useRef } from "react";
import { Table, Select, Switch, Checkbox, Popover, Modal, Spin } from "antd";

import { useSelector, useDispatch } from "react-redux";
import { languagesArray } from "../../../store/language/languageSlice";

import { getOrganizationStructure } from "../../../store/organizationStructure/organizationStructureSlice";
import { getIndividualDetails } from "../../../store/individualDetails/individualDetailsSlice";
import { getCopEntityDetails } from "../../../store/copEntityDetails/copEntityDetailsSlice";
import { getRelatedEntityDetails } from "../../../store/relatedEntityDetails/relatedEntityDetailsSlice";

import MasterFileCreation from "../../../helper/translations/MasterFileCreation.json";

import FormZ from "../../../components/FormZ";

import {
  sendNotify,
  getOptions,
  fetchApi,
  convertQueryParams,
  getQuery,
} from "../../../helper";
import Currency from "../../../helper/currency.json";
async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}
export default function IndividualForm(props) {
  const { language } = useSelector((state) => state.language);

  const { data, isLoading } = useSelector((state) => state.country);
  const individualDetails = useSelector((state) => state.individualDetails);
  const relatedEntityDetails = useSelector(
    (state) => state.relatedEntityDetails
  );
  const copEntityDetails = useSelector((state) => state.copEntityDetails);

  let { isLogged, userDetails } = useSelector((state) => state.auth);
  const organizationStructure = useSelector(
    (state) => state.organizationStructure
  );

  const individualFormRef = useRef([]);
  const senderFormRef = useRef(null);
  const receiverFormRef = useRef(null);
  const allocationFormRef = useRef(null);

  let query = getQuery();

  const dispatch = useDispatch();

  const [sanction, setSanction] = useState("Y");
  const [pep, setPep] = useState("Y");
  const [pepText, setPepText] = useState("");
  const [sanctionText, setSanctionText] = useState("");
  const [loading, setLoading] = useState(false);
  const [checkedList, setCheckedList] = useState({
    directors: false,
    shareholders: false,
    ubo: false,
    nominee: false,
    reserve: false,
  });
  const [tableLoading, setTableLoading] = useState(false);
  const [historyShow, setHistoryShow] = useState([]);
  const [directorChecked, setDirectorChecked] = useState(false);
  const [reserveChecked, setReserveChecked] = useState(false);
  const [shareholderChecked, setShareholderChecked] = useState(false);
  const [uboChecked, setUboChecked] = useState(false);
  const [nomineeChecked, setNomineeChecked] = useState(false);
  const [openindividual, setOpenindividual] = useState(false);
  const [individualShow, setIndividualShow] = useState(false);
  const [objData, setObjData] = useState({});
  const [formData, setFormData] = useState({});
  const [individualArray, setIndividualArray] = useState([]);
  const [individualData, setIndividualData] = useState([]);
  const [fullNameArray, setFullNameArray] = useState([]);
  const [selctedLanguage, setSelctedLanguage] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [queryParams, setQueryParams] = useState({
    _start: 0,
    _limit: 5,
    all: true,
  });
  const [individualOptions, setIndividualOptions] = useState({});
  const [senderFormData, setSenderFormData] = useState({});
  const [receiveFormData, setReceiveFormData] = useState({});
  const [receiverOptions, setReceiverOptions] = useState({});
  const [allocationFormData, setAllocationFormData] = useState({});
  const [receiverArray, setReceiverArray] = useState([]);
  const [findId, setFindId] = useState(null);
  const [shareTableData, setShareTableData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [currency, setCurrency] = useState([]);
  const [shareForm, setShareForm] = useState({
    show: false,
    type: "",
  });

  useEffect(() => {
    let index = languagesArray.find((tc) => tc.value == language);
    setSelctedLanguage(index.value);
  }, [language]);
  let m =
    MasterFileCreation.individual[selctedLanguage] ||
    MasterFileCreation.individual.en;
  let ma =
    MasterFileCreation.masterFile[selctedLanguage] ||
    MasterFileCreation.masterFile.en;

  let { index, name, addNewCol, removeNewCol, entityData, id } = props;

  const handleChange = (value) => {
    setSanction(value);
  };

  useEffect(() => {
    if (id || query.id) {
      let params = { ...queryParams, enId: id ? id : query.id };
      dispatch(getIndividualDetails(params));
      dispatch(getRelatedEntityDetails(params));
      dispatch(getCopEntityDetails(params));
      dispatch(getOrganizationStructure(params));
      handleRefresh();
    }
  }, [id]);

  useEffect(() => {
    if (id || query.id) {
      neworganizationStructure();
    }
  }, [
    individualDetails,
    organizationStructure,
    copEntityDetails,
    relatedEntityDetails,
  ]);
  const handleRefresh = async () => {
    const fetchData = async (url) => {
      const payload = { method: "GET", url };
      return fetchApi(payload).then((res) => res?.data?.rows);
    };
    const params = convertQueryParams({
      isAll: true,
      masterType: "individual",
      options: true,
    });
    const paramsName = convertQueryParams({
      isAll: true,
      masterType: "individual",
      exportAll: true,
    });

    const individual = await fetchData(`/client?${params}`);
    const individualName = await fetchData(`/client?${paramsName}`);
    let fullName = individualName
      ? individualName?.map((d) => {
          let obj = {
            value: d?._id,
            label: [d.firstName, d.middleName, d.lastName]
              .filter(Boolean)
              .join(" "),
          };
          return obj;
        })
      : [];
    let currency = Currency.map((a) => {
      let obj = {
        value: `${a.symbol}`,
        label: `${a.name} - ${a.code} - ${a.symbol}`,
      };
      return obj;
    });
    setCurrency(currency);
    setFullNameArray(fullName);
    handleindividualChange({});
    setIndividualArray(individual);
  };
  const neworganizationStructure = async () => {
    if (organizationStructure?.data?.rows?.length) {
    } else {
      let obj = {
        master: true,
        enId: id ? id : query.id,
      };
      let payload = {
        method: "POST",
        url: `/organizationStructure`,
        data: obj,
      };

      fetchApi(payload)
        .then((res) => {
          if (res.status == 201 && res?.data?.length) {
            dispatch(getOrganizationStructure(queryParams));
          }
        })
        .catch((error) => {});
    }
  };

  const handleChangePep = (value) => {
    setPep(value);
  };

  const [individualFormSchema, setIndividualFormSchema] = useState([
    {
      name: "code",
      value: "",
      type: "select",
      label: "Masterfile Code",
      placeholder: "Enter masterfile code",
      disabled: true,
      loading: isLoading,
      showSearch: true,
    },
    {
      name: "title",
      value: "",
      type: "select",
      label: "Salutation",
      placeholder: "Enter salutation",
      disabled: true,
      options: [
        {
          value: "mr",
          label: "Mr",
        },
        {
          value: "mrs",
          label: "Mrs",
        },
        {
          value: "miss",
          label: "Miss",
        },
        {
          value: "ms",
          label: "Ms",
        },
        {
          value: "dr",
          label: "Dr",
        },
        {
          value: "rev",
          label: "Rev",
        },
      ],
    },
    {
      name: "firstName",
      value: "",
      type: "select",
      label: "First Name",
      showSearch: true,
      disabled: true,
      placeholder: "Enter first name",
    },
    {
      name: "middleName",
      value: "",
      type: "select",
      label: "Middle Name",
      showSearch: true,
      placeholder: "Enter Middle name",
      disabled: true,
    },
    {
      name: "lastName",
      showSearch: true,
      value: "",
      type: "select",
      label: "Last Name",
      placeholder: "Enter last name",
      disabled: true,
    },
    {
      name: "DOB",
      value: "", //
      type: "date",
      label: "Date of Birth",
      placeholder: "Enter Date (YYYY-MM-DD)",
      format: "YYYY-MM-DD",
      disabled: true,
    },
    {
      name: "COB",
      value: "",
      type: "select",
      label: "Country of Birth",
      placeholder: "Select country of birth",
      options: getOptions(data),
      loading: isLoading,
      showSearch: true,
      disabled: true,
    },
    {
      name: "nationality",
      value: "",
      type: "select",
      label: "Nationality",
      placeholder: "Select nationality",
      options: getOptions(data),
      loading: isLoading,
      showSearch: true,
      disabled: true,
    },
    {
      name: "taxInfo",
      value: "",
      type: "text",
      label: "Tax Info Number",
      placeholder: "Enter tax info number",
      disabled: true,
    },
    {
      name: "passportNo",
      value: "",
      type: "text",
      label: "Passport Number",
      placeholder: "Enter passport number",
      disabled: true,
    },
    {
      name: "passportExp",
      value: "", //dayjs("12-12-2028", "DD/MM/YYYY")
      type: "date",
      label: "Expiry Date",
      placeholder: "Enter Date (YYYY-MM-DD)",
      format: "YYYY-MM-DD",
      disabled: true,
    },
    {
      name: "riskRating",
      value: "",
      type: "select",
      label: "Risk Level",
      placeholder: "Select risk level",
      disabled: true,
      options: [
        {
          value: "low",
          label: "Low",
        },
        {
          value: "medium",
          label: "Medium",
        },
        {
          value: "high",
          label: "High",
        },
      ],
    },
    {
      // name: "pep",
      // value: "Y",
      // type: "select",
      // label: "PEP",
      // placeholder: "Enter pep",
      // options: [
      //   {
      //     value: "Y",
      //     label: "Yes",
      //   },
      //   {
      //     value: "N",
      //     label: "No",
      //   },
      // ],
      customClass: pep == "Y" ? "col-md-3" : "col-lg-1",
      label: "PEP",
      customElement: (
        <div className="d-flex gap-2">
          <Select
            className={`custom-select ${pep == "Y" ? "w-50" : "w-100"}`}
            placeholder="Select"
            value={pep}
            disabled={true}
            onChange={handleChangePep}
            options={[
              {
                value: "Y",
                label: "Yes",
              },
              {
                value: "N",
                label: "No",
              },
            ]}
          />
          {pep == "Y" && (
            <input
              type="text"
              disabled={true}
              value={pepText}
              className="w-100"
              placeholder="Enter pep"
            />
          )}
        </div>
      ),
    },
    {
      customClass: sanction == "Y" ? "col-md-3" : "col-lg-1",
      label: "Sanction",
      customElement: (
        <div className="d-flex gap-2">
          <Select
            className={`custom-select ${sanction == "Y" ? "w-50" : "w-100"}`}
            placeholder="Select"
            value={sanction}
            disabled={true}
            onChange={handleChange}
            options={[
              {
                value: "Y",
                label: "Yes",
              },
              {
                value: "N",
                label: "No",
              },
            ]}
          />
          {sanction == "Y" && (
            <input
              type="text"
              className="w-100"
              disabled={true}
              value={sanctionText}
              placeholder="Enter sanction"
            />
          )}
        </div>
      ),
    },
    {
      name: "contactDetails",
      value: "",
      type: "number",
      label: "Contact Details",
      placeholder: "Enter contact details",
      disabled: true,
    },
    {
      name: "email",
      value: "",
      type: "email",
      label: "Email Address",
      placeholder: "Enter email address",
      disabled: true,
    },
  ]);

  const onChangeHandler = (setIdentifierState, event) => {
    setIdentifierState(event.target.value);
  };

  const categories = [
    { name: "Share Transfer", value: "Share Transfer", type: "main" },
    { name: "New Allocation", value: "New Allocation", type: "main" },
    { name: "Direct Entry ", value: "Direct Entry", type: "sub" },
    { name: "Joint Survivorship", value: "Joint Survivorship", type: "sub" },
  ];

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const handleChangeCategory = () => {
    setSelectedCategory(null);
    setSelectedSubCategory(null);
  };
  const handleSubCategoryClick = (category) => {
    setSelectedSubCategory(category);
    let obj = { ...shareForm };

    obj["show"] = true;
    if (selectedCategory == "Share Transfer") {
      if (category == "Direct Entry") {
        obj["type"] = "transfer";
      }
      setShareForm(obj);
    }
    if (selectedCategory == "New Allocation") {
      if (category == "Direct Entry") {
        obj["type"] = "new";
      }
      setShareForm(obj);
    }
  };
  const renderSubOptions = () => (
    <div className="certificate-links">
      <p>
        Selected Category:{" "}
        <strong>
          {categories.find((cat) => cat.value === selectedCategory)?.name}
        </strong>
      </p>

      {categories
        .filter((a) => a.type == "sub")
        .map((category) => (
          <button
            key={category.value}
            onClick={() => handleSubCategoryClick(category.value)}
            className="main-category-btn"
          >
            {category.name}
          </button>
        ))}

      <button className="change-category-btn" onClick={handleChangeCategory}>
        Change Type
      </button>
    </div>
  );

  const renderMainOptions = () => (
    <div className="main-categories">
      {categories
        .filter((a) => a.type == "main")
        .map((category) => (
          <button
            key={category.value}
            onClick={() => handleCategoryClick(category.value)}
            className="main-category-btn"
          >
            {category.name}
          </button>
        ))}
    </div>
  );

  const individualDeleteOnClick = async (id, index) => {
    setSelectedIndex(individualDetails.data.totalCount - index);
    setSelectedId(id);

    let payload = {};

    payload.method = "DELETE";
    payload.url = `/individualDetails?id=${id}`;

    fetchApi(payload)
      .then((res) => {
        sendNotify("success", res?.message);
      })
      .catch((error) => {
        sendNotify("error", error?.message);
      });
  };
  const individualcolumns = [
    {
      title: "Individual Code",
      dataIndex: "individualCode",
      sorter: (a, b) => a.individualName - b.individualName,
    },
    {
      title: "Individual Name",
      dataIndex: "individualName",
      sorter: (a, b) => a.noShares - b.noShares,
    },
    {
      title: "Select Type",
      dataIndex: "shareType",
      sorter: (a, b) => a.shareType - b.shareType,
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      format: "YYYY-MM-DD",
      sorter: (a, b) => a.incorpDate - b.incorpDate,
    },
  ];

  //userDetails.role == "admin" &&
  individualcolumns.push({
    title: "Delete / Update",
    render: (_, { _id }, index) => (
      <div className="d-flex gap-2 align-items-center justify-content-end">
        <button
          className="action-btn delete-btn"
          onClick={() => individualDeleteOnClick(_id, index)}
        >
          <i className="far fa-trash-alt"></i>
        </button>
        <button
          className="action-btn"
          onClick={() => individualEditModalOnClick(_id, index)}
        >
          <i className="far fa-pencil"></i>
        </button>
      </div>
    ),
  });

  let directorFormSchema = [
    {
      name: "dateAppointment",
      value: "",
      type: "date",
      label: "Date of Appointment",
      placeholder: "Enter Date (YYYY-MM-DD)",
      format: "YYYY-MM-DD",
    },
    {
      name: "dateResignation",
      value: "",
      type: "date",
      label: "Date of Resignation",
      placeholder: "Enter Date (YYYY-MM-DD)",
      format: "YYYY-MM-DD",
    },
  ];

  let shareholderFormSchema = [
    {
      name: "sharesHeld",
      value: "",
      type: "number",
      label: "No. of Shares Held",
      placeholder: "Enter no. of shares held",
    },
    {
      name: "shareEntry",
      value: null,
      type: "select",
      options: [
        {
          value: "Share Transfer",
          label: "Share Transfer",
        },
        {
          value: "New Allocation",
          label: "New Allocation",
        },
      ],
      label: "Add share entry",
      placeholder: "Select",
    },
  ];
  shareholderFormSchema.push({
    customClass: "col-md-12",
    label: "Add share entry",
    customElement: <></>,
  });

  let uboFormSchema = [
    {
      name: "totalSharesHeld",
      value: "",
      type: "number",
      label: "Total Shares Held",
      placeholder: "Enter total shares held",
    },
    {
      name: "ownershipPercentage",
      value: "",
      type: "text",
      label: "Ownership Percentage",
      placeholder: "Enter ownership percentage",
      disabled: true,
    },
  ];

  let nomineeShareholder = [
    {
      name: "nomineeSharesHeld",
      value: "",
      type: "number",
      label: "No. of Shares Held",
      placeholder: "Enter no. of shares held",
    },
    {
      name: "nomineeAgreementDate",
      value: "",
      type: "date",
      label: "Nominee Agreement Date",
      placeholder: "Enter nominee agreement Date",
    },
    {
      name: "nomineeShareEntry",
      value: null,
      type: "select",
      options: [
        {
          value: "Share Transfer",
          label: "Share Transfer",
        },
        {
          value: "New Allocation",
          label: "New Allocation",
        },
      ],
      label: "Add share entry",
      placeholder: "Select",
    },
  ];

  function removeArrItem(values, arr) {
    for (let i = 0; i < arr.length; i++) {
      for (let index = 0; index < values.length; index++) {
        if (arr[i]?.name === values[index]?.name) {
          arr.splice(i, 1);
        }
      }
    }
  }
  const senderOnChange = async (value, fields) => {
    let receiveData = { ...receiveFormData };
    receiveData.createdAt = new Date();

    if (fields == "numberOfShares") {
      let noShares = value.numberOfShares ?? 0;

      if (Number(noShares) <= Number(formData.sharesHeld)) {
        receiveData.numberOfShares = Number(value.numberOfShares);
        if (noShares) {
          value.totalShares =
            Number(formData.sharesHeld) - Number(noShares) == 0
              ? 0
              : Number(formData.sharesHeld) - Number(noShares);

          //  percentage;

          let percentage =
            (Number(noShares) / Number(entityData?.authorisedShares)) * 100;
          value.percentage = `${Math.round(Number(percentage) * 100) / 100} %`;
        }
      } else {
        // 10-01-2025
        sendNotify(
          "error",
          "Requested shares exceed the Individual's allocated total."
        );
      }
    }
    // if (fields == "shareCapital") {
    //   receiveData.shareCapital = value.shareCapital;
    // }
    receiveData.shareCapital = entityData?.authorisedShares;

    if (fields == "totalShares") {
      receiveData.totalShares = value.totalShares;
    }
    if (fields == "effectiveDate") {
      receiveData.effectiveDate = value.effectiveDate;
    }
    if (fields == "to") {
      receiveData.to = value.to;
    }
    if (fields == "from") {
      receiveData.from = value.from;
    }

    setSenderFormData(value);
    setReceiveFormData(receiveData);
  };
  const receiverOnChange = async (value, fields) => {
    if (!value || !fields) {
      console.warn("Value or fields is missing", { value, fields });
      return;
    }

    if (fields === "masterId" || fields === "name") {
      let find = null;

      console.log("wedryfguhioj[p");

      if (fields === "name") {
        find = receiverArray.find((e) => e.name === value?.name);
        if (!find) {
          find = receiverArray.find((e) => e.firstName === value?.name);
        }
      } else {
        find = receiverArray.find((e) => e._id === value.masterId);
      }

      if (find) {
        // totalShares;

        let findedDetails = [];
        if (find.masterType == "individual") {
          findedDetails = individualDetails?.data?.rows?.filter(
            (f) => f.invId == find._id
          );
        } else if (find.masterType == "cop-entity") {
          findedDetails = copEntityDetails?.data?.rows?.filter(
            (f) => f.invId == find._id
          );
        } else if (find.masterType == "entity") {
          findedDetails = relatedEntityDetails?.data?.rows?.filter(
            (f) => f.invId == find._id
          );
        }
        let noOfShares = 0;
        await asyncForEach(findedDetails, async (oneDoc) => {
          if (oneDoc.noOfShares) {
            noOfShares += Number(oneDoc.noOfShares);
          }
        });
        let totalShares = noOfShares + value.numberOfShares;

        const data = {
          masterId: find._id,
          name: find.name ?? find.firstName,
          masterType: find.masterType,
          totalShares: noOfShares + value.numberOfShares,
        };
        if (totalShares) {
          let percentage =
            (Number(totalShares) / Number(entityData.authorisedShares)) * 100;
          data.percentage = `${Math.round(Number(percentage) * 100) / 100} %`;
        }
        console.log(data, "rtyuio");

        setReceiveFormData((prev) => ({
          ...prev,
          ...data,
        }));
      } else {
        console.warn("No match found in receiverArray", { value, fields });
      }
    }
    if (fields == "numberOfShares") {
      let noShares = value.numberOfShares ?? 0;

      if (Number(noShares) <= Number(senderFormData.sharesHeld)) {
        if (noShares) {
          value.totalShares =
            Number(senderFormData.sharesHeld) - Number(noShares) == 0
              ? 0
              : Number(senderFormData.sharesHeld) - Number(noShares);

          //  percentage;

          let percentage =
            (Number(noShares) / Number(senderFormData.sharesHeld)) * 100;
          value.percentage = `${Math.round(Number(percentage) * 100) / 100} %`;
        }
      } else {
        // 10-01-2025
        // sendNotify(
        //   "error",
        //   "Requested shares exceed the Individual's allocated total."
        // );
      }
    }
  };
  const allocationOnChange = async (value, fields) => {
    if (!value || !fields) {
      console.warn("Value or fields is missing", { value, fields });
      return;
    }

    if (fields === "masterId" || fields === "name") {
      let find = null;

      if (fields === "name") {
        find = receiverArray.find((e) => e.name === value?.name);
        if (!find) {
          find = receiverArray.find((e) => e.firstName === value?.name);
        }
      } else {
        find = receiverArray.find((e) => e._id === value.masterId);
      }

      if (find) {
        console.log(find, "Matched receiver");
        const data = {
          masterId: find._id,
          name: find.name ?? find.firstName,
          masterType: find.masterType,
        };
        setAllocationFormData((prev) => ({
          ...prev,
          ...data,
        }));
      } else {
        console.warn("No match found in receiverArray", { value, fields });
      }
    }
  };
  const addNewChange = (values, checkStatus, name) => {
    setUboChecked(checkStatus);
    updateChecked("ubo", checkStatus);

    setNomineeChecked(false);

    let arr = [...individualFormSchema];
    removeArrItem(nomineeShareholder, arr);
    if (checkStatus) {
      arr.push(...values);
    } else {
      removeArrItem(values, arr);
    }
    setIndividualFormSchema(arr);
  };

  const addNewRow = (values, checkStatus, name) => {
    updateChecked(name, checkStatus);
    let arr = [...individualFormSchema];

    if (checkStatus) {
      removeArrItem(values, arr);
    }

    if (name == "directors") {
      if (checkStatus) {
        arr.push(...values);
        setDirectorChecked(true);
        setReserveChecked(false);
      } else {
        removeArrItem(values, arr);
        setDirectorChecked(false);
      }
    }
    if (name == "reserve") {
      if (checkStatus) {
        arr.push(...values);
        setReserveChecked(true);
        setDirectorChecked(false);
      } else {
        removeArrItem(values, arr);
        setReserveChecked(false);
      }
    }
    setIndividualFormSchema(arr);
  };

  const addNewCustom = (values, name, checkStatus) => {
    let arr = [...individualFormSchema];
    let individualData = { ...formData };

    updateChecked(name, checkStatus);

    if (name == "shareholders") {
      if (checkStatus) {
        arr.push(...values);
        setShareholderChecked(true);
        setNomineeChecked(false);
        removeArrItem(nomineeShareholder, arr);
      } else {
        setShareForm({
          show: false,
          type: "",
        });
        setFormData({
          ...individualData,
          shareEntry: null,
          nomineeShareEntry: null,
        });
        removeArrItem(values, arr);
        setShareholderChecked(false);
      }
    }

    if (name == "nominee") {
      if (checkStatus) {
        arr.push(...values);
        setNomineeChecked(true);
        setShareholderChecked(false);
        setUboChecked(false);
        removeArrItem(shareholderFormSchema, arr);
        removeArrItem(uboFormSchema, arr);
      } else {
        setShareForm({
          show: false,
          type: "",
        });
        setFormData({
          ...individualData,
          shareEntry: null,
          nomineeShareEntry: null,
        });
        removeArrItem(values, arr);
        setNomineeChecked(false);
      }
    }
    setIndividualFormSchema(arr);
  };

  function updateChecked(key, checked) {
    let obj = { ...checkedList };
    if (key == "nominee" && checked) {
      obj["shareholders"] = false;
    }
    if (key == "shareholders" && checked) {
      obj["nominee"] = false;
    }
    obj[key] = checked;
    setCheckedList(obj);
  }

  const toggleHistory = (index) => {
    let arr = [...historyShow];
    let item = arr.findIndex((hs) => hs === index);
    if (item !== -1) {
      arr.splice(item, 1);
    } else {
      arr.push(index);
    }
    setHistoryShow(arr);
  };

  const senderForm = [
    {
      name: "masterId",
      value: "",
      type: "text",
      label: "Master file Code",
      placeholder: "Enter master file code",
      disabled: true,
    },
    {
      name: "name",
      value: "",
      type: "text",
      label: "Master file Name",
      placeholder: "Enter master file Name",
      disabled: true,
    },
    {
      name: "shareCapital",
      value: "",
      type: "text",
      label: "Authorised Share Capital",
      placeholder: "Enter authorised share Capital",
      disabled: true,
    },
    {
      name: "numberOfShares",
      value: "",
      type: "number",
      label: "Number of shares transferred",
      placeholder: "Enter number of shares transferred",
    },
    {
      name: "from",
      value: "",
      type: "text",
      label: "From",
      //  placeholder: "Enter Date (YYYY-MM-DD)",
      //  format: "YYYY-MM-DD",
    },
    {
      name: "to",
      value: "",
      type: "text",
      label: "To",
      // placeholder: "Enter Date (YYYY-MM-DD)",
      // format: "YYYY-MM-DD",
    },
    {
      name: "shareType",
      type: "select",
      options: [
        {
          value: "Ordinary Shares",
          label: "Ordinary Shares",
        },
        {
          value: "Preference Shares",
          label: "Preference Shares",
        },
        {
          value: "Non-Voting Shares",
          label: "Non-Voting Shares",
        },
        {
          value: "Shares Without Par Value",
          label: "Shares Without Par Value",
        },
      ],
      label: "Share type",
      placeholder: "Select",
    },
    {
      name: "parValue",
      value: "",
      type: "number",
      label: "Par Value",
      placeholder: "Enter par value",
    },
    {
      name: "currency",
      value: "",
      type: "select",
      showSearch: true,
      options: currency,
      label: "Currency",
      placeholder: "Enter currency",
    },

    {
      name: "effectiveDate",
      value: "",
      type: "date",
      label: "Effective date",
      placeholder: "Enter Date (YYYY-MM-DD)",
      format: "YYYY-MM-DD",
      upcoming: true,
    },
    {
      name: "ceasedDate",
      value: "",
      type: "date",
      label: "Ceased date",
      placeholder: "Enter Date (YYYY-MM-DD)",
      format: "YYYY-MM-DD",
    },
    {
      name: "totalShares",
      value: "",
      type: "number",
      label: "Total Shares held",
      placeholder: "Enter total shares held",
    },
    {
      name: "percentage",
      value: "",
      type: "text",
      label: "Ownership Percentage",
      placeholder: "Enter ownership percentage",
      disabled: true,
    },
    {
      name: "certificateNumber",
      value: "",
      type: "text",
      label: "Certificate Number",
      placeholder: "Enter certificate number",
    },
    {
      name: "createdAt",
      value: "",
      type: "date",
      label: "Date of entry",
      placeholder: "Enter Date (YYYY-DD-MM)",
      format: "YYYY-DD-MM",
      disabled: true,
    },
    {
      name: "comment",
      value: "",
      type: "textarea",
      label: "Comment",
      placeholder: "Enter comment/details",
      customClass: "col-md-10",
    },
  ];

  const receiverForm = [
    {
      name: "masterId",
      value: "",
      type: "select",
      label: "Master file Code",
      placeholder: "Enter master file code",
    },
    {
      name: "name",
      value: "",
      type: "select",
      label: "Master file Name",
      placeholder: "Enter master file Name",
    },
    {
      name: "shareCapital",
      value: "",
      type: "text",
      label: "Authorised Share Capital",
      placeholder: "Enter authorised share Capital",
      disabled: true,
    },
    {
      name: "numberOfShares",
      value: "",
      type: "number",
      label: "Number of shares received",
      placeholder: "Enter Number of shares received",
      disabled: true,
    },
    {
      name: "from",
      value: "",
      type: "text",
      label: "From",
      //  placeholder: "Enter Date (YYYY-MM-DD)",
      // format: "YYYY-MM-DD",
      disabled: true,
    },
    {
      name: "to",
      value: "",
      type: "text",
      label: "To",
      // placeholder: "Enter Date (YYYY-MM-DD)",
      // format: "YYYY-MM-DD",
      disabled: true,
    },
    {
      name: "effectiveDate",
      value: "",
      type: "date",
      label: "Effective date",
      placeholder: "Enter Date (YYYY-MM-DD)",
      format: "YYYY-MM-DD",
      upcoming: true,
    },
    {
      name: "totalShares",
      value: "",
      type: "number",
      label: "Total Shares held",
      placeholder: "Enter total shares held",
      upcoming: true,
      disabled: true,
    },
    {
      name: "percentage",
      value: "",
      type: "text",
      label: "Ownership Percentage",
      placeholder: "Enter ownership percentage",
      disabled: true,
    },
    {
      name: "certificateNumber",
      value: "",
      type: "text",
      label: "Certificate Number",
      placeholder: "Enter certificate number",
    },
    {
      name: "createdAt",
      value: "",
      type: "date",
      label: "Date of entry",
      placeholder: "Enter Date (YYYY-MM-DD)",
      format: "YYYY-MM-DD",
      disabled: true,
    },
  ];

  const allocationForm = [
    {
      name: "masterId",
      value: "",
      type: "select",
      label: "Master file Code",
      placeholder: "Enter master file code",
      disabled: true,
    },
    {
      name: "name",
      value: "",
      type: "select",
      label: "Master file Name",
      placeholder: "Enter master file Name",
      disabled: true,
    },
    {
      name: "shareCapital",
      value: "",
      type: "text",
      label: "Authorised Share Capital",
      placeholder: "Enter authorised share Capital",
      disabled: true,
    },
    {
      name: "numberOfShares",
      value: "",
      type: "number",
      label: "Number of shares allocated",
      placeholder: "Enter number of shares allocated",
    },
    {
      name: "from",
      value: "",
      type: "text",
      label: "From",
      placeholder: "Enter number of From",
    },
    {
      name: "to",
      value: "",
      type: "text",
      label: "To",
      placeholder: "Enter number of To",
    },
    {
      name: "shareType",
      type: "select",
      options: [
        {
          value: "Ordinary Shares",
          label: "Ordinary Shares",
        },
        {
          value: "Preference Shares",
          label: "Preference Shares",
        },
        {
          value: "Non-Voting Shares",
          label: "Non-Voting Shares",
        },
        {
          value: "Shares Without Par Value",
          label: "Shares Without Par Value",
        },
      ],
      label: "Share type",
      placeholder: "Select",
    },
    {
      name: "parValue",
      value: "",
      type: "number",
      label: "Par Value",
      placeholder: "Enter par value",
    },
    {
      name: "currency",
      value: "",
      type: "select",
      showSearch: true,
      options: currency,
      label: "Currency",
      placeholder: "Enter currency",
    },

    {
      name: "effectiveDate",
      value: "",
      type: "date",
      label: "Effective date",
      placeholder: "Enter Date (YYYY-MM-DD)",
      format: "YYYY-MM-DD",
      upcoming: true,
    },
    {
      name: "ceasedDate",
      value: "",
      type: "date",
      label: "Ceased date",
      placeholder: "Enter Date (YYYY-MM-DD)",
      format: "YYYY-MM-DD",
    },

    {
      name: "totalShares",
      value: "",
      type: "number",
      label: "Total Shares held",
      placeholder: "Enter total shares held",
      disabled: true,
    },
    {
      name: "percentage",
      value: "",
      type: "text",
      label: "Ownership Percentage",
      placeholder: "Enter ownership percentage",
      disabled: true,
    },
    {
      name: "certificateNumber",
      value: "",
      type: "text",
      label: "Certificate Number",
      placeholder: "Enter certificate number",
    },

    {
      name: "createdAt",
      value: "",
      type: "date",
      label: "Date of entry",
      placeholder: "Enter Date (YYYY-MM-DD)",
      format: "YYYY-MM-DD",
    },
    {
      name: "comment",
      value: "",
      type: "textarea",
      label: "Comment",
      placeholder: "Enter comment/details",
      customClass: "col-md-10",
    },
  ];
  const findOneIndividual = async (value) => {
    setLoading(true);
    let ctr = {};
    if (value.code) {
      if (value.code.startsWith("INV")) {
        ctr.code = value.code;
      } else {
        ctr.id = value.code;
      }
    } else {
      ctr = value;
    }

    let params = convertQueryParams(ctr);
    let payload = {
      method: "GET",
      url: `/individual?${params}`,
    };
    let data = await fetchApi(payload).then((res) => {
      return res?.data?.rows?.[0];
    });
    if (data._id) {
      setFormData((prev) => ({
        ...prev,
        ...data,
      }));
    }
    setLoading(false);
  };

  const getReceiverList = async () => {
    const fetchData = async (url) => {
      const payload = { method: "GET", url };
      return fetchApi(payload).then((res) => res?.data?.rows);
    };
    const params = convertQueryParams({
      isAll: true,
      alltype: ["individual", "cop-entity"],
      options: true,
    });
    const receiverList = await fetchData(`/client?${params}`);

    const paramsName = convertQueryParams({
      isAll: true,
      alltype: ["individual", "cop-entity"],
      exportAll: true,
    });
    const receiverName = await fetchData(`/client?${paramsName}`);
    setReceiverArray(receiverName);
    let namelist = receiverName
      ? receiverName?.map((d) => {
          let obj = {
            value: d?.firstName ?? d?.name,

            label: d?.name
              ? d?.name
              : ` ${
                  d?.firstName && d?.lastName
                    ? `${d?.firstName} ${
                        d?.middleName && d?.middleName !== "."
                          ? d?.middleName + " "
                          : ""
                      }${d?.lastName}`
                    : ""
                }`,
          };
          return obj;
        })
      : [];

    let data = {
      name: namelist,
      masterId: receiverList,
    };
    setReceiverOptions(data);
  };
  const individualOnChange = async (value, field) => {
    let obj = { ...shareForm };
    let individualData = { ...formData };

    if (
      field == "shareEntry" ||
      field == "nomineeShareEntry" ||
      field == "sharesHeld"
    ) {
      setFormData({
        ...individualData,
        shareEntry: value[field],
        nomineeShareEntry: value[field],
      });
      obj["show"] = true;
      if (value[field] == "Share Transfer") {
        obj["type"] = "transfer";
        setShareForm(obj);
      }
      if (value[field] == "New Allocation") {
        obj["type"] = "new";
        setShareForm(obj);
      }

      setSenderFormData({
        name: ` ${
          formData.firstName && formData.lastName
            ? `${formData.firstName} ${
                formData.middleName && formData.middleName !== "."
                  ? formData.middleName + " "
                  : ""
              }${formData.lastName}`
            : ""
        }`,
        shareCapital: entityData?.authorisedShares,
        masterId: formData._id,
        createdAt: new Date(),
      });

      let percentage =
        (Number(value.sharesHeld) / Number(entityData?.authorisedShares)) * 100;
      percentage = `${Math.round(Number(percentage) * 100) / 100} %`;
      setAllocationFormData({
        name: ` ${
          formData.firstName && formData.lastName
            ? `${formData.firstName} ${
                formData.middleName && formData.middleName !== "."
                  ? formData.middleName + " "
                  : ""
              }${formData.lastName}`
            : ""
        }`,
        shareCapital: entityData?.authorisedShares,
        totalShares: value.sharesHeld,
        numberOfShares: value.sharesHeld,
        percentage: percentage,
        masterId: formData._id,
        createdAt: new Date(),
      });
      getReceiverList();
    }
    if (
      !["title", "nationality", "code", "middleName", "lastName"].includes(
        field
      )
    ) {
      let data = { ...objData };
      if (
        data.totalSharesHeld !== value.totalSharesHeld ||
        !data.totalSharesHeld
      ) {
        let noShares = value.totalSharesHeld ?? 0;
        if (noShares <= entityData?.authorisedShares) {
          let percentage =
            (Number(value.totalSharesHeld ? value.totalSharesHeld : 0) /
              Number(entityData?.authorisedShares)) *
            100;
          value.ownershipPercentage = `${Math.round(percentage * 100) / 100}`;
          value._id = formData?._id ?? "";
          value.totalSharesHeld = value.totalSharesHeld;
          setFormData((prev) => ({
            ...prev,
            ...value,
          }));
        } else {
          sendNotify(
            "error",
            "Requested shares exceed the entity's allocated total."
          );
        }
      }
    } else {
      let {
        totalSharesHeld,
        code,
        firstName,
        lastName,
        middleName,
        nationality,
        title,
      } = value;

      let param = {};
      if (field == "code" && !totalSharesHeld) {
        findOneIndividual({ code: code });

        if (code.startsWith("INV")) {
          param.code = code;
        } else {
          param.id = code;
        }
      }

      if (field == "firstName") {
        findOneIndividual({ firstName: firstName });
        param.firstName = firstName;
      }
      if (field == "lastName") {
        findOneIndividual({ lastName: lastName });
        param.lastName = lastName;
      }
      if (field == "middleName") {
        findOneIndividual({ middleName: middleName });
        param.middleName = middleName;
      }
      if (field == "nationality") {
        param.nationality = nationality;
      }
      if (field == "title") {
        findOneIndividual({ title: title });
        param.title = title;
      }

      //handleindividualChange(param);

      setObjData(value);
    }
  };

  const shareTableColumn = [
    {
      title: "Action type",
      dataIndex: "transactionType",
      sorter: (a, b) => a.transactionType - b.transactionType,
    },
    {
      title: "Master file Code",
      dataIndex: "senderMasterId",
      sorter: (a, b) => a.code - b.code,
    },
    {
      title: "Share Type",
      dataIndex: "shareType",
      sorter: (a, b) => a.shareType - b.shareType,
    },
    {
      title: "Authorised Share Capital",
      dataIndex: "sharesHeld",
      sorter: (a, b) => a.shareCapital - b.shareCapital,
    },
    {
      title: "From",
      dataIndex: "from",
      sorter: (a, b) => a.from - b.from,
    },
    {
      title: "To",
      dataIndex: "to",
      sorter: (a, b) => a.to - b.to,
    },
    {
      title: "Par Value",
      dataIndex: "parValue",
      sorter: (a, b) => a.parValue - b.parValue,
    },
    {
      title: "Effective date",
      dataIndex: "effectiveDate",
      sorter: (a, b) => a.effectiveDate - b.effectiveDate,
      format: "YYYY-MM-DD",
    },
    {
      title: "Total Shares held",
      dataIndex: "sharesHeld",
      sorter: (a, b) => a.totalShares - b.totalShares,
    },
    {
      title: "Ownership Percentage",
      dataIndex: "percentage",
      sorter: (a, b) => a.percentage - b.percentage,
    },
    {
      title: "Certificate Number",
      dataIndex: "shareNumber",
      sorter: (a, b) => a.shareNumber - b.shareNumber,
    },
    {
      title: "Date of entry",
      dataIndex: "createdAt",
      sorter: (a, b) => a.createdAt - b.createdAt,
    },
    {
      title: "Comment",
      render: (_, { _id, comment }, index) => (
        <Popover
          className="custom-popover"
          placement="rightBottom"
          content={<p className="mb-0">{comment}</p>}
        >
          <button className="primary-btn">View</button>
        </Popover>
      ),
    },
  ];

  // const shareTableData = [
  //   {
  //     type: "Share Transfer",
  //     code: "INV000001",
  //     name: "Vaheeshan",
  //     shareCapital: "N/A",
  //     numberOfShares: 200,
  //     from: "20-10-2022",
  //     to: "20-10-2024",
  //     parValue: "N/A",
  //     effectiveDate: "20-10-2026",
  //     totalShares: 800,
  //     percentage: 20,
  //     certificateNumber: "FJC038932",
  //     createdAt: "30-10-2024",
  //   },
  //   {
  //     type: "Share Transfer",
  //     code: "INV000001",
  //     name: "Vaheeshan",
  //     shareCapital: "N/A",
  //     numberOfShares: 200,
  //     from: "20-10-2022",
  //     to: "20-10-2024",
  //     parValue: "N/A",
  //     effectiveDate: "20-10-2026",
  //     totalShares: 800,
  //     percentage: 20,
  //     certificateNumber: "FJC038932",
  //     createdAt: "30-10-2024",
  //   },
  //   {
  //     type: "New Allocation",
  //     code: "INV000001",
  //     name: "Vaheeshan",
  //     shareCapital: "N/A",
  //     numberOfShares: 200,
  //     from: "20-10-2022",
  //     to: "20-10-2024",
  //     parValue: "N/A",
  //     effectiveDate: "20-10-2026",
  //     totalShares: 800,
  //     percentage: 20,
  //     certificateNumber: "FJC038932",
  //     createdAt: "30-10-2024",
  //   },
  // ];

  const handleindividualChange = async (value) => {
    let query = convertQueryParams(value);

    let payload = {
      method: "get",
      url: `/individual?${query}`,
    };
    let individualData = await fetchApi(payload).then((res) => {
      return res?.data;
    });

    if (individualData?.rows.length) {
      const firstName = individualData?.rows.map((d) => ({
        value: d.firstName,
        label: d.firstName,
      }));
      const middleName = individualData?.rows.map((d) => ({
        value: d.middleName,
        label: d.middleName,
      }));
      const lastName = individualData?.rows.map((d) => ({
        value: d.lastName,
        label: d.lastName,
      }));
      let data = {
        firstName: firstName,
        middleName: middleName,
        lastName: lastName,
      };
      setIndividualOptions(data);
    } else {
      setFormData({});
    }
    if (individualData?.rows.length) {
      setFormData((prev) => ({
        ...prev,
        ...individualData?.rows[0],
      }));
    }
  };

  const saveOnClick = () => {
    saveData(selectedId);
  };

  const saveData = async () => {
    let transaction = {};
    if (formData.shareEntry == "Share Transfer") {
      let receiverFormSchema = receiverFormRef.current.getPayload();
      let senderFormSchema = senderFormRef.current.getPayload();
      if (receiverFormSchema.masterId) {
        transaction = {
          receiverformFields: {
            masterId: receiverFormSchema.masterId,
            masterType: receiveFormData.masterType,
            shareCapital: receiverFormSchema.shareCapital,
            numberOfShares: receiverFormSchema.numberOfShares,
            from: receiverFormSchema.from,
            to: receiverFormSchema.to,
            effectiveDate: receiverFormSchema.effectiveDate,
            totalShares: receiverFormSchema.totalShares,
            percentage: receiverFormSchema.percentage,
            certificateNumber: receiverFormSchema.certificateNumber,
          },
          senderFormFields: {
            masterId: senderFormSchema.masterId,
            masterType: "individual",
            shareCapital: senderFormSchema.shareCapital,
            numberOfShares: senderFormSchema.numberOfShares,
            from: senderFormSchema.from,
            to: senderFormSchema.to,
            effectiveDate: senderFormSchema.effectiveDate,
            totalShares: senderFormSchema.totalShares,
            percentage: senderFormSchema.percentage,
            certificateNumber: senderFormSchema.certificateNumber,
            shareType: senderFormSchema.shareType,
            parValue: senderFormSchema.parValue,
            comment: senderFormSchema.comment,
            currency: senderFormSchema.currency,
            ceasedDate: senderFormSchema.ceasedDate,
          },
        };
      } else {
        sendNotify("error", "plz select receiver");
      }
    } else if (formData.shareEntry == "New Allocation") {
      let allocationFormSchema = allocationFormRef.current.getPayload();
      transaction = {
        allocationformFields: {
          masterId: allocationFormSchema.masterId,
          masterType: "individual",
          shareCapital: allocationFormSchema.shareCapital,
          numberOfShares: allocationFormSchema.numberOfShares,
          from: allocationFormSchema.from,
          to: allocationFormSchema.to,
          effectiveDate: allocationFormSchema.effectiveDate,
          totalShares: allocationFormSchema.totalShares,
          percentage: allocationFormSchema.percentage,
          certificateNumber: allocationFormSchema.certificateNumber,
          shareType: allocationFormSchema.shareType,
          parValue: allocationFormSchema.parValue,
          comment: allocationFormSchema.comment,
          currency: allocationFormSchema.currency,
          ceasedDate: allocationFormSchema.ceasedDate,
        },
      };
      // (transaction.allocationFormSchema = allocationFormSchema);
    }

    const prepareData = () => ({
      invId: formData._id,
      enId: entityData._id,
      resignation: formData.dateResignation,
      appointment: formData.dateAppointment,
      noOfShares: formData.sharesHeld || formData.nomineeSharesHeld,
      totalShares: formData.totalSharesHeld,
      ownershipPer: formData.ownershipPercentage,
      agreementDate: formData.nomineeAgreementDate,
      shareEntry: formData.shareEntry,
      subType: "Direct Entry",
      checkedList: checkedList,
      transaction: transaction,
    });

    const handleCheckedList = () => {
      const organizationArray = organizationStructure.data.rows.filter(
        (a) => a.pid == null
      );
      const fullName = `${formData.title} ${formData.firstName} ${
        formData.middleName || ""
      } ${formData.lastName}`
        .replace(/\s+/g, " ")
        .trim();

      const createObj = (role, shares, percentage = null) => {
        const roleData = organizationArray.find((i) => i.role === role);
        return roleData
          ? {
              name: fullName,
              pid: roleData._id,
              color: roleData.color,
              role: "Individual",
              pType: role,
              shares,
              percentage,
            }
          : null;
      };

      const objArray = [];
      if (checkedList.directors || checkedList.reserve) {
        objArray.push({
          ...createObj("Director"),
          reserveDirector: checkedList.reserve,
        });
      }
      if (checkedList.shareholders || checkedList.nominee) {
        const sharePercentage = `${Math.round(
          (Number(formData.sharesHeld) / Number(entityData?.authorisedShares)) *
            100
        )}%`;
        objArray.push(
          createObj("Shareholder", formData.sharesHeld, sharePercentage)
        );
      }
      if (checkedList.ubo) {
        objArray.push(
          createObj(
            "UBO",
            formData.totalSharesHeld,
            formData.ownershipPercentage
          )
        );
      }

      return objArray.filter(Boolean); // Filter out any null values
    };

    const handleApiPost = async (url, data) => {
      try {
        const response = await fetchApi({ method: "POST", url, data });
        return response;
      } catch (error) {
        sendNotify("error", error?.message);
        throw error;
      }
    };
    const handleApiPut = async (url, data) => {
      try {
        const response = await fetchApi({ method: "PUT", url, data });
        return response;
      } catch (error) {
        sendNotify("error", error?.message);
        throw error;
      }
    };
    const processRows = async (objArray) => {
      for (const obj of objArray) {
        await handleApiPost(`/organizationStructure`, obj);
      }
    };

    if (formData._id) {
      const data = prepareData();
      if (Object.values(checkedList).some(Boolean)) {
        try {
          const response = selectedId
            ? await handleApiPut(
                `/individualDetails/update/${selectedId}`,
                data
              )
            : await handleApiPost(`/individualDetails`, data);
          if (response?.status == 208) {
            sendNotify("error", response?.message);
          } else {
            sendNotify("success", response?.message);
            closeModalOnClick();
            setCheckedList({
              directors: false,
              shareholders: false,
              ubo: false,
              nominee: false,
              reserve: false,
            });
          }
          if (organizationStructure?.data?.rows?.length) {
            const objArray = handleCheckedList();
            await processRows(objArray);
          } else {
            const params = { ...queryParams, enId: id || query.id };
            dispatch(getIndividualDetails(params));
            dispatch(getOrganizationStructure(params));
          }
        } catch (error) {
          sendNotify("error", error?.message);
        }

        const params = { ...queryParams, enId: id || query.id };
        dispatch(getIndividualDetails(params));
      } else {
        sendNotify("error", "Please enter at least one name");
      }
    } else {
      sendNotify("error", "Please enter at least one name");
    }
  };

  const individualDropDownOnClick = async (value) => {
    let params = {};
    params.isAll = true;
    params.masterType = "individual";
    params.code = value;
    let query = convertQueryParams(params);
    let payload = {
      method: "GET",
      url: `/client?${query}`,
    };
    let clientData = await fetchApi(payload).then((res) => {
      return res?.data;
    });
    setIndividualData(clientData.rows);
    let list = clientData.rows.map((d) => {
      let obj = {
        value: d?._id,
        label: d?.code,
      };
      return obj;
    });

    setIndividualArray(list);
  };
  const openIndividualModal = (value) => {
    setIndividualShow(value);
  };
  const individualModalOnClick = () => {
    setSelectedId("");
    setUboChecked(false);
    setDirectorChecked(false);
    setReserveChecked(false);
    setShareholderChecked(false);
    setNomineeChecked(false);
    setFormData({});
    setOpenindividual(true);
  };
  const closeModalOnClick = () => {
    setSelectedId("");
    setObjData({});
    setUboChecked(false);
    setDirectorChecked(false);
    setReserveChecked(false);
    setShareholderChecked(false);
    setNomineeChecked(false);
    setFormData({});
    setOpenindividual(false);
    setCheckedList({
      directors: false,
      shareholders: false,
      ubo: false,
      nominee: false,
      reserve: false,
    });
    setShareForm({
      show: false,
      type: "",
    });
    clearFormRef();
    clearArrItem();
  };
  const getPagination = (current, pageSize) => {
    setTableLoading(true);
    let params = { ...queryParams };
    params._start = (current - 1) * pageSize;
    params._limit = pageSize;
    params.enId = id ? id : query.id;
    dispatch(getIndividualDetails(params));
    setQueryParams(params);
    setTableLoading(false);
  };
  const handleRefreshTransaction = async (data) => {
    console.log(data, "drtyfuihoj");
    try {
      const fetchData = async (url) => {
        const payload = { method: "GET", url };
        const response = await fetchApi(payload);
        return response?.data?.rows || [];
      };

      const clientParams = convertQueryParams({
        isAll: true,
        options: true,
      });
      const clientData = await fetchData(`/client?${clientParams}`);
      console.log(clientData, "clientData");

      const clientDataMap = new Map(
        clientData.map((item) => [item.value, item.label])
      );

      const transformedResponse = {
        rows: data.map((row) => ({
          ...row,
          senderMasterId: row.senderForm?.length
            ? clientDataMap.get(row.senderForm[0]?.masterId)
            : row.allocationForm?.length
            ? clientDataMap.get(row.allocationForm[0]?.masterId)
            : null,

          shareNumber: row.senderForm?.length
            ? row.senderForm[0]?.certificateNumber
            : row.receiverForm?.length
            ? row.receiverForm[0]?.certificateNumber
            : row.allocationForm?.length
            ? row.allocationForm[0]?.certificateNumber
            : null,

          noShares: row.senderForm?.length
            ? row.senderForm[0]?.numberOfShares
            : row.receiverForm?.length
            ? row.receiverForm[0]?.numberOfShares
            : row.allocationForm?.length
            ? row.allocationForm[0]?.numberOfShares
            : null,

          sharesHeld: row.senderForm?.length
            ? row.senderForm[0]?.shareCapital
            : row.receiverForm?.length
            ? row.receiverForm[0]?.shareCapital
            : row.allocationForm?.length
            ? row.allocationForm[0]?.shareCapital
            : null,

          parValue: row.senderForm?.length
            ? row.senderForm[0]?.parValue
            : row.receiverForm?.length
            ? row.receiverForm[0]?.parValue
            : row.allocationForm?.length
            ? row.allocationForm[0]?.parValue
            : null,

          ceasedDate: row.senderForm?.length
            ? row.senderForm[0]?.ceasedDate
            : row.receiverForm?.length
            ? row.receiverForm[0]?.ceasedDate
            : row.allocationForm?.length
            ? row.allocationForm[0]?.ceasedDate
            : null,

          effectiveDate: row.senderForm?.length
            ? row.senderForm[0]?.effectiveDate
            : row.receiverForm?.length
            ? row.receiverForm[0]?.effectiveDate
            : row.allocationForm?.length
            ? row.allocationForm[0]?.effectiveDate
            : null,

          totalShares: row.senderForm?.length
            ? row.senderForm[0]?.totalShares
            : row.receiverForm?.length
            ? row.receiverForm[0]?.totalShares
            : row.allocationForm?.length
            ? row.allocationForm[0]?.totalShares
            : null,

          percentage: row.senderForm?.length
            ? row.senderForm[0]?.percentage
            : row.receiverForm?.length
            ? row.receiverForm[0]?.percentage
            : row.allocationForm?.length
            ? row.allocationForm[0]?.percentage
            : null,

          comment: row.allocationForm?.length
            ? row.allocationForm[0]?.comment
            : null,

          currency: row.allocationForm?.length
            ? row.allocationForm[0]?.currency
            : null,
          from: row.senderForm?.length
            ? row.senderForm[0]?.from
            : row.receiverForm?.length
            ? row.receiverForm[0]?.from
            : row.allocationForm?.length
            ? row.allocationForm[0]?.from
            : null,
          to: row.senderForm?.length
            ? row.senderForm[0]?.to
            : row.receiverForm?.length
            ? row.receiverForm[0]?.to
            : row.allocationForm?.length
            ? row.allocationForm[0]?.to
            : null,
          shareType: row.senderForm?.length
            ? row.senderForm[0]?.shareType
            : row.receiverForm?.length
            ? row.receiverForm[0]?.shareType
            : row.allocationForm?.length
            ? row.allocationForm[0]?.shareType
            : null,

          combinedForm: {
            sender: row.senderForm || [],
            receiver: row.receiverForm || [],
            allocation: row.allocationForm || [],
          },
        })),
      };
      console.log(transformedResponse.rows, "weryxtuyiuoi");
      setShareTableData(transformedResponse.rows);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const individualEditModalOnClick = async (id, index) => {
    // setSelectedIndex(index + 1);
    setSelectedIndex(individualDetails.data.totalCount - index);
    setSelectedId(id);

    const details = individualDetails.data.rows.find((e) => e._id === id);
    if (!details) return;

    const params = {
      isAll: true,
      masterType: "individual",
      code: details.individualCode,
    };
    const query = convertQueryParams(params);
    const payload = {
      method: "GET",
      url: `/client?${query}`,
    };

    try {
      const clientData = await fetchApi(payload).then(
        (res) => res?.data?.rows || []
      );
      let arr = [...individualFormSchema];
      setIndividualData(clientData);
      setIndividualArray(
        clientData.map((d) => ({ value: d?._id, label: d?.code }))
      );

      const finded = clientData.find((e) => e._id === details.individualId);
      const { shareholders, ubo, nominee, directors, reserve } =
        details.checkedList;
      console.log(finded, "dwertyuio");
      if (finded) {
        const newItem = {
          ...finded,
          pep: finded.details.pep,
          sanction: finded.details.sanction,
          pepText: finded.details.pepText,
          sanctionText: finded.details.sanctionText,
          riskRating: finded.details.riskRating,
          address: { ...finded.details.address },
          sharesHeld: finded.relatedDetailData[0].noOfShares,
          dateResignation: finded.relatedDetailData[0].resignation,
          dateAppointment: finded.relatedDetailData[0].appointment,
          totalSharesHeld: finded.relatedDetailData[0].totalShares,

          ownershipPercentage: finded.relatedDetailData[0].ownershipPer,
        };
        setFormData(newItem);
        setObjData(newItem);
      }

      setUboChecked(ubo);
      setDirectorChecked(directors);
      setReserveChecked(reserve);
      setShareholderChecked(shareholders);
      setNomineeChecked(nominee);
      setOpenindividual(true);
      // setShareForm({ show: false, type: "" });
      if (details.transaction) {
        handleRefreshTransaction(details.transaction);
      }
      if (directors) {
        addNewRow(directorFormSchema, directors, "directors");
        arr.push(...directorFormSchema);
      }
      if (reserve) {
        addNewRow(directorFormSchema, reserve, "reserve");
        arr.push(...directorFormSchema);
      }
      if (ubo) {
        addNewChange(uboFormSchema, ubo, "ubo");
        arr.push(...uboFormSchema);
      }
      if (shareholders) {
        addNewCustom(shareholderFormSchema, "shareholders", shareholders);
        arr.push(...shareholderFormSchema);
      }
      if (nominee) {
        addNewCustom(nomineeShareholder, "nominee", nominee);
        arr.push(...nomineeShareholder);
      }
      setIndividualFormSchema(arr);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const clearForm = () => {
    setFindId(null);
    setFormData({});
    setObjData({});
    setUboChecked(false);
    setDirectorChecked(false);
    setReserveChecked(false);
    setShareholderChecked(false);
    setNomineeChecked(false);
    setShareForm({
      show: false,
      type: "",
    });
    setCheckedList({
      directors: false,
      shareholders: false,
      ubo: false,
      nominee: false,
      reserve: false,
    });
    clearFormRef();
    clearArrItem();
  };
  const searchChange = (value) => {
    if (value) {
      let ctr = {};
      ctr.id = value;
      findOneIndividual(ctr);
      setFindId(value);
      setObjData(value);
    } else {
      setFindId(null);
    }
  };
  const clearArrItem = () => {
    let arr = [...individualFormSchema];
    const schemas = [
      nomineeShareholder,
      directorFormSchema,
      shareholderFormSchema,
      uboFormSchema,
    ];

    schemas.forEach((schema) => removeArrItem(schema, arr));
    setIndividualFormSchema(arr);
  };

  const clearFormRef = () => {
    try {
      if (receiverFormRef.current) receiverFormRef?.current?.clearForm();
      if (allocationFormRef.current) allocationFormRef?.current?.clearForm();
      if (senderFormRef.current) senderFormRef?.current?.clearForm();
      if (individualFormRef.current) individualFormRef?.current?.clearForm();
    } catch (err) {
      console.log(err, "Error clearing form references");
      return err;
    }
  };
  return (
    <>
      <div className="d-flex py-4 gap-3 align-items-center">
        <Switch
          className="custom-switch"
          onChange={(value) => openIndividualModal(value)}
        />
        <h4 className="primary-title">Individual</h4>
      </div>
      {individualShow ? (
        <div key={index} id={`individual${index}`}>
          <button
            className="primary-btn ms-auto mb-3"
            onClick={individualModalOnClick}
          >
            <i className="fal fa-plus-circle"></i>Add New
          </button>
          <div className="border-box">
            <Spin spinning={tableLoading}>
              <Table
                className="custom-table-light mt-md-1 mb-3"
                columns={individualcolumns}
                dataSource={individualDetails?.data?.rows}
                //  pagination={false}
                scroll={{ x: 1200 }}
                pagination={{
                  className: "custom-pagination",
                  defaultCurrent: 1,
                  pageSize: queryParams?._limit,
                  total: individualDetails?.data?.totalCount,
                  onChange: getPagination,
                }}
              />
            </Spin>
            <Modal
              title={selectedId ? "Edit Individual " : "Add New Individual"}
              okText={selectedId ? "Update" : "Save"}
              className="custom-modal"
              open={openindividual}
              centered={true}
              width={1100}
              onOk={saveOnClick}
              onCancel={closeModalOnClick}
              // footer={false}
            >
              {!selectedId ? (
                <div className="custom-form row mb-3 mb-md-4 gy-3">
                  <div className="d-flex gap-2">
                    <div className="container mt-4">
                      <div className="d-flex align-items-center justify-content-between">
                        <h4 className="primary-title ">Individual</h4>
                        <button className="primary-btn" onClick={clearForm}>
                          Clear Form
                        </button>
                      </div>
                      <div className="search-bar d-flex p-3 bg-light shadow-sm rounded">
                        <Select
                          className="custom-select w-100"
                          placeholder={m.code || "Select Code"}
                          value={findId}
                          onChange={(value) => searchChange(value)}
                          options={individualArray}
                          showSearch={true}
                          allowClear={true}
                          filterOption={(input, option) =>
                            option.label
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        />
                        &nbsp;
                        <Select
                          className="custom-select w-100"
                          placeholder={m.name || "Select Name"}
                          value={findId}
                          onChange={(value) => searchChange(value)}
                          options={fullNameArray}
                          showSearch={true}
                          allowClear={true}
                          filterOption={(input, option) =>
                            option.label
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="d-flex gap-3 mb-3 align-items-center">
                <div className="d-flex gap-3 mb-3 align-items-center">
                  <h3 className="sub-title">
                    {!selectedId
                      ? ` Individual 0${individualDetails?.data?.count + 1}`
                      : `Individual 0${selectedIndex}`}{" "}
                    &nbsp;
                    <i className="fal fa-question-circle"></i>
                  </h3>

                  <Checkbox
                    className="custom-checkbox"
                    checked={directorChecked && !reserveChecked}
                    onChange={(e) =>
                      addNewRow(
                        directorFormSchema,
                        e.target.checked,
                        "directors"
                      )
                    }
                    disabled={JSON.stringify(objData) == "{}"}
                  >
                    Directors
                  </Checkbox>
                  <Checkbox
                    className="custom-checkbox"
                    checked={shareholderChecked && !nomineeChecked}
                    onChange={(e) =>
                      addNewCustom(
                        shareholderFormSchema,
                        "shareholders",
                        e.target.checked
                      )
                    }
                    disabled={JSON.stringify(objData) == "{}"}
                  >
                    Shareholders
                  </Checkbox>
                  <Checkbox
                    className="custom-checkbox"
                    onChange={(e) =>
                      addNewChange(uboFormSchema, e.target.checked, "ubo")
                    }
                    checked={uboChecked}
                    disabled={JSON.stringify(objData) == "{}"}
                  >
                    UBOs
                  </Checkbox>
                  <Checkbox
                    className="custom-checkbox"
                    checked={nomineeChecked && !shareholderChecked}
                    onChange={(e) =>
                      addNewCustom(
                        nomineeShareholder,
                        "nominee",
                        e.target.checked
                      )
                    }
                    // disabled={objData.code ? false : true}
                    disabled={JSON.stringify(objData) == "{}"}
                  >
                    Nominee Shareholder
                  </Checkbox>
                  <Checkbox
                    className="custom-checkbox"
                    checked={reserveChecked && !directorChecked}
                    onChange={(e) =>
                      addNewRow(directorFormSchema, e.target.checked, "reserve")
                    }
                    disabled={JSON.stringify(objData) == "{}"}
                  >
                    Reserve Director
                  </Checkbox>
                </div>
              </div>
              <Spin spinning={loading}>
                <FormZ
                  ref={(el) => (individualFormRef.current[index] = el)}
                  formSchema={individualFormSchema}
                  formData={formData}
                  // formOptions={{ code: individualArray, ...individualOptions }}
                  onChange={individualOnChange}
                  onKeyUp={(e) => individualDropDownOnClick(e.target.value)}
                  formClass="row custom-form mb-3 gy-3 gx-4"
                  childClass="col-md-3 mb-0"
                  labelClass="mb-1"
                  inputClass="w-100"
                />
                {shareholderChecked ? (
                  <Popover
                    className="custom-popover"
                    placement="top"
                    content={
                      selectedCategory
                        ? renderSubOptions()
                        : renderMainOptions()
                    }
                  >
                    <button className="primary-btn" aria-label="Share Entry">
                      Share Entry
                    </button>
                  </Popover>
                ) : null}
                {shareForm["show"] && (
                  <div>
                    {shareForm["type"] == "transfer" ? (
                      <div>
                        <div className="primary-title mb-2">
                          Transferor (Sender)
                        </div>
                        <FormZ
                          ref={senderFormRef}
                          formSchema={senderForm}
                          onChange={senderOnChange}
                          formData={senderFormData}
                          formClass="row custom-form mb-3 gy-3 gx-4"
                          childClass="col-md-3 mb-0"
                          labelClass="mb-1"
                          inputClass="w-100"
                        />
                        <div className="primary-title mb-2">
                          Transferee (Receiver)
                        </div>
                        <FormZ
                          ref={receiverFormRef}
                          formSchema={receiverForm}
                          onChange={receiverOnChange}
                          formOptions={receiverOptions}
                          formData={receiveFormData}
                          formClass="row custom-form mb-3 gy-3 gx-4"
                          childClass="col-md-3 mb-0"
                          labelClass="mb-1"
                          inputClass="w-100"
                        />
                      </div>
                    ) : (
                      <div>
                        <div className="primary-title mb-2">New Allocation</div>
                        <FormZ
                          ref={allocationFormRef}
                          formSchema={allocationForm}
                          onChange={allocationOnChange}
                          formData={allocationFormData}
                          formOptions={receiverOptions}
                          formClass="row custom-form mb-3 gy-3 gx-4"
                          childClass="col-md-3 mb-0"
                          labelClass="mb-1"
                          inputClass="w-100"
                        />
                      </div>
                    )}
                    {selectedId ? (
                      <>
                        <div className="primary-title mb-2">
                          Detailed History
                        </div>
                        <Table
                          className="custom-table-light mt-3 mb-3"
                          columns={shareTableColumn}
                          dataSource={shareTableData}
                          scroll={{ x: 2000 }}
                          pagination={{
                            className: "custom-pagination",
                          }}
                        />
                      </>
                    ) : null}
                  </div>
                )}
              </Spin>
            </Modal>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
