import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal, Checkbox, Spin } from "antd";

import SampleDoc from "../assets/pdf/sample.pdf";

import UploadZ from "../components/UploadZ";

import { Each, getQuery, fetchApi, sendNotify } from "../helper";

const document = [
  {
    url: SampleDoc,
    file: "Other",
    name: "sample-doc.pdf",
    size: 287392,
    type: "pdf",
    base64: "",
    isApproved: true,
  },
  {
    url: SampleDoc,
    file: "Other",
    name: "sample-doc.pdf",
    size: 287392,
    type: "pdf",
    base64: "",
    isApproved: false,
  },
  {
    url: SampleDoc,
    file: "Other",
    name: "sample-doc.pdf",
    size: 287392,
    type: "pdf",
    base64: "",
    isApproved: false,
  },
];

export default function Other() {
  let query = getQuery();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [docs, setDocs] = useState(document);
  const [explainModel, setExplainModel] = useState(false);
  const [rejectModel, setRejectModel] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    handleRefresh();
  }, []);

  const handleRefresh = () => {
    setLoading(true);
    const payload = {
      method: "get",
      url: `/serviceRequest?id=${query?.id}`,
    };

    fetchApi(payload)
      .then((res) => {
        setData(res?.data?.rows?.[0]);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
        sendNotify("error", "Failed to load data");
      });
  };

  return (
    <div>
      <div className="page-header">
        <Link to={"/app/active-requests"}>
          <h1 className="d-flex align-items-center gap-2">
            <i className="fal fa-long-arrow-left"></i> Other
          </h1>
        </Link>
        <button className="primary-btn">Update Entity Overview</button>
      </div>
      <Spin spinning={loading}>
        <div className="page-content">
          <div className="custom-form row gy-4 mb-5">
            <div className="col-md-3">
              <label htmlFor="">Entity Code</label>
              <span className="light-badge">{data?.entityCode ?? "N/A"}</span>
            </div>
            <div className="col-md-3">
              <label htmlFor="">Entity Name</label>
              <span className="light-badge">{data?.entityName ?? "N/A"}</span>
            </div>
            <div className="col-md-3">
              <label htmlFor="">Client Code</label>
              <span className="light-badge">{data?.clientCode ?? "N/A"}</span>
            </div>
            <div className="col-md-3">
              <label htmlFor="">Client Name</label>
              <span className="light-badge">{data?.clientName ?? "N/A"}</span>
            </div>
            <div className="col-md-3">
              <label htmlFor="">Service Type(s)</label>
              {/* <div className="d-flex gap-2 flex-wrap">
                <span className="light-badge">Reinstatement</span>
                <span className="light-badge">Document Certifications</span>
              </div> */}
            </div>
          </div>
          <div className="row custom-form gy-2 gx-4">
            <Each
              array={data?.documents}
              render={(doc, index) => (
                <div className="col-md-3">
                  <div>
                    <label htmlFor="">Other</label>
                    <UploadZ file={doc} />
                  </div>
                </div>
              )}
            />
          </div>
        </div>
      </Spin>
    </div>
  );
}
