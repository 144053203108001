import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal, Checkbox, Spin } from "antd";

import SampleDoc from "../assets/pdf/sample.pdf";

import UploadZ from "../components/UploadZ";
import RequestResubmission from "../components/RequestResubmission";

import { Each, getQuery, fetchApi, sendNotify } from "../helper";

const document = [
  {
    url: SampleDoc,
    file: "Liquidation Plan",
    name: "sample-doc.pdf",
    size: 287392,
    type: "pdf",
    base64: "",
    isApproved: true,
  },
  {
    url: SampleDoc,
    file: "Statement of Assets and Liabilities",
    name: "sample-doc.pdf",
    size: 287392,
    type: "pdf",
    base64: "",
    isApproved: false,
  },
  {
    url: SampleDoc,
    file: "Blank Indmenity Agreement",
    name: "sample-doc.pdf",
    size: 287392,
    type: "pdf",
    base64: "",
    isApproved: false,
  },
  {
    url: SampleDoc,
    file: "CP New Engagement Application Form",
    name: "sample-doc.pdf",
    size: 287392,
    type: "pdf",
    base64: "",
    isApproved: false,
  },
  {
    url: SampleDoc,
    file: "Declaration of Solvency",
    name: "sample-doc.pdf",
    size: 287392,
    type: "pdf",
    base64: "",
    isApproved: false,
  },
  {
    url: SampleDoc,
    file: "Notice of Appointment of Liquidator",
    name: "sample-doc.pdf",
    size: 287392,
    type: "pdf",
    base64: "",
    isApproved: false,
  },
  {
    url: SampleDoc,
    file: "Notice of Appointment Confirmation",
    name: "sample-doc.pdf",
    size: 287392,
    type: "pdf",
    base64: "",
    isApproved: false,
  },
  {
    url: SampleDoc,
    file: "Written Resolution of the Shareholders",
    name: "sample-doc.pdf",
    size: 287392,
    type: "pdf",
    base64: "",
    isApproved: false,
  },
  {
    url: SampleDoc,
    file: "Written Resolutions of the Directors",
    name: "sample-doc.pdf",
    size: 287392,
    type: "pdf",
    base64: "",
    isApproved: false,
  },
  {
    url: SampleDoc,
    file: "New Procedures - Voluntary Liquidations",
    name: "sample-doc.pdf",
    size: 287392,
    type: "pdf",
    base64: "",
    isApproved: false,
  },
];

export default function SolventLiquidation() {
  let query = getQuery();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [docs, setDocs] = useState(document);
  const [explainModel, setExplainModel] = useState(false);
  const [rejectModel, setRejectModel] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    handleRefresh();
  }, []);

  const handleRefresh = () => {
    setLoading(true);
    const payload = {
      method: "get",
      url: `/serviceRequest?id=${query?.id}`,
    };

    fetchApi(payload)
      .then((res) => {
        let data = res?.data?.rows?.[0];
        setData(data);
        setDocs(data?.documents);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
        sendNotify("error", "Failed to load data");
      });
  };

  const getRejectDocs = (checked, docId) => {
    let arr = [...docs];
    let index = arr.findIndex((doc) => doc._id === docId);
    if (checked) {
      arr[index]["rejected"] = true;
    } else {
      delete arr[index]["rejected"];
    }
    setDocs(arr);
  };

  const handleOk = () => {
    setExplainModel(false);
    setRejectModel(false);
  };

  const handleCancel = () => {
    setExplainModel(false);
    setRejectModel(false);
  };

  const onChangeApproval = (file, bool) => {
    let arr = [...docs];
    let index = arr.findIndex((doc) => doc.file == file);
    arr[index].isApproved = bool;

    setDocs(arr);
  };

  return (
    <div>
      <div className="page-header">
        <Link to={"/app/active-requests"}>
          <h1 className="d-flex align-items-center gap-2">
            <i className="fal fa-long-arrow-left"></i> Solvent Liquidation
          </h1>
        </Link>
        <div className="d-flex gap-3">
          <RequestResubmission id={query?.id} documents={docs} />
          <button className="outline-btn">Save</button>
          <button className="primary-btn">Submit</button>
        </div>
      </div>
      <Spin spinning={loading}>
        <div className="page-content">
          <div className="custom-form row gy-4 mb-4">
            <div className="col-md-3">
              <label htmlFor="">Entity Code</label>
              <span className="light-badge">{data?.entityCode ?? "N/A"}</span>
            </div>
            <div className="col-md-3">
              <label htmlFor="">Entity Name</label>
              <span className="light-badge">{data?.entityName ?? "N/A"}</span>
            </div>
            <div className="col-md-3">
              <label htmlFor="">Client Code</label>
              <span className="light-badge">{data?.clientCode ?? "N/A"}</span>
            </div>
            <div className="col-md-3">
              <label htmlFor="">Client Name</label>
              <span className="light-badge">{data?.clientName ?? "N/A"}</span>
            </div>
          </div>

          <div className="row gy-2 gx-4">
            <Each
              array={data?.documents}
              render={(doc, index) => (
                <div className="col-md-3" key={index}>
                  <div>
                    <Checkbox
                      className="custom-checkbox"
                      onChange={(e) =>
                        getRejectDocs(e.target.checked, doc?._id)
                      }
                    >
                      {doc?.document}
                    </Checkbox>
                    <UploadZ
                      file={doc}
                      downloadOnly={true}
                      showApprove={true}
                      onFileApprove={onChangeApproval}
                      showTranslation={true}
                      showExtraction={true}
                    />
                  </div>
                </div>
              )}
            />
          </div>
        </div>
      </Spin>
      <Modal
        className="custom-modal-2"
        open={rejectModel}
        width={800}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Reject"
        okButtonProps={{ className: "reject-btn" }}
        cancelButtonProps={{ className: "d-none" }}
      >
        <div className="mt-4 pt-2">
          <h6 className="input-title">Reject Request</h6>
          <p className="description mb-3">
            Select reason(s) as to why this request should be rejected.
          </p>
          <Checkbox className="custom-checkbox py-1 font-md">
            Incomplete or inaccurate information provided in the documents
          </Checkbox>
          <Checkbox className="custom-checkbox py-1 font-md">
            Insufficient supporting documentation or evidence
          </Checkbox>
          <Checkbox className="custom-checkbox py-1 font-md">
            The requesting entity does not have the legal authority to do so
          </Checkbox>
          <Checkbox className="custom-checkbox py-1 font-md">
            Lack of authorization or signature from the appropriate authority
          </Checkbox>
          <Checkbox className="custom-checkbox py-1 font-md">
            Failure to pay required fees or charges associated with the request
          </Checkbox>
          <Checkbox className="custom-checkbox py-1 font-md">
            Issues with the authenticity or validity of the requesting entity or
            individuals involved
          </Checkbox>
          <Checkbox className="custom-checkbox py-1 font-md">
            Suspected fraud or misrepresentation in the application
          </Checkbox>
        </div>
      </Modal>
    </div>
  );
}
