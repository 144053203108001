import React, { useRef, useState, useEffect } from "react";
import { Table } from "antd"; // Assuming you're using Ant Design for the Modal and Table
import FormZ from "./FormZ"; // Adjust the import path for FormZ
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import html2pdf from "html2pdf.js";
import useRiskAssessment from "../hooks/useRiskAssessment";
import { useSelector } from "react-redux";
import { calculateLevelOfRisk } from "../helper";
import PDFControls from "./PDFControls";

const defaultFormData = {
  entityId: "",
  countryRiskCountry: "",
  countryRiskCPI: "",
  countryRiskBasel: "",
  countryRiskFatfGrey: false,
  countryRiskSanction: "",
  typeOfEntity: "INDIVIDUAL",
  overallRiskScore: 0,
  overallRiskRating: "LOW",
  preparedDate: "",
  countryRiskScore: 0,
  customerRiskScore: 0,
  sourceofFundsRiskScore: 0,
  businessRiskScore: 0,
  businessStructureScore: 0,
  preparedBy: "",
  approvedBy: "",
  approvedDate: "",
};

export default function IndividualRAF({
  personalDataFormData,
  personalDataOnChange,
  countryRiskFromData,
  countryRiskDtaOnChange,
  overallRiskFromData,
  overallRiskDataOnChange,
}) {
  const personalDetailFormRef = useRef();
  const countryRiskFormRef = useRef();
  const overallRiskFormRef = useRef();
  const pdfRef = useRef();
  const formRef = useRef();
  const form2Ref = useRef();
  const { userDetails } = useSelector((state) => state.auth);
  const [formData, setFormData] = useState(defaultFormData);
  const { data: riskAssessmentData, saveData } = useRiskAssessment();
  const [overallRiskScore, setOverallRiskScore] = useState(0);
  const [overallRiskRating, setOverallRiskRating] = useState("LOW");

  const handleScoreChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));

    calculateOverallScore();
  };

  const calculateOverallScore = () => {
    const total =
      (parseInt(formData?.customerRiskScore) || 0) +
      (parseInt(formData?.countryRiskScore) || 0) +
      (parseInt(formData?.sourceofFundsRiskScore) || 0) +
      (parseInt(formData?.businessRiskScore) || 0) +
      (parseInt(formData?.businessStructureRiskScore) || 0);

    // setFormData((prevFormData) => ({
    //   ...prevFormData,
    //   overallRiskScore: total,
    //   preparedBy: riskAssessmentData?.preparedBy || "",
    //   preparedDate: riskAssessmentData?.preparedDate || "",
    //   approvedBy: riskAssessmentData?.approvedBy || "",
    //   approvedDate: riskAssessmentData?.approvedDate || "",
    //   // countryRiskCountry: riskAssessmentData.countryRiskCountry || "",
    //   // countryRiskCPI: riskAssessmentData.countryRiskCPI || "",
    //   // countryRiskBasel: riskAssessmentData.countryRiskBasel || "",
    //   // countryRiskFatfGrey:true,
    //   // countryRiskSanction: riskAssessmentData?.countryRiskSanction || "",
    // }));

    console.log(`Overall Risk Score: ${total}`);
    setOverallRiskScore(total);
    setOverallRiskRating(calculateLevelOfRisk(total));
    overallRiskDataOnChange({
      ...overallRiskFromData,
      overallRiskScore: total.toString(),
      overallRiskRating: calculateLevelOfRisk(total),
      preparedBy: riskAssessmentData?.preparedBy || "",
      preparedDate: riskAssessmentData?.preparedDate || "",
      approvedBy: riskAssessmentData?.approvedBy || "",
      approvedDate: riskAssessmentData?.approvedDate || "",
    });
  };

  const handleCountryRiskChange = (newData) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      ...newData,
    }));
    countryRiskDtaOnChange({
      ...countryRiskFromData,
      countryRiskCountry: newData.countryRiskCountry,
      countryRiskCPI: newData.countryRiskCPI,
      countryRiskBasel: newData.countryRiskBasel,
      countryRiskFatfGrey: newData.countryRiskFatfGrey,
      countryRiskSanction: newData?.countryRiskSanction,
    });
  };

  const businesss_structure_columns = [
    {
      title:
        "Businesss Structure  (Individual Employment/Professional Activities, Entrepreneur etc.)",
      dataIndex: "name",
    },
    {
      title: "Score",
      dataIndex: "score",
      align: "center",
      render: (_, { _id, score }) => (
        <div className="custom-form">
          <input
            type="number"
            className="w-80px"
            defaultValue={score || 1}
            placeholder="Enter count"
            min={1}
            onChange={(e) => {
              if (!form2Ref.current) form2Ref.current = {};
              if (!form2Ref.current[_id]) form2Ref.current[_id] = {};
              form2Ref.current[_id] = {
                ...form2Ref.current[_id],
                score: parseInt(e.target.value) || 1,
              };
            }}
          />
        </div>
      ),
    },
  ];
  const country_risk_columns = [
    {
      title: "Country Risk",
      dataIndex: "name",
    },
    {
      title: "Score",
      dataIndex: "score",
      align: "center",
      render: (_, { _id, score }) => (
        <div className="custom-form">
          <input
            type="number"
            className="w-80px"
            defaultValue={score || 1}
            placeholder="Enter count"
            min={1}
            onChange={(e) => {
              if (!formRef.current) formRef.current = {};
              if (!formRef.current[_id]) formRef.current[_id] = {};
              form2Ref.current[_id] = {
                ...formRef.current[_id],
                score: parseInt(e.target.value) || 1,
              };
            }}
          />
        </div>
      ),
    },
  ];
  const businesss_risk_columns = [
    {
      title:
        "Businesss Risk (Individual Employment/Professional Activities, Entrepreneur etc.)",
      dataIndex: "name",
    },
    {
      title: "Score",
      dataIndex: "score",
      align: "center",
      render: (_, { _id, score }) => (
        <div className="custom-form">
          <input
            type="number"
            className="w-80px"
            defaultValue={score || 1}
            placeholder="Enter count"
            min={1}
            onChange={(e) => {
              if (!form2Ref.current) form2Ref.current = {};
              if (!form2Ref.current[_id]) form2Ref.current[_id] = {};
              form2Ref.current[_id] = {
                ...form2Ref.current[_id],
                score: parseInt(e.target.value) || 1,
              };
            }}
          />
        </div>
      ),
    },
  ];

  const customer_risk_columns = [
    {
      title: "Customer Risk",
      dataIndex: "name",
    },
    {
      title: "Score",
      dataIndex: "score",
      align: "center",
      render: (_, { _id, score }) => (
        <div className="custom-form">
          <input
            type="number"
            className="w-80px"
            defaultValue={score || 1}
            placeholder="Enter count"
            min={1}
            onChange={(e) => {
              if (!form2Ref.current) form2Ref.current = {};
              if (!form2Ref.current[_id]) form2Ref.current[_id] = {};
              form2Ref.current[_id] = {
                ...form2Ref.current[_id],
                score: parseInt(e.target.value) || 1,
              };
            }}
          />
        </div>
      ),
    },
  ];
  const source_of_funds_columns = [
    {
      title: "Source of funds",
      dataIndex: "name",
    },
    {
      title: "Score",
      dataIndex: "score",
      align: "center",
      render: (_, { _id, score }) => (
        <div className="custom-form">
          <input
            type="number"
            className="w-80px"
            defaultValue={score || 1}
            placeholder="Enter count"
            min={1}
            onChange={(e) => {
              if (!form2Ref.current) form2Ref.current = {};
              if (!form2Ref.current[_id]) form2Ref.current[_id] = {};
              form2Ref.current[_id] = {
                ...form2Ref.current[_id],
                score: parseInt(e.target.value) || 1,
              };
            }}
          />
        </div>
      ),
    },
  ];

  let overallRiskFormSchema = [
    {
      name: "overallRiskScore",
      value: "",
      type: "text",
      label: "Overall Risk Score",
      placeholder: "Enter Overall Risk Score",
      disabled: true,
    },
    {
      name: "overallRiskRating",
      value: "",
      type: "select",
      label: "Overall Risk Rating",
      placeholder: "Select Overall Risk Rating",
      options: [
        { value: "Low", label: "Low" },
        { value: "Medium", label: "Medium" },
        { value: "High", label: "High" },
      ],
      disabled: true,
    },
    {
      name: "preparedBy",
      value: "",
      type: "text",
      label: "Prepared By",
      placeholder: "Enter Prepared By",
      disabled: true,
    },
    {
      name: "preparedDate",
      value: "",
      type: "date",
      label: "Prepared Date",
      placeholder: "Enter Date (YYYY-MM-DD)",
      disabled: true,
    },
    {
      name: "approvedBy",
      value: "",
      type: "text",
      label: "Approved By",
      placeholder: "Enter Approved By",
      disabled: true,
    },

    {
      name: "approvedDate",
      value: "",
      type: "date",
      label: "Approved date",
      placeholder: "Enter Date (YYYY-MM-DD)",
      disabled: true,
    },
  ];
  let personalDetailFormSchema = [
    {
      name: "firstName",
      value: "",
      type: "text",
      label: "First Name",
      placeholder: "Enter first name",
      disabled: true,
    },
    {
      name: "middleName",
      value: "",
      type: "text",
      label: "Middle Name",
      placeholder: "Enter middle name",
      disabled: true,
    },
    {
      name: "lastName",
      value: "",
      type: "text",
      label: "Last Name",
      placeholder: "Enter last name",
      disabled: true,
    },
    {
      name: "DOB",
      value: "",
      type: "date",
      label: "Date of Birth",
      placeholder: "Enter date of birth",
      disabled: true,
    },
    {
      name: "designation",
      value: "",
      type: "text",
      label: "Designation",
      placeholder: "Enter designation",
      disabled: true,
    },
    {
      name: "nationality",
      value: "",
      type: "text",
      label: "Nationality",
      placeholder: "Enter nationality",
      disabled: true,
    },
    {
      name: "idType",
      value: "",
      type: "text",
      label: "ID Type",
      placeholder: "Enter ID type",
      disabled: true,
    },
    {
      name: "idNo",
      value: "",
      type: "text",
      label: "ID No",
      placeholder: "Enter ID number",
      disabled: true,
    },
    {
      name: "idIssue",
      value: "",
      type: "date",
      label: "Issue Date",
      placeholder: "Enter issue date",
      disabled: true,
    },
    {
      name: "idExp",
      value: "",
      type: "date",
      label: "Expiry Date",
      placeholder: "Enter expiry date",
      disabled: true,
    },
    {
      name: "placeOfBirth",
      value: "",
      type: "text",
      label: "Place of Birth",
      placeholder: "Enter place of birth",
      disabled: true,
    },
    {
      name: "address",
      value: "",
      type: "textarea",
      label: "Address",
      customClass: "col-md-5",
      placeholder: "Enter address",
      disabled: true,
    },
  ];
  let countryRiskFormSchema = [
    {
      name: "countryRiskCountry",
      value: "",
      type: "text",
      label: "Country",
      placeholder: "Enter country",
    },
    {
      name: "countryRiskCPI",
      value: "",
      type: "text",
      label: "CPI",
      placeholder: "Enter CPI",
    },
    {
      name: "countryRiskBasel",
      value: "",
      type: "text",
      label: "Basel",
      placeholder: "Enter Basel",
    },
    {
      name: "countryRiskFatfGrey",
      value: "",
      type: "select",
      label: "Black Listed/Grey Listed",
      placeholder: "Select FATF",
      options: [
        { value: "YES", label: "YES" },
        { value: "NO", label: "NO" },
      ],
    },
    {
      name: "countryRiskSanction",
      value: "",
      type: "text",
      label: "Sanction",
      placeholder: "Enter sanction",
    },
  ];
  const riskLevels = [
    {
      label: "Low Risk",
      value: "0-40",
      bgColor: "rgba(76, 248, 76, 0.62)",
      headerBgColor: "rgba(76, 248, 76, 0.82)",
    },
    {
      label: "Medium Risk",
      value: "41-70",
      bgColor: "rgba(240, 128, 128, 0.7)",
      headerBgColor: "rgba(240, 128, 128, 0.88)",
    },
    {
      label: "High Risk",
      value: "71-100",
      bgColor: "rgba(255, 0, 0, 0.63)",
      headerBgColor: "rgba(255, 0, 0, 0.84)",
    },
  ];

  const customerRiskData = [
    {
      id: 1,
      name: "Regulated Person / Individuals on Government employment or pensions",
      score: 4,
      colName: "customerRiskScore",
    },
    {
      id: 2,
      name: "Non-Regulated Person / Face to Face domestic",
      score: 8,
      colName: "customerRiskScore",
    },
    {
      name: "Non-Regulated Person / Non Face to Face",
      score: 12,
      colName: "customerRiskScore",
    },
    {
      id: 3,
      name: "NPO Charity / High Cash / Individuals with complex trust or company ownership structures that could be intended to hide the identity of the ultimate beneficial owners (e.g. multi-tiered, groups, shell companies with nominee shareholders and/or directors, and companies with shares issued to bearer)",
      score: 16,
      colName: "customerRiskScore",
    },
    {
      id: 4,
      name: "Politically Exposed Person (Sanctioned Individual)",
      score: 20,
      colName: "customerRiskScore",
    },
  ];

  const countryRiskData = [
    {
      id: 1,
      name: "Recognized jurisdiction / Low Risk Countries as per Basel and CPI index / FATF Compliant",
      score: 4,
      colName: "countryRiskScore",
    },
    {
      id: 2,
      name: "Non FATF Compliant / Medium Risk as per Basel / CPI index",
      score: 8,
      colName: "countryRiskScore",
    },
    {
      id: 3,
      name: "High risk countries as per Basel and CPI Index / FATF Grey Listed",
      score: 12,
      colName: "countryRiskScore",
    },
    {
      id: 4,
      name: "Subject to EU/UN Sanction",
      score: 16,
      colName: "countryRiskScore",
    },
    {
      id: 5,
      name: "Terrorist Sponsored state / Unknown",
      score: 20,
      colName: "countryRiskScore",
    },
  ];

  const sourceOffundsData = [
    {
      id: 1,
      name: "Employment Income / Salary and Bonuses / Govt Assistance",
      score: 4,
      colName: "sourceofFundsRiskScore",
    },
    {
      id: 2,
      name: "Investments / Business Ownership",
      score: 8,
      colName: "sourceofFundsRiskScore",
    },
    {
      id: 3,
      name: "Gift / Inheritance",
      score: 12,
      colName: "sourceofFundsRiskScore",
    },
    {
      id: 4,
      name: "Loans or Debt / Cryptoassets",
      score: 16,
      colName: "sourceofFundsRiskScore",
    },
    {
      id: 5,
      name: "Trusts / Foundations",
      score: 20,
      colName: "sourceofFundsRiskScore",
    },
  ];

  const businesssRiskData = [
    {
      id: 1,
      name: "Regulated business / Companies listed on a recognised stock exchange.",
      score: 5,
      colName: "businessRiskScore",
    },
    {
      id: 2,
      name: "Non-Regulated business / Wholly owned subsidiaries of companies listed on a recognised stock exchange.",
      score: 10,
      colName: "businessRiskScore",
    },
    {
      id: 3,
      name: "High Risk Businesses",
      score: 20,
      colName: "businessRiskScore",
    },
  ];

  const businesssStructureData = [
    {
      id: 1,
      name: "Non high risk business",
      score: 5,
      colName: "businessStructureRiskScore",
    },
    {
      id: 2,
      name: "Complex Ownership structure",
      score: 10,
      colName: "businessStructureRiskScore",
    },
    {
      id: 3,
      name: "Non-transparent structure / opaque",
      score: 15,
      colName: "businessStructureRiskScore",
    },
    {
      id: 4,
      name: "Bearer shares / Unknown",
      score: 20,
      colName: "businessStructureRiskScore",
    },
  ];
  const generatePdf = () => {
    const element = pdfRef.current;
    const opt = {
      margin: 0.5,
      filename: "individualRAF.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 4, logging: true, dpi: 192, letterRendering: true },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };
    html2pdf().from(element).set(opt).save();
    return opt;
  };

  useEffect(() => {
    if (riskAssessmentData) {
      setFormData(riskAssessmentData);
      calculateOverallScore();
      countryRiskDtaOnChange({
        ...countryRiskFromData,
        countryRiskCountry: riskAssessmentData.countryRiskCountry || "",
        countryRiskCPI: riskAssessmentData.countryRiskCPI || "",
        countryRiskBasel: riskAssessmentData.countryRiskBasel || "",
        countryRiskFatfGrey: riskAssessmentData.countryRiskFatfGrey || "",
        countryRiskSanction: riskAssessmentData?.countryRiskSanction || "",
      });
    }
  }, [riskAssessmentData]);

  useEffect(() => {
    if (formData) {
      calculateOverallScore();
    }
  }, [formData]);

  useEffect(() => {
    if (personalDataFormData?._id) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        entityId: personalDataFormData._id,
      }));
    }
  }, [personalDataFormData]);
  const rowSelection = {
    type: "radio",
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );

      if (selectedRows.length > 0) {
        const record = selectedRows[0];
        handleScoreChange(record.colName, record.score);
      }
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };
  return (
    <div className="page-header">
      <div className="page-content">
        <div className="pdf-zoom-wrapper">
          <TransformWrapper
            initialScale={1}
            centerOnInit
            panning={{ excluded: ["input"] }}
          >
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
              <div>
                <PDFControls generatePdf={generatePdf} />
                <TransformComponent wrapperClass="w-100 pdf-transform">
                  <div className="w-100 border-box mb-4" ref={pdfRef}>
                    <h5 className="primary-title mb-4">Personal Details</h5>
                    <FormZ
                      ref={personalDetailFormRef}
                      formSchema={personalDetailFormSchema}
                      formData={personalDataFormData}
                      onChange={personalDataOnChange}
                      formClass="row custom-form mb-4 mb-md-5 gy-2 gx-4"
                      childClass="col-md-4 mb-0"
                      labelClass="mb-1"
                      inputClass="w-100"
                    />
                    <h5 className="primary-title mb-4">
                      Customer Risk Details
                    </h5>
                    <Table
                      className="custom-table mb3-"
                      columns={customer_risk_columns}
                      rowSelection={{ type: "radio", ...rowSelection }}
                      rowKey={"id"}
                      dataSource={customerRiskData}
                      scroll={{ x: 500 }}
                      pagination={false}
                    />
                    <div className="col-md-3 ms-auto">
                      <div className="input-box w-100">
                        <label htmlFor="score" className="mb-1">
                          Score
                        </label>
                        <input
                          id="score"
                          type="number"
                          className="w-100"
                          placeholder="Enter Score"
                          value={formData?.customerRiskScore}
                          // onChange={(e) =>
                          //   handleScoreChange(
                          //     "customerRiskScore",
                          //     e.target.value
                          //   )
                          // }
                          min="0"
                          max="20"
                          disabled={true}
                        />
                      </div>
                    </div>

                    <h5 className="primary-title mb-4">Country Risk</h5>
                    <FormZ
                      ref={countryRiskFormRef}
                      formSchema={countryRiskFormSchema}
                      formData={countryRiskFromData}
                      onChange={handleCountryRiskChange}
                      formClass="row custom-form mb-4 mb-md-5 gy-2 gx-4"
                      childClass="col-md-4 mb-0"
                      labelClass="mb-1"
                      inputClass="w-100"
                    />

                    <Table
                      className="custom-table mb3-"
                      columns={country_risk_columns}
                      dataSource={countryRiskData}
                      scroll={{ x: 500 }}
                      rowKey={"id"}
                      rowSelection={{ type: "radio", ...rowSelection }}
                      pagination={false}
                    />
                    <div className="col-md-3 ms-auto">
                      <div className="input-box w-100">
                        <label htmlFor="score" className="mb-1">
                          Score
                        </label>
                        <input
                          id="score"
                          type="number"
                          className="w-100"
                          placeholder="Enter Score"
                          value={formData?.countryRiskScore}
                          // onChange={(e) =>
                          //   handleScoreChange(
                          //     "countryRiskScore",
                          //     e.target.value
                          //   )
                          // }
                          disabled={true}
                          min="0"
                          max="20"
                        />
                      </div>
                    </div>

                    <Table
                      className="custom-table mb3-"
                      columns={source_of_funds_columns}
                      dataSource={sourceOffundsData}
                      rowKey={"id"}
                      rowSelection={{ type: "radio", ...rowSelection }}
                      scroll={{ x: 500 }}
                      pagination={false}
                    />
                    <div className="col-md-3 ms-auto">
                      <div className="input-box w-100">
                        <label htmlFor="score" className="mb-1">
                          Score
                        </label>
                        <input
                          id="score"
                          type="number"
                          className="w-100"
                          placeholder="Enter Score"
                          value={formData?.sourceofFundsRiskScore}
                          // onChange={(e) =>
                          //   handleScoreChange(
                          //     "sourceofFundsRiskScore",
                          //     e.target.value
                          //   )
                          // }
                          disabled={true}
                          min="0"
                          max="20"
                        />
                      </div>
                    </div>
                    <Table
                      className="custom-table mb3-"
                      columns={businesss_risk_columns}
                      rowKey={"id"}
                      rowSelection={{ type: "radio", ...rowSelection }}
                      dataSource={businesssRiskData}
                      scroll={{ x: 500 }}
                      pagination={false}
                    />
                    <div className="col-md-3 ms-auto">
                      <div className="input-box w-100">
                        <label htmlFor="score" className="mb-1">
                          Score
                        </label>
                        <input
                          id="score"
                          type="number"
                          className="w-100"
                          placeholder="Enter Score"
                          value={formData?.businessRiskScore}
                          // onChange={(e) =>
                          //   handleScoreChange(
                          //     "businessRiskScore",
                          //     e.target.value
                          //   )
                          // }
                          disabled={true}
                          min="0"
                          max="20"
                        />
                      </div>
                    </div>
                    <Table
                      className="custom-table mb3-"
                      columns={businesss_structure_columns}
                      rowKey={"id"}
                      dataSource={businesssStructureData}
                      rowSelection={{ type: "radio", ...rowSelection }}
                      scroll={{ x: 500 }}
                      pagination={false}
                    />
                    <div className="col-md-3 ms-auto">
                      <div className="input-box w-100">
                        <label htmlFor="score" className="mb-1">
                          Score
                        </label>
                        <input
                          id="score"
                          type="number"
                          className="w-100"
                          placeholder="Enter Score"
                          value={formData?.businessStructureRiskScore}
                          // onChange={(e) =>
                          //   handleScoreChange(
                          //     "businessStructureRiskScore",
                          //     e.target.value
                          //   )
                          // }
                          disabled={true}
                          min="0"
                          max="20"
                        />
                      </div>
                    </div>
                    <table className="risk-level-table-container">
                      <thead>
                        <tr>
                          {riskLevels.map((risk, index) => (
                            <th
                              key={index}
                              className={`table-header ${risk.label
                                .toLowerCase()
                                .replace(" ", "-")}`}
                            >
                              {risk.label}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {riskLevels.map((risk, index) => (
                            <td
                              key={index}
                              className={`table-cell ${risk.label
                                .toLowerCase()
                                .replace(" ", "-")}`}
                            >
                              {risk.value}
                            </td>
                          ))}
                        </tr>
                      </tbody>
                    </table>

                    <FormZ
                      ref={overallRiskFormRef}
                      formSchema={overallRiskFormSchema}
                      formData={overallRiskFromData}
                      onChange={overallRiskDataOnChange}
                      formClass="custom-form row gy-3"
                      childClass="col-md-4 mb-0"
                      labelClass="mb-1"
                      inputClass="w-100"
                    />
                    <div className="d-flex flex-wrap gap-3 mt-4 mb-5 mb-md-2">
                      <button
                        className="primary-btn"
                        onClick={() => {
                          saveData(formData);
                        }}
                      >
                        Update
                      </button>
                      {(userDetails.role === "lead" ||
                        userDetails.role === "admin") && (
                        <button
                          className="approvedButton"
                          onClick={() => {
                            setFormData((prevFormData) => {
                              const updatedFormData = {
                                ...prevFormData,
                                approvedBy: userDetails.userName,
                              };
                              saveData(updatedFormData);
                            });
                          }}
                        >
                          Approve
                        </button>
                      )}
                    </div>
                  </div>
                </TransformComponent>
              </div>
            )}
          </TransformWrapper>
        </div>
      </div>
    </div>
  );
}
