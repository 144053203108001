  import React from "react";

  const PDFControls = (props) => {
    const { zoomIn, zoomOut } = props;

    const downloadPDF = () => {
      if (props && props.generatePdf) {
        props.generatePdf();
      } else {
        console.error(
          "Error: Signature missing. Please provide a valid signature to continue."
        );
      }
    };

    return (
      <div className="control-tools d-flex gap-3">
        <button onClick={zoomIn}>
          <i className="far fa-search-plus"></i>
        </button>
        <button onClick={zoomOut}>
          <i className="far fa-search-minus"></i>
        </button>
        <button onClick={downloadPDF}>
          <i className="far fa-arrow-to-bottom"></i>
        </button>
      </div>
    );
  };

  export default PDFControls;