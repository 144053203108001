const extractDirectors = (details, isCopEntity = false) => {
    const directors = [];
  
    details.forEach((row) => {
      const { checkedList, individualName, appointment, copEntityName } = row;
  
      if (checkedList.directors) {
        directors.push({
          name: isCopEntity ? copEntityName : individualName,
          dateOfAppointment: appointment ? formatDate(appointment) : null
        });
      }
    });
  
    return directors;
  };
  
  const extractShareholders = (details, isCopEntity = false) => {
    const shareholders = [];
  
    details.forEach((row) => {
      const { checkedList, individualName, noOfShares, copEntityName } = row;
  
      if (checkedList.shareholders) {
        shareholders.push({
          name: isCopEntity ? copEntityName : individualName,
          shares: noOfShares || "",
        });
      }
    });
  
    return shareholders;
  };
  
  const formatDate = (dateString) => {
    const dateObj = new Date(dateString);
    const day = dateObj.getUTCDate().toString().padStart(2, '0'); // Add leading zero if single digit
    const month = dateObj.toLocaleString('default', { month: 'long' });
    const year = dateObj.getUTCFullYear();
    return `${day} ${month} ${year}`;
  };
  
  const createEmailTemplateParams = (
    registeredAgentName,
    registeredAgentAddress,
    entity,
    individualDetails,
    copEntityDetails,
    emailCategory,
    registerOfCharges,
  ) => {
    const directors = [
      ...extractDirectors(individualDetails),
      ...extractDirectors(copEntityDetails, true),
    ];
  
    const shareholders = [
      ...extractShareholders(individualDetails),
      ...extractShareholders(copEntityDetails, true),
    ];
  
    const dateObj = new Date(entity.incorporationDate || null);
    const currentDate = new Date(); // Current moment
    const signatureDate = currentDate.getUTCDate();
    const signatureMonth = currentDate.toLocaleString("default", { month: "long" });
    const signatureYear = currentDate.getUTCFullYear();

    return {
      registeredAgentName: registeredAgentName,
      registeredAgentAddress: registeredAgentAddress,
      companyName: entity.name,
      incorperatedIn: entity.jurisdiction,
      incorporationDate: dateObj.getUTCDate(),
      incorporationMonth: dateObj.toLocaleString("default", {
        month: "long",
      }),
      incorporationYear: dateObj.getUTCFullYear(),
      companyNumber: entity.incorporationNumber,
      registerOfCharges: registerOfCharges,
      directors: directors,
      shareholders: shareholders,
      signatureDate: signatureDate,
      signatureMonth: signatureMonth,
      signatureYear: signatureYear,
      authorizedSignatory: "",
      recieverEmail: entity.email,
      emailCategory:emailCategory,
      capNo:"",
      effectiveDate:"",
      effectiveMonth:"",
      effectiveYear:"",
      continueDate:"",
      continueMonth:"",
      continueYear:""
    };
  };
  
  export {
    extractDirectors,
    extractShareholders,
    formatDate,
    createEmailTemplateParams,
  };
  