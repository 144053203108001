import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Logo from "../assets/img/logo-icon.png";
import { languagesArray } from "../store/language/languageSlice";
import homeData from "../helper/translations/home.json";

export default function Home() {
  let { isLogged, userDetails } = useSelector((state) => state.auth);
  const { language } = useSelector((state) => state.language);
  const [selctedLanguage, setSelctedLanguage] = useState("");
  useEffect(() => {
    let index = languagesArray.find((tc) => tc.value == language);
    setSelctedLanguage(index.value);
  }, [language]);
  let t = homeData.translations[selctedLanguage] || homeData.translations.en;

  let list = [
    { pageName: "In-House Translation" },
    { pageName: "Manual Translation" },
    { pageName: "In-House OCR" },
    { pageName: "Manual OCR" },
    { pageName: "Storage" },
    { pageName: "OCR" },
    { pageName: "RAF" },
    { pageName: "Web Search" },
    { pageName: "Risk Analysis" },
    { pageName: "CDD Screen" },
    { pageName: "Document Review" },
    { pageName: "MLRO Sign Off Dashboard" },
    { pageName: "New Business Onboard" },
    { pageName: "Send to Approval" },
    { pageName: "Incorporation Request" },
    { pageName: "Active Requests" },
    { pageName: "Incorporation Confirmation" },
    { pageName: "Incorporation Feedback" },
    { pageName: "Incorporation Pack" },
    { pageName: "MLRO Sign Off" },
    { pageName: "New Incorporation" },
    { pageName: "Pending Information" },
    { pageName: "Post Incorp" },
    { pageName: "Pre-Incorp Supported Documents" },
    { pageName: "Entity Invoicing" },
    { pageName: "Entity Overview - Update" },
    { pageName: "Entity Overview - List" },
    { pageName: "Director Screen" },
    { pageName: "Shareholder" },
    { pageName: "Trust" },
    { pageName: "Partnership" },
    { pageName: "General Partnership" },
    { pageName: "Limited Partnership" },
    { pageName: "Parent Client" },
    { pageName: "Client" },
    { pageName: "Crypto KYC" },
    { pageName: "Finance" },
    { pageName: "Individual Analysis" },
    { pageName: "My Portfolio" },
    { pageName: "Master Search" },
    { pageName: "Periodic Review" },
    { pageName: "CorporateEntity Analysis" },
    { pageName: "Name Check" },
    { pageName: "New Incorporation" },
    { pageName: "New Incorporation Request" },
    { pageName: "New Incorporation Review" },
    { pageName: "New Incorporation ApprovalReq" },
    { pageName: "Initial MLRO Approval" },
    { pageName: "MLRO SignOff Request View" },
    { pageName: "Onboard Entity" },
    { pageName: "Create New TransferIn" },
    { pageName: "MLRO Approval" },
    { pageName: "New Incorporation Submission To Registry" },
    { pageName: "New BusinessOnboard" },
    { pageName: "Post Incorp Service Request" },
    { pageName: "OCR Extraction" },
    { pageName: "Translation" },
    { pageName: "Pending Resubmission" },
    { pageName: "Manual Storage" },
    { pageName: "Filings" },
    { pageName: "Invoicing" },
    { pageName: "FeeSchedule" },
    { pageName: "Audit Trail And Reporting" },
    { pageName: "Notifications" },
    { pageName: "CDD" },
    { pageName: "Mailbox" },
    { pageName: "ChatBox" },
    { pageName: "CryptoTM" },
    { pageName: "MasterFile Creation" },
    { pageName: "MasterFile Details" },
    { pageName: "Organization Structure" },
    { pageName: "Profile Settings" },
    { pageName: "User Management" },
    { pageName: "AddUser" },
    { pageName: "EditUser" },
    { pageName: "Manage Roles" },
    { pageName: "Account" },
    { pageName: "Business Partners" },
    { pageName: "AddBusiness Partner" },
    { pageName: "EditBusiness Partner" },
    { pageName: "Certificate Of Good Standing" },
    { pageName: "Change Of Director" },
    { pageName: "Change Of Shareholder" },
    { pageName: "Certificate Of Shares" },
    { pageName: "Alteration To MAA" },
    { pageName: "MAA Name Change" },
    { pageName: "Notarization Of Documents" },
    { pageName: "Apostille Of Documents" },
    { pageName: "Drafting Resolutions" },
    { pageName: "Corporate Documents" },
    { pageName: "TransferOut" },
    { pageName: "SolventLiquidation" },
    { pageName: "Reminders Notifications Setup" },
    { pageName: "Access Management" },
  ];

  return (
    <div className="page-content">
      {userDetails?.otherDetails?.companyName ? (
        <h1 className="page-title">
          {t.titlePrefix} -{" "}
          <span>{userDetails?.otherDetails?.companyName}</span>
        </h1>
      ) : null}
      <div className="welcome-section mt-4 mt-md-0">
        <div className="w-md-50 text-center">
          <img className="logo" src={Logo} alt="" />
          <h2>
            {t.welcome}, {userDetails?.userName || t.guest}
          </h2>
          <p>{t.description}</p>
        </div>
      </div>
    </div>
  );
}
