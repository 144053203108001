// Auth
import Login from "../pages/auth/login";
import Signup from "../pages/auth/signup";
import ResetPassword from "../pages/auth/reset-password";
import VerificationCode from "../pages/auth/verification-code";

//Private
import Home from "../pages/home";
import Incorporation from "../pages/incorporation";
import PostIncorp from "../pages/post-incorp";
import OnboardingTeamListing from "../pages/onboarding-team/listing";
import OnboardingTeamEdit from "../pages/onboarding-team/edit";
import OnboardingTeamDownload from "../pages/onboarding-team/download";
import OnboardingTeamUpload from "../pages/onboarding-team/upload";
import OnboardingTeamReviewList from "../pages/onboarding-team/review-listing";
import OnboardingTeamReviewUpload from "../pages/onboarding-team/review-upload";
import OnboardingTeamView from "../pages/onboarding-team/view";
import MasterFileCreation from "../pages/master-file/creation";
import MasterFileDetails from "../pages/master-file/details";
import PreIncorpResolution from "../pages/pre-incorp-resolution";
import RelationshipManager from "../pages/relationship-manager";
import ActiveRequests from "../pages/active-requests";
import MLRO from "../pages/mlro";
import NewIncorporation from "../pages/new-incorporation";
import IncorporationConfirmation from "../pages/incorporation-confirmation";
import IncorporationFeedback from "../pages/incorporation-feedback";
import EntityOverviewEdit from "../pages/entity-overview/edit";
import EntityOverviewList from "../pages/entity-overview/list";
import IncorporationPack from "../pages/incorporation-pack";

import AdditionalScreens from "../pages/additional-screens";
import CDD from "../pages/additional-screens/cdd";
import Client from "../pages/additional-screens/client";
import Shareholder from "../pages/additional-screens/shareholder";
import Director from "../pages/additional-screens/director";
import Crypto from "../pages/additional-screens/crypto";

import Storage from "../pages/storage";
import OcrExtraction from "../pages/ocr-extraction";
import DocTranslation from "../pages/doc-translation";
import ServiceInvoice from "../pages/service-invoice";
import AccessControl from "../pages/access-control";
import PendingRequests from "../pages/pending-requests";
import MasterSearch from "../pages/master-search";
import IndividualAnalysis from "../pages/individual-analysis";
import CorporateEntityAnalysis from "../pages/corporate-entity-analysis";
import MyPortfolio from "../pages/my-portfolio";
import ClientDetails from "../pages/client-details";

import PeriodicReview from "../pages/periodic-review";
import ManualStorage from "../pages/manual-storage";
import AuditTrail from "../pages/audit-trail";
import AnnualFilings from "../pages/annual-filings";
import FeeSchedule from "../pages/fee-schedule";
import FeeScheduleCreate from "../pages/fee-schedule/create";
import FeeScheduleDetails from "../pages/fee-schedule/details";
import Mail from "../pages/mail";
import Notifications from "../pages/notifications";

import CertificateOfIncumbency from "../pages/certificate-of-incumbency";
import CoiRequest from "../pages/coi-request";
import OrganizationStructure from "../pages/organization-structure";
import ProfileSettings from "../pages/profile-settings";
import Account from "../pages/account";
import UserManagement from "../pages/user-management";
import UserCrud from "../pages/user-management/crud";
import ManageRoles from "../pages/user-management/manage-roles";
import BusinessPartners from "../pages/business-partners";
import BPCrud from "../pages/business-partners/crud";
import OnboardEntity from "../pages/onboard-entity";
import CreateTransferIn from "../pages/onboard-entity/create";

import CertificateOfGoodStanding from "../pages/certificate-of-good-standing";
import ChangeOfDirector from "../pages/change-of-director";
import ChangeOfShareholder from "../pages/change-of-shareholder";
import CertificateOfShares from "../pages/certificate-of-shares";
import AlterationToMAA from "../pages/alteration-to-maa";
import MaaNameChange from "../pages/maa-name-change";
import NotarizationOfDocuments from "../pages/notarization-of-documents";
import ApostilleOfDocuments from "../pages/apostille-of-documents";
import DraftingResolutions from "../pages/drafting-resolutions";
import CorporateDocuments from "../pages/corporate-documents";
import Other from "../pages/other";
import TransferOut from "../pages/transfer-out";
import SolventLiquidation from "../pages/solvent-liquidation";

import Reminders from "../pages/reminders";
import AccessManagement from "../pages/access-management";

import ChatBox from "../pages/chatBox/chatBox";
import NotFound from "../pages/not-found";

const routes = [
  {
    name: "Login",
    path: "/",
    icon: "",
    component: <Login />,
    type: "public",
    showInMenu: false,
  },
  {
    path: "/login",
    component: <Login />,
    type: "public",
    showInMenu: false,
  },
  {
    path: "/signup",
    component: <Signup />,
    type: "public",
    showInMenu: false,
  },
  {
    path: "/reset-password",
    component: <ResetPassword />,
    type: "public",
    showInMenu: false,
  },
  {
    path: "/verification-code",
    component: <VerificationCode />,
    type: "public",
    showInMenu: false,
  },
  {
    name: "Home",
    path: "home",
    component: <Home />,
    icon: "fa-home",
    type: "private",
    permissions: [
      "super-admin",
      "admin",
      "analyst",
      "client",
      "mlro",
      "entity",
      "Processor",
      "Lead",
      "lead",
      "nb",
      "introducer-client",
      "end-user",
      "parent-client",
      "point-of-contact",
    ],

    showInMenu: true,
  },
  {
    name: "MasterSearchCreation", //"Master Search/MF Creation",
    path: "master-search",
    component: <MasterSearch />,
    icon: "fa-file-search",
    type: "private",
    permissions: ["super-admin", "admin", "lead", "mlro", "analyst"],
    showInMenu: true,
    parentId: 0,
  },
  {
    name: "IndividualAnalysis", //"Individual Analysis",
    path: "individual-analysis",
    component: <IndividualAnalysis />,
    icon: "fa-user-circle",
    type: "private",
    permissions: ["super-admin", "admin", "lead", "mlro", "analyst"],
    showInMenu: true,
    parentId: 0,
    duplicate: 2,
  },
  {
    name: "PeriodicReview", //"Periodic Review",
    path: "periodic-review",
    component: <PeriodicReview />,
    icon: "fa-file-chart-pie",
    type: "private",
    permissions: ["super-admin", "admin", "lead", "mlro", "analyst"],
    showInMenu: true,
    parentId: 0,
  },
  {
    name: "CorporateEntityAnalysis", //"Corporate Entity Analysis",
    path: "corporate-entity-analysis",
    component: <CorporateEntityAnalysis />,
    icon: "fa-analytics",
    type: "private",
    permissions: ["super-admin", "admin", "lead", "mlro", "analyst"],
    showInMenu: true,
    parentId: 0,
    duplicate: 2,
  },
  {
    name: "PreIncorpDocumentation", //"Pre-Incorp Documentation",
    path: "pre-incorp-resolution",
    component: <PreIncorpResolution />,
    type: "private",
    icon: "fa-file-download",
    permissions: ["super-admin", "admin", "lead", "mlro", "analyst", "client"],
    showInMenu: true,
    parentId: 1,
  },
  {
    name: "NameCheck", //"Name Check",
    path: "incorporation",
    component: <Incorporation />,
    icon: "fa-paper-plane",
    type: "private",
    permissions: [
      "super-admin",
      "admin",
      "lead",
      "analyst",
      "client",
      "point-of-contact",
    ],
    showInMenu: true,
    parentId: 1,
  },
  {
    name: "ActiveRequests", //"Active Requests",
    path: "active-requests",
    component: <ActiveRequests />,
    icon: "fa-tasks",
    type: "private",
    permissions: ["super-admin", "admin", "lead", "mlro", "analyst"],
    showInMenu: true,
    parentId: 6,
  },
  {
    name: "NewIncorporationRequest", //"New Incorporation Request",
    path: "relationship-manager",
    component: <RelationshipManager />,
    icon: "fa-tasks-alt",
    type: "private",
    permissions: [
      "super-admin",
      "admin",
      "lead",
      "analyst",
      "client",
      "point-of-contact",
    ],
    showInMenu: true,
    parentId: 1,
  },
  {
    name: "NewIncorporationReview", //"New Incorporation Review",
    path: "onboarding-team/download/:taskId",
    component: <OnboardingTeamDownload />,
    type: "private",
    icon: "fa-folder-plus",
    permissions: ["super-admin", "admin", "lead", "analyst"],
    showInMenu: true,
    parentId: 1,
  },
  {
    name: "NewIncorporationApprovalReq", //"New Incorporation Approval Req",
    path: "onboarding-team/upload",
    component: <OnboardingTeamUpload />,
    icon: "fa-flag-alt",
    type: "private",
    permissions: ["super-admin", "admin", "lead", "analyst"],
    showInMenu: true,
    parentId: 1,
  },
  {
    name: "InitialMLROApproval", //"Initial MLRO Approval",
    path: "onboarding-team/review",
    component: <OnboardingTeamReviewList />,
    icon: "fa-clipboard-check",
    type: "private",
    permissions: ["super-admin", "admin", "lead", "mlro", "analyst"],
    showInMenu: true,
    parentId: 1,
  },

  {
    name: "MLROSignOffRequestView", //"MLRO Sign Off Request View",
    path: "onboarding-team/view",
    component: <OnboardingTeamView />,
    icon: "fa-building",
    type: "private",
    permissions: ["super-admin", "admin", "lead", "analyst"],
    showInMenu: true,
  },
  {
    name: "OnboardEntity", //"Onboard Entity",
    path: "onboard-entity",
    component: <OnboardEntity />,
    icon: "fa-check-square",
    type: "private",
    permissions: ["super-admin", "admin", "lead", "analyst"],
    showInMenu: true,
    parentId: 1,
  },
  {
    name: "CreateNewTransferIn", // "Create New Transfer In",
    path: "onboard-entity/create",
    component: <CreateTransferIn />,
    type: "private",
    permissions: ["super-admin", "admin", "lead", "analyst"],
    showInMenu: false,
  },
  {
    name: "MLROApproval", //"MLRO Approval",
    path: "mlro",
    component: <MLRO />,
    icon: "fa-check-square",
    type: "private",
    permissions: ["super-admin", "admin", "lead", "mlro", "analyst"],
    showInMenu: true,
    parentId: 2,
  },
  {
    name: "NewIncorporationSubmissionToRegistry", //"New Incorporation Submission to Registry",
    path: "new-incorporation",
    component: <NewIncorporation />,
    icon: "fa-external-link-square",
    type: "private",
    permissions: ["super-admin", "admin", "lead", "analyst"],
    showInMenu: true,
    parentId: 1,
  },
  {
    name: "NewBusinessOnboard", // "New Business Onboard",
    path: "onboarding-team/review-upload",
    component: <OnboardingTeamReviewUpload />,
    icon: "fa-building",
    type: "private",
    permissions: ["super-admin", "admin", "lead", "analyst"],
    showInMenu: true,
    parentId: 1,
  },
  {
    name: "EntityOverviewUpdate", //"Entity Overview Update",
    path: "entity-overview/edit",
    component: <EntityOverviewEdit />,
    icon: "fa-file-edit",
    type: "private",
    permissions: ["super-admin", "admin", "lead", "mlro", "analyst"],
    showInMenu: true,
    parentId: 3,
  },
  {
    name: "PostIncorpServiceRequest", // "Post Incorp Service Request",
    path: "post-incorp",
    component: <PostIncorp />,
    icon: "fa-plus-circle",
    type: "private",
    permissions: [
      "super-admin",
      "admin",
      "lead",
      "mlro",
      "analyst",
      "client",
      "point-of-contact",
    ],
    showInMenu: true,
    parentId: 3,
  },
  {
    name: "OCRExtraction", //"OCR Extraction",
    path: "ocr-extraction",
    component: <OcrExtraction />,
    icon: "fa-print-search",
    type: "private",
    permissions: ["super-admin"],
    showInMenu: true,
    parentId: 3,
  },
  {
    name: "Translation", //"Translation",
    path: "doc-translation",
    component: <DocTranslation />,
    icon: "fa-language",
    type: "private",
    permissions: ["super-admin"],
    showInMenu: true,
    parentId: 3,
  },
  {
    name: "MyPortfolioClient", //"My Portfolio – Client",
    path: "my-portfolio",
    component: <MyPortfolio />,
    icon: "fa-briefcase",
    type: "private",
    permissions: [
      "super-admin",
      "admin",
      "lead",
      "mlro",
      "analyst",
      "client",
      "point-of-contact",
    ],
    showInMenu: true,
    parentId: 3,
  },

  {
    name: "PendingResubmission", //"Pending/Resubmission",
    path: "pending-requests",
    component: <PendingRequests />,
    icon: "fa-exclamation-circle",
    type: "private",
    permissions: [
      "super-admin",
      "admin",
      "lead",
      "mlro",
      "analyst",
      "client",
      "point-of-contact",
    ],
    showInMenu: true,
    parentId: 3,
  },
  {
    name: "ManualStorage", //"Manual Storage",
    path: "manual-storage",
    component: <ManualStorage />,
    icon: "fa-server",
    type: "private",
    permissions: ["super-admin", "admin", "lead", "mlro", "analyst"],
    showInMenu: true,
    parentId: 3,
  },
  {
    name: "Filings",
    path: "annual-filings",
    component: <AnnualFilings />,
    icon: "fa-clipboard-list-check",
    type: "private",
    permissions: ["super-admin", "admin", "lead", "mlro", "analyst"],
    showInMenu: true,
    parentId: 4,
  },
  {
    name: "Invoicing",
    path: "service-invoice",
    component: <ServiceInvoice />,
    icon: "fa-money-check-edit-alt",
    type: "private",
    permissions: ["super-admin", "admin", "lead", "analyst"],
    showInMenu: true,
    parentId: 4,
  },
  {
    name: "FeeSchedule", //"Fee Schedule",
    path: "fee-schedule",
    component: <FeeSchedule />,
    icon: "fa-money-check-alt",
    type: "private",
    permissions: ["super-admin", "admin", "lead", "analyst"],
    showInMenu: true,
    parentId: 8,
  },
  {
    path: "fee-schedule-create",
    component: <FeeScheduleCreate />,
    type: "private",
    permissions: ["super-admin", "admin", "lead", "analyst"],
    showInMenu: false,
  },
  {
    path: "fee-schedule-details",
    component: <FeeScheduleDetails />,
    type: "private",
    permissions: ["super-admin", "admin", "lead", "analyst"],
    showInMenu: false,
  },
  {
    name: "AuditTrailAndReporting", //"Audit Trail & Reporting",
    path: "audit-trail",
    component: <AuditTrail />,
    icon: "fa-file-chart-line",
    type: "private",
    permissions: ["super-admin", "admin", "lead", "analyst"],
    showInMenu: true,
    parentId: 6,
  },
  {
    name: "Notifications", // "Notifications",
    path: "notifications",
    component: <Notifications />,
    icon: "fa-bell",
    type: "private",
    permissions: [
      "super-admin",
      "admin",
      "lead",
      "mlro",
      "analyst",
      "client",
      "point-of-contact",
    ],
    showInMenu: true,
    parentId: 6,
  },
  {
    name: "CDD",
    path: "additional-screens/cdd",
    component: <CDD />,
    icon: "fa-calendar-alt",
    type: "private",
    permissions: [
      "super-admin",
      "admin",
      "lead",
      "mlro",
      "analyst",
      "client",
      "point-of-contact",
    ],
    showInMenu: false,
  },
  {
    name: "Mailbox",
    path: "mail",
    icon: "fa-envelope",
    component: <Mail />,
    type: "private",
    permissions: ["super-admin", "admin", "lead", "analyst"],
    showInMenu: true,
    parentId: 6,
  },
  {
    name: "ChatBox",
    path: "chat-box",
    icon: "fa-envelope",
    component: <ChatBox />,
    type: "private",
    permissions: ["super-admin", "admin", "lead", "analyst"],
    showInMenu: true,
    parentId: 6,
  },

  {
    name: "CryptoTM", //"Crypto TM",
    path: "additional-screens/crypto",
    component: <Crypto />,
    icon: "fa-usd-circle",
    type: "private",
    permissions: ["super-admin"],
    showInMenu: false,
  },
  {
    name: "VASP",
    path: "vasp",
    component: <Home />,
    icon: "fa-server",
    type: "private",
    permissions: ["super-admin", "admin", "analyst", "client"],
    showInMenu: false,
  },
  {
    path: "onboarding-team",
    component: <OnboardingTeamListing />,
    type: "private",
    permissions: ["super-admin", "admin", "lead", "analyst"],
    showInMenu: false,
  },
  {
    path: "onboarding-team/edit/:taskId",
    component: <OnboardingTeamEdit />,
    type: "private",
    permissions: ["super-admin", "admin", "lead", "analyst"],
    showInMenu: false,
  },
  {
    name: "MasterFileCreation", //"MasterFile Creation",
    path: "master-file-creation",
    component: <MasterFileCreation />,
    icon: "fa-file-check",
    type: "private",
    permissions: ["super-admin", "admin", "lead", "analyst"],
    showInMenu: false,
  },
  {
    name: "MasterFileDetails", // "MasterFile Details",
    path: "master-file-details",
    component: <MasterFileDetails />,
    icon: "fa-file-check",
    type: "private",
    permissions: ["super-admin", "admin", "lead", "analyst"],
    showInMenu: false,
  },
  {
    path: "incorporation-confirmation",
    component: <IncorporationConfirmation />,
    type: "private",
    permissions: ["super-admin", "admin", "lead", "analyst"],
    showInMenu: false,
  },
  {
    path: "incorporation-feedback",
    component: <IncorporationFeedback />,
    type: "private",
    permissions: ["super-admin", "admin", "lead", "analyst"],
    showInMenu: false,
  },
  {
    path: "entity-overview/list",
    component: <EntityOverviewList />,
    type: "private",
    permissions: [
      "super-admin",
      "admin",
      "lead",
      "mlro",
      "analyst",
      "client",
      "point-of-contact",
    ],
    showInMenu: false,
  },
  {
    path: "client-details",
    component: <ClientDetails />,
    type: "private",
    permissions: ["super-admin", "admin", "lead", "analyst"],
    showInMenu: false,
  },

  {
    path: "incorporation-pack",
    component: <IncorporationPack />,
    type: "private",
    permissions: ["super-admin", "admin", "lead", "analyst"],
    showInMenu: false,
  },
  {
    path: "additional-screens",
    component: <AdditionalScreens />,
    type: "private",
    permissions: ["super-admin"],
    showInMenu: false,
  },
  {
    path: "additional-screens/client",
    component: <Client />,
    type: "private",
    permissions: ["super-admin", "admin", "lead", "analyst"],
    showInMenu: false,
  },
  {
    path: "additional-screens/shareholder",
    component: <Shareholder />,
    type: "private",
    permissions: ["super-admin", "admin", "lead", "analyst"],
    showInMenu: false,
  },
  {
    path: "additional-screens/director",
    component: <Director />,
    type: "private",
    permissions: ["super-admin", "admin", "lead", "analyst"],
    showInMenu: false,
  },
  {
    path: "storage",
    component: <Storage />,
    type: "private",
    permissions: ["super-admin", "admin", "lead", "analyst"],
    showInMenu: false,
  },
  {
    name: "OrganizationStructure", //"Organization Structure",
    path: "organization-structure",
    component: <OrganizationStructure />,
    type: "private",
    permissions: ["super-admin"],
    // showInMenu: true,
    // parentId: 5,
  },
  {
    path: "certificate-of-incumbency",
    component: <CertificateOfIncumbency />,
    type: "private",
    permissions: ["super-admin", "admin", "lead", "analyst"],
    showInMenu: false,
  },
  {
    path: "coi-request",
    component: <CoiRequest />,
    type: "private",
    permissions: ["super-admin"],
    showInMenu: false,
  },
  {
    name: "ProfileSettings", //"Profile Settings",
    path: "profile-settings",
    component: <ProfileSettings />,
    type: "private",
    permissions: ["super-admin", "admin"],
    showInMenu: true,
    parentId: 7,
  },
  {
    name: "UserManagement", //"User Management",
    path: "user-management",
    component: <UserManagement />,
    type: "private",
    permissions: ["super-admin", "admin"],
    showInMenu: true,
    parentId: 7,
  },
  {
    name: "AddUser", //"Add User",
    path: "add-user",
    component: <UserCrud />,
    type: "private",
    permissions: ["super-admin", "admin"],
  },
  {
    name: "EditUser", //"Edit User",
    path: "edit-user/:id",
    component: <UserCrud />,
    type: "private",
    permissions: ["super-admin", "admin"],
  },
  {
    name: "ManageRoles", //"ManageRoles",
    path: "manage-roles",
    component: <ManageRoles />,
    type: "private",
    permissions: ["super-admin", "admin"],
  },
  {
    name: "Account",
    path: "account",
    component: <Account />,
    type: "private",
    permissions: ["super-admin"],
    showInMenu: true,
    parentId: 7,
  },
  {
    name: "BusinessPartners", //"Business Partners",
    path: "business-partners",
    component: <BusinessPartners />,
    type: "private",
    permissions: ["super-admin"],
    showInMenu: true,
    parentId: 7,
  },

  {
    name: "AddBusinessPartner", //"Add Business Partner",
    path: "business-partners/add",
    component: <BPCrud />,
    type: "private",
    permissions: ["super-admin"],
  },
  {
    name: "EditBusinessPartner", //"Edit Business Partner",
    path: "business-partners/edit/:id",
    component: <BPCrud />,
    type: "private",
    permissions: ["super-admin"],
  },
  {
    name: "CertificateOfGoodStanding", //"Certificate Of Good Standing",
    path: "certificate-of-good-standing",
    component: <CertificateOfGoodStanding />,
    type: "private",
    permissions: ["super-admin", "admin", "lead", "analyst"],
  },
  {
    name: "ChangeOfDirector", //"Change of Director",
    path: "change-of-director",
    component: <ChangeOfDirector />,
    type: "private",
    permissions: [
      "super-admin",
      "admin",
      "lead",
      "analyst",
      "client",
      "point-of-contact",
    ],
  },
  {
    name: "ChangeOfShareholder", //"Change of Shareholder",
    path: "change-of-shareholder",
    component: <ChangeOfShareholder />,
    type: "private",
    permissions: [
      "super-admin",
      "admin",
      "lead",
      "analyst",
      "client",
      "point-of-contact",
    ],
  },
  {
    name: "CertificateOfShares", //"Certificate of Shares",
    path: "certificate-of-shares",
    component: <CertificateOfShares />,
    type: "private",
    permissions: ["super-admin", "admin", "lead", "analyst"],
  },
  {
    name: "AlterationToMAA", //"Alteration to M&AA",
    path: "alteration-to-maa",
    component: <AlterationToMAA />,
    type: "private",
    permissions: [
      "super-admin",
      "admin",
      "lead",
      "analyst",
      "client",
      "point-of-contact",
    ],
  },
  {
    name: "MAANameChange", //"M&AA Name Change",
    path: "maa-name-change",
    component: <MaaNameChange />,
    type: "private",
    permissions: ["super-admin", "admin", "lead", "analyst"],
  },
  {
    name: "NotarizationOfDocuments", //"Notarization of Documents",
    path: "notarization-of-documents",
    component: <NotarizationOfDocuments />,
    type: "private",
    permissions: [
      "super-admin",
      "admin",
      "lead",
      "analyst",
      "client",
      "point-of-contact",
    ],
  },
  {
    name: "ApostilleOfDocuments", //"Apostille of Documents",
    path: "apostille-of-documents",
    component: <ApostilleOfDocuments />,
    type: "private",
    permissions: [
      "super-admin",
      "admin",
      "lead",
      "analyst",
      "client",
      "point-of-contact",
    ],
  },
  {
    name: "DraftingResolutions", //"Drafting Resolutions",
    path: "drafting-resolutions",
    component: <DraftingResolutions />,
    type: "private",
    permissions: ["super-admin", "admin", "lead", "analyst"],
  },
  {
    name: "CorporateDocuments", //"Corporate Documents",
    path: "corporate-documents",
    component: <CorporateDocuments />,
    type: "private",
    permissions: ["super-admin", "admin", "lead", "analyst"],
  },
  {
    name: "Other",
    path: "other",
    component: <Other />,
    type: "private",
    permissions: ["super-admin", "admin", "analyst", "client"],
  },
  {
    name: "TransferOut", //"Transfer Out",
    path: "transfer-out",
    component: <TransferOut />,
    type: "private",
    permissions: [
      "super-admin",
      "admin",
      "lead",
      "analyst",
      "client",
      "point-of-contact",
    ],
  },
  {
    name: "SolventLiquidation", // "Solvent Liquidation",
    path: "solvent-liquidation",
    component: <SolventLiquidation />,
    type: "private",
    permissions: [
      "super-admin",
      "admin",
      "lead",
      "analyst",
      "client",
      "point-of-contact",
    ],
  },
  {
    name: "RemindersNotificationsSetup", //"Reminders/Notifications Setup",
    path: "reminders",
    component: <Reminders />,
    type: "private",
    permissions: ["super-admin", "admin", "lead", "analyst"],
    showInMenu: true,
    parentId: 8,
  },
  {
    name: "AccessManagement", //"Access Management",
    path: "access-management",
    component: <AccessManagement />,
    type: "private",
    permissions: ["super-admin", "admin"],
    showInMenu: true,
    parentId: 8,
  },
  // {
  //   name: "Error 404",
  //   path: "*",
  //   icon: "",
  //   component: <NotFound />,
  //   type: "private",
  //   permissions: [],
  //   showInMenu: false,
  // },
];

export default routes;
