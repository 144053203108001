import React, { useEffect, useState, useRef } from "react";
import { Table, Select, Popover, Spin } from "antd";
import { useSelector, useDispatch } from "react-redux";
import TableSortArrows from "../components/tableSortArrows";
import { fetchApi, sendNotify } from "../helper";
import {
  getFilingSetup,
  getPeriodicRiskReviewSetup,
  getdocumentExpiryNotify,
} from "../store/masterSetup/masterSetupSlice";
import { getManageRoles } from "../store/manageRoles/manageRolesSlice";
async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

export default function Reminders() {
  const formRef = useRef();
  const form2Ref = useRef();
  const form3Ref = useRef();
  const masterSetup = useSelector((state) => state.masterSetup);
  const manageRoles = useSelector((state) => state.manageRoles);
  const [role, setRoleArray] = useState([]);
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    let ctr = {};
    dispatch(getFilingSetup(ctr));
    dispatch(getPeriodicRiskReviewSetup(ctr));
    dispatch(getdocumentExpiryNotify(ctr));
    dispatch(getManageRoles({ _limit: 10, _start: 0 }));
  }, []);

  useEffect(() => {
    handleRefresh();
  }, [masterSetup, manageRoles]);

  const column1 = [
    {
      title: "Risk Level",
      dataIndex: "riskLevel",
      render: (_, { _id, riskLevel }) => (
        <Select
          className="custom-select w-100px ptb-1"
          defaultValue={riskLevel || "Medium"}
          onChange={(value) => {
            if (!formRef.current) formRef.current = {};
            if (!formRef.current[_id]) formRef.current[_id] = {};
            formRef.current[_id] = {
              ...formRef.current[_id],
              riskLevel: value,
            };
          }}
          disabled={true}
          options={[
            { value: "High", label: "High" },
            { value: "Medium", label: "Medium" },
            { value: "Low", label: "Low" },
          ]}
        />
      ),
    },
    {
      title: "Risk Review Frequency",
      dataIndex: ["riskReview", "riskDuration"],
      render: (_, { _id, riskReview, riskDuration }) => (
        <div className="custom-form">
          <input
            type="number"
            className="w-80px"
            defaultValue={riskReview || 1}
            min={1}
            placeholder="Enter count"
            onChange={(e) => {
              if (!formRef.current) formRef.current = {};
              if (!formRef.current[_id]) formRef.current[_id] = {};
              formRef.current[_id] = {
                ...formRef.current[_id],
                riskReview: parseInt(e.target.value) || 1,
              };
            }}
          />
          &nbsp;
          <Select
            className="custom-select w-100px ptb-1"
            placeholder="Select duration"
            defaultValue={riskDuration || "months"}
            onChange={(value) => {
              if (!formRef.current) formRef.current = {};
              if (!formRef.current[_id]) formRef.current[_id] = {};
              formRef.current[_id] = {
                ...formRef.current[_id],
                riskDuration: value,
              };
            }}
            options={[
              { value: "days", label: "Days" },
              { value: "week", label: "Week" },
              { value: "months", label: "Months" },
              { value: "years", label: "Years" },
            ]}
          />
        </div>
      ),
    },
    {
      title: "Reminder Count",
      dataIndex: "reminderCount",
      render: (_, { _id, reminderCount }) => (
        <div className="custom-form">
          <input
            type="number"
            className="w-80px"
            defaultValue={reminderCount || 1}
            placeholder="Enter count"
            min={1}
            onChange={(e) => {
              if (!formRef.current) formRef.current = {};
              if (!formRef.current[_id]) formRef.current[_id] = {};
              formRef.current[_id] = {
                ...formRef.current[_id],
                reminderCount: parseInt(e.target.value) || 1,
              };
            }}
          />
        </div>
      ),
    },
    {
      title: "Reminder Starts From",
      dataIndex: "reminderStartsFrom",
      render: (_, { _id, reminderStartsFrom, onwards }) => (
        <div className="custom-form">
          <input
            type="number"
            className="w-80px"
            step="0.1"
            defaultValue={reminderStartsFrom || 1}
            placeholder="Enter count"
            min={1}
            onChange={(e) => {
              if (!formRef.current) formRef.current = {};
              if (!formRef.current[_id]) formRef.current[_id] = {};
              formRef.current[_id] = {
                ...formRef.current[_id],
                reminderStartsFrom: parseFloat(e.target.value) || 1,
              };
            }}
          />
          &nbsp;
          <Select
            className="custom-select w-100px ptb-1"
            placeholder="Select"
            defaultValue={onwards || "months"}
            onChange={(value) => {
              if (!formRef.current) formRef.current = {};
              if (!formRef.current[_id]) formRef.current[_id] = {};
              formRef.current[_id] = {
                ...formRef.current[_id],
                onwards: value,
              };
            }}
            options={[
              { value: "days", label: "Days" },
              { value: "week", label: "Week" },
              { value: "months", label: "Months" },
              { value: "years", label: "Years" },
            ]}
          />
        </div>
      ),
    },
    {
      title: "Recurring Time",
      dataIndex: "recurringTime",
      render: (_, { _id, recurringTime }) => (
        <div className="custom-form">
          <input
            type="number"
            className="w-80px"
            defaultValue={recurringTime || 1}
            placeholder="Enter count"
            min={1}
            onChange={(e) => {
              if (!formRef.current) formRef.current = {};
              if (!formRef.current[_id]) formRef.current[_id] = {};
              formRef.current[_id] = {
                ...formRef.current[_id],
                recurringTime: parseInt(e?.target?.value) || 1,
              };
            }}
          />
        </div>
      ),
    },
    {
      title: "Reminder Frequency",
      dataIndex: "reminderFrequency",
      render: (_, { _id, reminderFrequency }) => (
        <Select
          className="custom-select w-150px ptb-1"
          defaultValue={reminderFrequency || "Days"}
          onChange={(value) => {
            if (!formRef.current) formRef.current = {};
            if (!formRef.current[_id]) formRef.current[_id] = {};
            formRef.current[_id] = {
              ...formRef.current[_id],
              reminderFrequency: value,
            };
          }}
          options={[
            { value: "Days", label: "Days" },
            { value: "Week", label: "Week" },
            { value: "Months", label: "Months" },
            { value: "Years", label: "Years" },
          ]}
        />
      ),
    },
    {
      title: "Default Recipients",
      dataIndex: "defaultRecipients",
      sorter: (a, b) => a.defaultRecipients.localeCompare(b.defaultRecipients),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { _id, defaultRecipients }) => (
        <Select
          className="custom-select ptb-1"
          style={{ width: 150 }}
          placeholder="Select duration"
          defaultValue={defaultRecipients || "admin"}
          mode="multiple"
          onChange={(value) => {
            if (!formRef.current) formRef.current = {};
            if (!formRef.current[_id]) formRef.current[_id] = {};
            formRef.current[_id] = {
              ...formRef.current[_id],
              defaultRecipients: value,
            };
          }}
          options={role}
        />
      ),
    },
  ];

  const column2 = [
    {
      title: "Filing Type",
      dataIndex: "filingType",
      sorter: (a, b) => a.filingType.localeCompare(b.filingType),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { filingType, _id }) => (
        <div className="custom-form d-flex gap-3 align-items-center">
          <input
            type="text"
            defaultValue={filingType}
            placeholder="Enter deadline"
            style={{ width: 200 }}
            onChange={(e) => {
              if (!form2Ref.current) form2Ref.current = {};
              if (!form2Ref.current[_id]) form2Ref.current[_id] = {};
              form2Ref.current[_id] = {
                ...form2Ref.current[_id],
                filingType: parseInt(e.target.value) || 1,
              };
            }}
          />{" "}
        </div>
      ),
    },

    {
      title: "Deadline",
      dataIndex: "deadline",
      align: "center",
      sorter: (a, b) => a.deadline.localeCompare(b.deadline),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { _id, deadline, deadlineDis }) => (
        <div className="custom-form d-flex gap-3 align-items-center">
          <input
            type="text"
            defaultValue={deadline}
            placeholder="Enter deadline"
            style={{ width: 55 }}
            onChange={(e) => {
              if (!form2Ref.current) form2Ref.current = {};
              if (!form2Ref.current[_id]) form2Ref.current[_id] = {};
              form2Ref.current[_id] = {
                ...form2Ref.current[_id],
                deadline: parseInt(e.target.value) || 1,
              };
            }}
          />
          &nbsp;
          <Select
            className="custom-select w-100px ptb-1"
            placeholder="Select duration"
            defaultValue={deadlineDis || "months"}
            onChange={(value) => {
              if (!form2Ref.current) form2Ref.current = {};
              if (!form2Ref.current[_id]) form2Ref.current[_id] = {};
              form2Ref.current[_id] = {
                ...form2Ref.current[_id],
                deadlineDis: value,
              };
            }}
            options={[
              { value: "days", label: "Days" },
              { value: "week", label: "Week" },
              { value: "months", label: "Months" },
              { value: "years", label: "Years" },
            ]}
          />
        </div>
      ),
    },
    {
      title: "Reminder Count",
      dataIndex: "reminderCount",
      align: "center",
      render: (_, { _id, reminderCount }) => (
        <div className="custom-form">
          <input
            type="number"
            className="w-80px"
            defaultValue={reminderCount || 1}
            placeholder="Enter count"
            min={1}
            onChange={(e) => {
              if (!form2Ref.current) form2Ref.current = {};
              if (!form2Ref.current[_id]) form2Ref.current[_id] = {};
              form2Ref.current[_id] = {
                ...form2Ref.current[_id],
                reminderCount: parseInt(e.target.value) || 1,
              };
            }}
          />
        </div>
      ),
    },
    {
      title: "Reminder Starts After",
      dataIndex: "reminderStartsAfter",
      sorter: (a, b) =>
        a.reminderStartsAfter.localeCompare(b.reminderStartsAfter),
      sortIcon: ({ sortOrder, _id }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { _id, deadline, deadlineDis }) => (
        <div className="custom-form d-flex gap-3 align-items-center">
          <input
            type="text"
            defaultValue={deadline}
            placeholder="Enter deadline"
            style={{ width: 55 }}
            onChange={(e) => {
              if (!form2Ref.current) form2Ref.current = {};
              if (!form2Ref.current[_id]) form2Ref.current[_id] = {};
              form2Ref.current[_id] = {
                ...form2Ref.current[_id],
                deadline: parseInt(e.target.value) || 1,
              };
            }}
          />
          &nbsp;
          <Select
            className="custom-select w-100px ptb-1"
            placeholder="Select duration"
            defaultValue={deadlineDis || "months"}
            onChange={(value) => {
              if (!form2Ref.current) form2Ref.current = {};
              if (!form2Ref.current[_id]) form2Ref.current[_id] = {};
              form2Ref.current[_id] = {
                ...form2Ref.current[_id],
                deadlineDis: value,
              };
            }}
            options={[
              { value: "days", label: "Days" },
              { value: "week", label: "Week" },
              { value: "months", label: "Months" },
              { value: "years", label: "Years" },
            ]}
          />
        </div>
      ),
    },
    {
      title: "Recurring Time",
      dataIndex: "recurringTime",
      sorter: (a, b) => a.recurringTime.localeCompare(b.recurringTime),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { _id, reminderCount }) => (
        <div className="custom-form">
          <input
            type="text"
            className="w-80px"
            defaultValue={reminderCount || 1}
            placeholder="Enter count"
            min={1}
            onChange={(e) => {
              if (!form2Ref.current) form2Ref.current = {};
              if (!form2Ref.current[_id]) form2Ref.current[_id] = {};
              form2Ref.current[_id] = {
                ...form2Ref.current[_id],
                reminderCount: parseInt(e.target.value) || 1,
              };
            }}
          />
        </div>
      ),
    },
    {
      title: "Reminder Frequency",
      dataIndex: "reminderFrequency",
      sorter: (a, b) => a.reminderFrequency.localeCompare(b.reminderFrequency),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { reminderFrequency, _id }) => (
        <Select
          className="custom-select w-150px ptb-1"
          placeholder="Select risk level"
          defaultValue={reminderFrequency || "Days"}
          options={[
            {
              value: "Days",
              label: "Days",
            },
            {
              value: "Week",
              label: "Week",
            },
            {
              value: "Months",
              label: "Months",
            },
            {
              value: "Years",
              label: "Years",
            },
          ]}
          mode="multiple"
          onChange={(value) => {
            if (!form2Ref.current) form2Ref.current = {};
            if (!form2Ref.current[_id]) form2Ref.current[_id] = {};
            form2Ref.current[_id] = {
              ...form2Ref.current[_id],
              reminderFrequency: value,
            };
          }}
        />
      ),
    },
    {
      title: "Default Recipients",
      dataIndex: "defaultRecipients",
      sorter: (a, b) => a.defaultRecipients.localeCompare(b.defaultRecipients),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { _id, defaultRecipients }) => (
        <Select
          className="custom-select ptb-1"
          style={{ width: 150 }}
          placeholder="Select duration"
          defaultValue={defaultRecipients || "admin"}
          mode="multiple"
          onChange={(value) => {
            if (!form2Ref.current) form2Ref.current = {};
            if (!form2Ref.current[_id]) form2Ref.current[_id] = {};
            form2Ref.current[_id] = {
              ...form2Ref.current[_id],
              defaultRecipients: value,
            };
          }}
          options={role}
        />
      ),
    },
  ];
  const column3 = [
    {
      title: "Risk Review Frequency",
      dataIndex: ["riskReview", "riskDuration"],
      render: (_, { _id, riskReview, riskDuration }) => (
        <div className="custom-form">
          <input
            type="number"
            className="w-80px"
            defaultValue={riskReview || 1}
            min={1}
            placeholder="Enter count"
            onChange={(e) => {
              if (!form3Ref.current) form3Ref.current = {};
              if (!form3Ref.current[_id]) form3Ref.current[_id] = {};
              form3Ref.current[_id] = {
                ...form3Ref.current[_id],
                riskReview: parseInt(e.target.value) || 1,
              };
            }}
          />
          &nbsp;
          <Select
            className="custom-select w-100px ptb-1"
            placeholder="Select duration"
            defaultValue={riskDuration || "months"}
            onChange={(value) => {
              if (!form3Ref.current) form3Ref.current = {};
              if (!form3Ref.current[_id]) form3Ref.current[_id] = {};
              form3Ref.current[_id] = {
                ...form3Ref.current[_id],
                riskDuration: value,
              };
            }}
            options={[
              { value: "days", label: "Days" },
              { value: "week", label: "Week" },
              { value: "months", label: "Months" },
              { value: "years", label: "Years" },
            ]}
          />
        </div>
      ),
    },
    {
      title: "Reminder Count",
      dataIndex: "reminderCount",
      render: (_, { _id, reminderCount }) => (
        <div className="custom-form">
          <input
            type="number"
            className="w-80px"
            defaultValue={reminderCount || 1}
            placeholder="Enter count"
            min={1}
            onChange={(e) => {
              if (!form3Ref.current) form3Ref.current = {};
              if (!form3Ref.current[_id]) form3Ref.current[_id] = {};
              form3Ref.current[_id] = {
                ...form3Ref.current[_id],
                reminderCount: parseInt(e.target.value) || 1,
              };
            }}
          />
        </div>
      ),
    },
    {
      title: "Reminder Starts From",
      dataIndex: "reminderStartsFrom",
      render: (_, { _id, reminderStartsFrom, onwards }) => (
        <div className="custom-form">
          <input
            type="number"
            className="w-80px"
            step="0.1"
            defaultValue={reminderStartsFrom || 1}
            placeholder="Enter count"
            min={1}
            onChange={(e) => {
              if (!form3Ref.current) form3Ref.current = {};
              if (!form3Ref.current[_id]) form3Ref.current[_id] = {};
              form3Ref.current[_id] = {
                ...form3Ref.current[_id],
                reminderStartsFrom: parseFloat(e.target.value) || 1,
              };
            }}
          />
          &nbsp;
          <Select
            className="custom-select w-100px ptb-1"
            placeholder="Select"
            defaultValue={onwards || "months"}
            onChange={(value) => {
              if (!form3Ref.current) form3Ref.current = {};
              if (!form3Ref.current[_id]) form3Ref.current[_id] = {};
              form3Ref.current[_id] = {
                ...form3Ref.current[_id],
                onwards: value,
              };
            }}
            options={[
              { value: "days", label: "Days" },
              { value: "week", label: "Week" },
              { value: "months", label: "Months" },
              { value: "years", label: "Years" },
            ]}
          />
        </div>
      ),
    },
    {
      title: "Recurring Time",
      dataIndex: "recurringTime",
      render: (_, { _id, recurringTime }) => (
        <div className="custom-form">
          <input
            type="number"
            className="w-80px"
            defaultValue={recurringTime || 1}
            placeholder="Enter count"
            min={1}
            onChange={(e) => {
              if (!form3Ref.current) form3Ref.current = {};
              if (!form3Ref.current[_id]) form3Ref.current[_id] = {};
              form3Ref.current[_id] = {
                ...form3Ref.current[_id],
                recurringTime: parseInt(e?.target?.value) || 1,
              };
            }}
          />
        </div>
      ),
    },
    {
      title: "Reminder Frequency",
      dataIndex: "reminderFrequency",
      render: (_, { _id, reminderFrequency }) => (
        <Select
          className="custom-select w-150px ptb-1"
          defaultValue={reminderFrequency || "Days"}
          onChange={(value) => {
            if (!form3Ref.current) form3Ref.current = {};
            if (!form3Ref.current[_id]) form3Ref.current[_id] = {};
            form3Ref.current[_id] = {
              ...form3Ref.current[_id],
              reminderFrequency: value,
            };
          }}
          options={[
            { value: "Days", label: "Days" },
            { value: "Week", label: "Week" },
            { value: "Months", label: "Months" },
            { value: "Years", label: "Years" },
          ]}
        />
      ),
    },
    {
      title: "Default Recipients",
      dataIndex: "defaultRecipients",
      sorter: (a, b) => a.defaultRecipients.localeCompare(b.defaultRecipients),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { _id, defaultRecipients }) => (
        <Select
          className="custom-select ptb-1"
          style={{ width: 150 }}
          placeholder="Select duration"
          defaultValue={defaultRecipients || "admin"}
          mode="multiple"
          onChange={(value) => {
            if (!form3Ref.current) form3Ref.current = {};
            if (!form3Ref.current[_id]) form3Ref.current[_id] = {};
            form3Ref.current[_id] = {
              ...form3Ref.current[_id],
              defaultRecipients: value,
            };
          }}
          options={role}
        />
      ),
    },
  ];
  const handleRefresh = async () => {
    setLoading(true);
    setLoading2(true);
    setLoading3(true);
    if (masterSetup?.periodicRiskReviewSetup?.totalCount) {
      setData1(masterSetup?.periodicRiskReviewSetup?.rows);
      setLoading(false);
      const dataArray = manageRoles?.data?.rows;

      let array = dataArray?.map((d) => {
        let obj = {
          value: d?._id,
          label: SelectComponent(d?.title),
        };
        return obj;
      });
      setRoleArray(array);
    } else {
      handleInsetDate();
    }
    if (masterSetup?.filingSetup?.totalCount) {
      setData2(masterSetup?.filingSetup?.rows);
      setLoading2(false);
    }
    if (masterSetup?.documentExpiryNotify?.totalCount) {
      setData3(masterSetup?.documentExpiryNotify?.rows);
      setLoading3(false);
    }
  };
  const handleInsetDate = async () => {
    if (!masterSetup?.periodicRiskReviewSetup?.totalCount) {
      const filingSetup = [
        {
          riskLevel: "High",
          riskDuration: "months",
          riskReview: 6,
          onwards: "months",
          reminderCount: 3,
          reminderStartsFrom: 4.5,
          recurringTime: 15,
          reminderFrequency: "Days",
          position: 1,
        },
        {
          riskLevel: "Medium",
          riskDuration: "years",
          riskReview: 4,
          reminderCount: 3,
          onwards: "months",
          reminderStartsFrom: 21,
          recurringTime: 1,
          reminderFrequency: "Months",
          position: 2,
        },
        {
          riskLevel: "Low",
          riskDuration: "years",
          riskReview: 5,
          reminderCount: 3,
          onwards: "months",
          reminderStartsFrom: 9,
          recurringTime: 1,
          reminderFrequency: "Years",
          position: 3,
        },
      ];
      await asyncForEach(filingSetup, async (oneDoc) => {
        let obj = oneDoc;
        let payload = {
          method: "POST",
          url: `/masterSetup/periodicRiskReviewSetup`,
          data: obj,
        };
        fetchApi(payload)
          .then((res) => {})
          .catch((error) => {});
      });

      dispatch(getPeriodicRiskReviewSetup({}));
    }
    if (!masterSetup?.filingSetup?.totalCount) {
      const filingSetup = [
        {
          position: 1,
          filingType: "ROD",
          deadline: "30",
          deadlineDis: "months",
          reminderCount: 2,
          reminderStartsAfter: "months",
          recurringTime: 1,
          reminderFrequency: "",
        },
        {
          position: 2,
          filingType: "Shareholder",
          deadline: "60",
          deadlineDis: "months",
          reminderCount: 2,
          reminderStartsAfter: "months",
          recurringTime: 1,
          reminderFrequency: "",
        },
        {
          position: 3,
          filingType: "Annual Return",
          deadline: `9`,
          deadlineDis: `months`,
          reminderCount: 9,
          reminderStartsAfter: "months",
          recurringTime: 1,
          reminderFrequency: "",
        },
        {
          position: 4,
          filingType: "Compliance Filing / Periodic Review",
          deadline: "3",
          deadlineDis: "months",
          reminderCount: 7,
          reminderStartsAfter: "months",
          recurringTime: 2,
          reminderFrequency: "",
        },
        {
          position: 5,
          filingType: "BOSS/ES",
          deadline: "TBC",
          reminderCount: 8,
          reminderStartsAfter: "months",
          recurringTime: 2,
          reminderFrequency: "",
        },
      ];
      await asyncForEach(filingSetup, async (oneDoc) => {
        let obj = oneDoc;
        let payload = {
          method: "POST",
          url: `/masterSetup/filingSetup`,
          data: obj,
        };
        fetchApi(payload)
          .then((res) => {})
          .catch((error) => {});
      });
      dispatch(getFilingSetup({}));
    }
    if (!masterSetup?.documentExpiryNotify?.totalCount) {
      const filingSetup = [
        {
          deadline: "30",
          deadlineDis: "months",
          reminderCount: 2,
          reminderStartsAfter: "months",
          recurringTime: 1,
          reminderFrequency: "",
          position: 1,
        },
      ];
      await asyncForEach(filingSetup, async (oneDoc) => {
        let obj = oneDoc;
        let payload = {
          method: "POST",
          url: `/masterSetup/documentExpiryNotify`,
          data: obj,
        };
        fetchApi(payload)
          .then((res) => {})
          .catch((error) => {});
      });

      dispatch(getdocumentExpiryNotify({}));
    }
  };

  const SelectComponent = (id) => {
    const roleList = [
      { label: "Entity Point of Contact", value: "point-of-contact" },
      { label: "Analyst", value: "analyst" },
      { label: "Admin", value: "admin" },
      { label: "MLRO", value: "mlro" },
      { label: "Lead / Manager", value: "lead" },
      { label: "End User Client", value: "end-user" },
      { label: "Introducer Client", value: "introducer-client" },
      { label: "Client", value: "client" },
    ];
    let dataArray = manageRoles?.data?.rows.find((a) => a.title == id);

    const roleTitle = roleList.find((a) => a.value == dataArray?.title);

    return roleTitle ? roleTitle.label : "Unknown Role";
  };

  const handleSubmit = () => {
    console.log("Form Values:", formRef.current);

    let payload = {
      method: "PUT",
      url: `/masterSetup/periodicRiskReviewSetup/update`,
      data: formRef.current,
    };
    fetchApi(payload)
      .then((res) => {
        sendNotify("success", res?.message ? res?.message : "success");
        dispatch(getPeriodicRiskReviewSetup({}));
      })
      .catch((error) => {
        sendNotify("error", error?.message);
      });
  };

  const handleSubmitFilingSetup = () => {
    console.log("Form Values:", form2Ref.current);

    let payload = {
      method: "PUT",
      url: `/masterSetup/filingSetup/update`,
      data: form2Ref.current,
    };
    fetchApi(payload)
      .then((res) => {
        sendNotify("success", res?.message ? res?.message : "success");
        dispatch(getFilingSetup({}));
      })
      .catch((error) => {
        sendNotify("error", error?.message);
      });
  };
  const handleSubmitDocumentExpirySetup = () => {
    console.log("Form Values:", form2Ref.current);

    let payload = {
      method: "PUT",
      url: `/masterSetup/documentExpiryNotify/update`,
      data: form3Ref.current,
    };
    fetchApi(payload)
      .then((res) => {
        sendNotify("success", res?.message ? res?.message : "success");
        dispatch(getdocumentExpiryNotify({}));
      })
      .catch((error) => {
        sendNotify("error", error?.message);
      });
  };
  return (
    <div>
      <div className="page-header">
        <h1>Master Setup</h1>
      </div>
      <div className="page-content">
        <Spin spinning={loading}>
          <div className="d-flex justify-content-between flex-column flex-md-row align-items-md-center align-items-start mb-3">
            <h6 className="primary-title mb-3 mb-md-0">
              Periodic Risk Review Setup - Master
            </h6>
            <button className="primary-btn" onClick={handleSubmit}>
              Save Changes
            </button>
          </div>
          <Table
            className="custom-table mb-3"
            columns={column1}
            dataSource={data1}
            scroll={{ x: 1300 }}
            pagination={false}
          />
        </Spin>
        <Spin spinning={loading2}>
          <div className="d-flex justify-content-between flex-column flex-md-row align-items-md-center align-items-start mb-3">
            <h6 className="primary-title mb-3 mb-md-0">
              Filing Setup - Master
            </h6>
            <button className="primary-btn" onClick={handleSubmitFilingSetup}>
              Save Changes
            </button>
          </div>

          <Table
            className="custom-table mb-3"
            columns={column2}
            dataSource={data2}
            scroll={{ x: 1400 }}
            pagination={false}
          />
        </Spin>{" "}
        <Spin spinning={loading3}>
          <div className="d-flex justify-content-between flex-column flex-md-row align-items-md-center align-items-start mb-3">
            <h6 className="primary-title mb-3 mb-md-0">
              Document Expiry Nodification Setup - Master
            </h6>
            <button
              className="primary-btn"
              onClick={handleSubmitDocumentExpirySetup}
            >
              Save Changes
            </button>
          </div>
          <Table
            className="custom-table mb-3"
            columns={column3}
            dataSource={data3}
            scroll={{ x: 1150 }}
            pagination={false}
          />
        </Spin>
      </div>
    </div>
  );
}
