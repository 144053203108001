import { useState } from "react";
import { fetchApi } from "../helper";

const useAuthorizedIndividuals = () => {
  const [authIndiData, setAuthIndiData] = useState([]);
  const [tableDataLoading, setTableDataLoading] = useState(false);

  const fetchAuthorizedIndividuals = async (entityId) => {
    setTableDataLoading(true);
    try {
      const fetchData = async (url) => {
        let payload = { method: "GET", url };
        return fetchApi(payload).then((res) => res?.data?.rows || []);
      };

      const individualDetails = await fetchData(
        `/individualDetails?enId=${entityId}`
      );

      const copEntityDetails = await fetchData(
        `/copEntityDetails?enId=${entityId}`
      );

      const processedIndividualDetails = individualDetails.map((item) => {
        const individual = item.individualArray || {};

        return {
          key: item._id,
          name: `${individual.firstName} ${individual.lastName}`,
          type: individual.masterType,
          designation: item.shareType,
          wcResult: "",
          pep: individual.details?.pep === "Y" ? "Yes" : "No",
          sanctioned: individual.details?.sanction === "Y" ? "Yes" : "No",
          adverseMedia: individual.details?.sanctionText || "",
          sampleSignatures: "",
          sourceOfWealth: "",
          countryOfResidence: individual.COB,
          nationality: individual.nationality,
          listedRegulated:
            item.riskAssessment?.[0].countryRiskFatfGrey === "Grey Listed"
              ? "Yes"
              : "No",
          stakeholderRisk: item.riskAssessment?.[0].overallRiskScore || "",
        };
      });

      const processedCopEntityDetails = copEntityDetails.map((item) => {
        const coopDetails = item.cooperateEntityDetails || {};

        return {
          key: item._id,
          name: item.copEntityName,
          type: "Co-Operate",
          designation: item.shareType,
          wcResult: "",
          pep: "",
          sanctioned: coopDetails.details?.sanction === "Y" ? "Yes" : "No",
          adverseMedia: coopDetails.details?.sanctionText || "",
          sampleSignatures: "",
          sourceOfWealth: "",
          countryOfResidence: coopDetails.incorporationCountry || "",
          nationality: coopDetails.incorporationCountry || "",
          listedRegulated: coopDetails.details?.listed === "Y" ? "Yes" : "No",
          stakeholderRisk: item.riskAssessment?.[0]?.overallRiskScore || "",
        };
      });

      const combinedData = [
        ...processedIndividualDetails,
        ...processedCopEntityDetails,
      ];

      setAuthIndiData(combinedData);
    } catch (error) {
      console.error("Error fetching authorized individuals:", error);
    } finally {
      setTableDataLoading(false);
    }
  };

  return { authIndiData, tableDataLoading, fetchAuthorizedIndividuals };
};

export default useAuthorizedIndividuals;
