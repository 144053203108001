import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal, Checkbox, Spin } from "antd";

import SampleDoc from "../assets/pdf/sample.pdf";

import UploadZ from "../components/UploadZ";
import RequestResubmission from "../components/RequestResubmission";

import { Each, getQuery, fetchApi, sendNotify } from "../helper";

export default function DraftingResolutions() {
  let query = getQuery();

  const [docs, setDocs] = useState([]);
  const [reqType, setReqType] = useState("");
  const [rejectModel, setRejectModel] = useState(false);

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    handleRefresh();
  }, []);

  const handleRefresh = () => {
    setLoading(true);
    const payload = {
      method: "get",
      url: `/serviceRequest?id=${query?.id}`,
    };

    fetchApi(payload)
      .then((res) => {
        let data = res?.data?.rows?.[0];
        setData(data);
        let type = data?.individual?.name ? "individual" : "corporate";
        setReqType(type);
        setDocs(data?.[type]?.documents);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
        sendNotify("error", "Failed to load data");
      });
  };

  const getRejectDocs = (checked, docId) => {
    let arr = [...docs];
    let index = arr.findIndex((doc) => doc._id === docId);
    if (checked) {
      arr[index]["rejected"] = true;
    } else {
      delete arr[index]["rejected"];
    }
    setDocs(arr);
  };

  const handleOk = () => {
    setRejectModel(false);
  };
  const handleCancel = () => {
    setRejectModel(false);
  };

  const onChangeApproval = (file, bool) => {
    let arr = [...docs];
    let index = arr.findIndex((doc) => doc.file == file);
    arr[index].isApproved = bool;

    setDocs(arr);
  };

  return (
    <div>
      <div className="page-header">
        <Link to={"/app/active-requests"}>
          <h1 className="d-flex align-items-center gap-2">
            <i className="fal fa-long-arrow-left"></i> Drafting Resolutions
          </h1>
        </Link>
        <div className="d-flex gap-3">
          <RequestResubmission
            id={query?.id}
            documents={{ ...data?.[reqType], documents: docs }}
            type={reqType}
          />
          <button className="outline-btn">Save</button>
          <button className="primary-btn">Upload Drafted Resolutions</button>
        </div>
      </div>
      <Spin spinning={loading}>
        <div className="page-content">
          <div className="custom-form row gy-4 mb-5">
            <div className="col-md-3">
              <label htmlFor="">Entity Code</label>
              <span className="light-badge">{data?.entityCode ?? "N/A"}</span>
            </div>
            <div className="col-md-3">
              <label htmlFor="">Entity Name</label>
              <span className="light-badge">{data?.entityName ?? "N/A"}</span>
            </div>
            <div className="col-md-3">
              <label htmlFor="">Client Code</label>
              <span className="light-badge">{data?.clientCode ?? "N/A"}</span>
            </div>
            <div className="col-md-3">
              <label htmlFor="">Client Name</label>
              <span className="light-badge">{data?.clientName ?? "N/A"}</span>
            </div>
            <div className="col-md-3">
              <label htmlFor="">Resolution Type</label>
              <div className="d-flex gap-2">
                {data?.types?.length > 0 ? (
                  <Each
                    array={data?.types}
                    render={(type, i) => {
                      return (
                        <span key={i} className="light-badge">
                          {type}
                        </span>
                      );
                    }}
                  />
                ) : (
                  <span className="light-badge">N/A</span>
                )}
              </div>
            </div>
            <div className="col-md-3">
              <label htmlFor="">Description/Cause for Resolution</label>
              <span className="light-badge">{data?.description ?? "N/A"}</span>
            </div>
          </div>
          <h4 className="form-title mb-4">
            Individuals/Corporate Entities Involved
          </h4>
          {data?.individual?.name && (
            <div className="custom-box">
              <span className="light-badge mb-4">
                <i className="fal fa-user-circle"></i> Individual
              </span>
              <div className="custom-form row gy-4 mb-4">
                <div className="col-md-3">
                  <label htmlFor="">Full Name</label>
                  <span className="light-badge">{data?.individual?.name}</span>
                </div>
                <div className="col-md-3">
                  <label htmlFor="">Email</label>
                  <span className="light-badge">{data?.individual?.email}</span>
                </div>
                <div className="col-md-3">
                  <label htmlFor="">Contact Number</label>
                  <span className="light-badge">
                    {data?.individual?.contactNumber}
                  </span>
                </div>
              </div>
              <div className="row gy-2 gx-4">
                <Each
                  array={data?.individual?.documents}
                  render={(doc, index) => (
                    <div className="col-md-3" key={index}>
                      <div>
                        <Checkbox
                          className="custom-checkbox"
                          onChange={(e) =>
                            getRejectDocs(e.target.checked, doc?._id)
                          }
                        >
                          {doc?.document}
                        </Checkbox>
                        <UploadZ
                          file={doc}
                          downloadOnly={true}
                          showApprove={true}
                          onFileApprove={onChangeApproval}
                          showTranslation={true}
                          showExtraction={true}
                        />
                      </div>
                    </div>
                  )}
                />
              </div>
            </div>
          )}
          {data?.corporate?.name && (
            <div className="custom-box">
              <span className="light-badge mb-4">
                <i className="fal fa-user-circle"></i> Corporate
              </span>
              <div className="custom-form row gy-4 mb-4">
                <div className="col-md-3">
                  <label htmlFor="">Name</label>
                  <span className="light-badge">{data?.corporate?.name}</span>
                </div>
              </div>
              <div className="row gy-2 gx-4">
                <Each
                  array={data?.corporate?.documents}
                  render={(doc, index) => (
                    <div className="col-md-3" key={index}>
                      <div>
                        <Checkbox
                          className="custom-checkbox"
                          onChange={(e) =>
                            getRejectDocs(e.target.checked, doc?._id)
                          }
                        >
                          {doc?.document}
                        </Checkbox>
                        <UploadZ
                          file={doc}
                          downloadOnly={true}
                          showApprove={true}
                          onFileApprove={onChangeApproval}
                          showTranslation={true}
                          showExtraction={true}
                        />
                      </div>
                    </div>
                  )}
                />
              </div>
            </div>
          )}
        </div>
      </Spin>
      <Modal
        className="custom-modal-2"
        open={rejectModel}
        width={800}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Reject"
        okButtonProps={{ className: "reject-btn" }}
        cancelButtonProps={{ className: "d-none" }}
      >
        <div className="mt-4 pt-2">
          <h6 className="input-title">Reject Request</h6>
          <p className="description mb-3">
            Select reason(s) as to why this request should be rejected.
          </p>
          <Checkbox className="custom-checkbox py-1 font-md">
            Incomplete or inaccurate information provided in the documents
          </Checkbox>
          <Checkbox className="custom-checkbox py-1 font-md">
            Insufficient supporting documentation or evidence
          </Checkbox>
          <Checkbox className="custom-checkbox py-1 font-md">
            The requesting entity does not have the legal authority to do so
          </Checkbox>
          <Checkbox className="custom-checkbox py-1 font-md">
            Lack of authorization or signature from the appropriate authority
          </Checkbox>
          <Checkbox className="custom-checkbox py-1 font-md">
            Failure to pay required fees or charges associated with the request
          </Checkbox>
          <Checkbox className="custom-checkbox py-1 font-md">
            Issues with the authenticity or validity of the requesting entity or
            individuals involved
          </Checkbox>
          <Checkbox className="custom-checkbox py-1 font-md">
            Suspected fraud or misrepresentation in the application
          </Checkbox>
        </div>
      </Modal>
    </div>
  );
}
